import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function FreeConsultation() {
  const [featuredCountries, setFeaturedCountries] = useState([])
  const [file, setFile] = useState([]);
  const [nationalityData, setNationalityData] = useState([])
  const [countriesData, setCountriesData] = useState([])
  const [allData, setAllData] = useState({ treatmentname: "", patientname: "", age: "", gender: "", nationality: "", email: "", countrycode: "", phone: "", medicalconcerns: "" })
  const [successMessage, setSuccessMessage] = useState("")
  const [image, setImage] = useState( {preview: "", raw: ""})
  const [treatmentName , setTreatmentName] = useState("")
  const imageMimeType = /(jpg|jpeg|doc|docx|pdf|png)/i;
  const imageRef = useRef(null)
  const didMountRef = useRef(true)
  const location = useLocation()
  const {id , type} = useParams()

  useEffect(() => {
    if (didMountRef.current) {
      setTreatmentName(location.state)
      apiServices.countrieslistGetRequest().then(res => {
        console.log(res)
        if (res.data.countries.length > 0) {
          setCountriesData(res.data.countries)
          setFeaturedCountries(res.data.featuredcountries)
        }
      })
      apiServices.nationalitylistGetRequest().then(res => {
        console.log(res)
        if (res.data.nationality.length > 0) {
          setNationalityData(res.data.nationality)
        }
      })
    }
    didMountRef.current = false;
  }, [])

  const handlechangedata = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setAllData({ ...allData, [key]: value })
  }

  const handleImage = e => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Invalid Format, supported formats are | jpeg | jpg | doc | docx | pdf | png.");
      return;
    }
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
      // setImage(image => [...image, ...e.target.files]);
    }
  };

  const getfreequotation = () => {
    if(id){
      console.log(id,"if")
    }
    else{
      console.log(id,"else")
    }
    if(treatmentName != null){
    if (treatmentName === "") {
      document.getElementById("treatmentname").style.border = "1px solid red"
      document.getElementById("treatmentname").focus()
      return false
    }
  }
  else{
    if (allData.treatmentname === "") {
      document.getElementById("treatmentname").style.border = "1px solid red"
      document.getElementById("treatmentname").focus()
      return false
    }

  }
    if (allData.patientname === "") {
      document.getElementById("patientname").style.border = "1px solid red"
      document.getElementById("patientname").focus()
      return false
    }
    else if (allData.age === "") {
      document.getElementById("age").style.border = "1px solid red"
      document.getElementById("age").focus()
      return false
    }
    else if (allData.gender === "" || allData.gender == undefined) {
      document.getElementById("gender").style.border = "1px solid red"
      document.getElementById("gender").focus()
      return false
    }
    else if (allData.nationality === "") {
      document.getElementById("nationality").style.border = "1px solid red"
      document.getElementById("nationality").focus()
      return false
    }
    else if (allData.email === "") {
      document.getElementById("email").style.border = "1px solid red"
      document.getElementById("email").focus()
      return false
    }
    else if (allData.countrycode === "") {
      document.getElementById("countrycode").style.border = "1px solid red"
      document.getElementById("countrycode").focus()
      return false
    }
    else if (allData.phone === "") {
      document.getElementById("phone").style.border = "1px solid red"
      document.getElementById("phone").focus()
      return false
    }
    else if (allData.medicalconcerns === "") {
      document.getElementById("medicalconcerns").style.border = "1px solid red"
      document.getElementById("medicalconcerns").focus()
      return false
    }
    const formData = new FormData()
   
    formData.append('page_type', "Free Consultation");
    if(treatmentName != null){ 
    formData.append('treatment_name', treatmentName);
    }
    else{
      formData.append('treatment_name', allData.treatmentname);
    }
    formData.append('patient_name', allData.patientname)
    formData.append('age', allData.age)
    formData.append('gender', allData.gender)
    formData.append('nationality', allData.nationality)
    formData.append('email', allData.email)
    formData.append('country_code', allData.countrycode)
    formData.append('phone', allData.phone)
    formData.append('medical_concerns', allData.medicalconcerns)
    if(type && type !== undefined){
    formData.append('enquiry_free_consultation_type', type)
    }
    if(id && id !== undefined){
    formData.append('enquiry_hospital_doctor_id', id)
    }
   
    formData.append('medical_record_image', image.raw)
   
    // for (let i = 0; i < image.length; i++) {
    //   formData.append("medical_record_image[]", image[i]);
    // }

  

    apiServices.quotationprocessPostRequest(formData).then(res => {
      if (res.data.status == "success") {
        setSuccessMessage(res.data.message)
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      }
    })
  }
  return (
    <>
      <Header />
      <section className="section-gap-equal-small parallex-form">
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center mb-5' >
                <h4 className="title mb-2" style={{ color: "white" }}>Schedule FREE Consultations</h4>
                {/* <p style={{ color: "white" }}>Trusted by Patients from 50+ countries</p> */}
              </div>
            </div>
          </div>
          <div className='row g-5'>
            <div className='col-lg-7'>
              <div className='quotform'>
                <div className='row'>
                  {successMessage != "" ?
                    <div class="alert alert-info">
                      <strong>{successMessage}</strong>
                    </div> : ""}
                  <div className='col-lg-12'>
                    <div className='quotform-group mb-4'>
                      <label>Treatment Name<span >*</span></label>
                      <input type="text" name="treatmentname" placeholder="Treatment" id='treatmentname' defaultValue={treatmentName}  onChange={handlechangedata} disabled={treatmentName != null ?true :false}/>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='quotform-group mb-4'>
                      <label>Patient Full Name<span >*</span></label>
                      <input type="text" name="patientname" placeholder="Patient" id='patientname' onChange={handlechangedata} />
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='quotform-group mb-4'>
                      <label>Age<span >*</span></label>
                      <input type="text" name="age" placeholder="Age" id='age' onChange={handlechangedata} />
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='quotform-group mb-4'>
                      <label>Gender<span >*</span></label>
                      <select name="gender" id='gender' onChange={handlechangedata}>
                        <option value="">Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='quotform-group mb-4'>
                      <label>Nationality (Passport)<span >*</span></label>
                      <select name="nationality" id='nationality' onChange={handlechangedata}>
                        <option >Select</option>
                        {nationalityData.length > 0 && nationalityData.map((value, index) => (
                          <option key={index} value={value.nationality_id}>{value.nationality_name}</option>))}
                      </select>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='quotform-group mb-4'>
                      <label>Email <span >*</span></label>
                      <input type="email" name="email" placeholder="Email" id='email' onChange={handlechangedata} />
                    </div>
                  </div>
                  <div className='col-lg-3'>
                    <div className='quotform-group mb-4'>
                      <label>Country Code<span >*</span></label>
                      <select name="countrycode" id='countrycode' onChange={handlechangedata}>
                        <option >Select</option>
                        <option disabled style={{ fontWeight: "bold" }}>Popular Countries</option>
                        {featuredCountries.length > 0 && featuredCountries.map((value, index) => (
                          <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                        ))}
                        <option disabled style={{ fontWeight: "bold" }}>All Countries</option>
                        {countriesData.length > 0 && countriesData.map((value, index) => (
                          <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='col-lg-5'>
                    <div className='quotform-group mb-4'>
                      <label>Phone<span >*</span></label>
                      <input type="number" name="phone" placeholder="Phone Number" id='phone' onChange={handlechangedata} />
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='quotform-group mb-4'>
                      <label>Medical Concerns/Questions<span >*</span></label>
                      <textarea type="text" name="medicalconcerns" placeholder="Medical Concerns/Questions" id='medicalconcerns' onChange={handlechangedata} />
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='quotform-group mb-4'> 
                      <label>Upload Medical Records (jpeg, jpg, doc, docx, pdf, png)</label>
                      <div className="custom-file">
                      <input type="file" className="custom-file-input" id="customFile" onChange={handleImage} ></input>
                        <label className="custom-file-label" for="customFile">{image.raw.name !== null && image.raw.name != ""  ? image.raw.name : "Choose file"}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-12'>
                <button type="submit" className='edu-btn btn-large-second mt-3' onClick={getfreequotation}>Submit</button>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='quoteformlits'>
                <ul>
                  <li>
                    <p>Submit an inquiry tell us what is troubling you, attach any reports.</p>
                  </li>
                  <li>
                    <p>A representative from the medical team will reach out to coordinate a suitable time between you and the specialist.</p>
                  </li>
                  <li>
                    <p>You will receive a phone call or video link to join for your appointment with the doctor.</p>
                  </li>
                  <li>
                    <p>Join the meeting and a proper consultation is conducted by the doctor during your assigned time slot. Doctor will provide a diagnosis and treatment plan for your condition.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
export default FreeConsultation;