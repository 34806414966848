import React, { useEffect, useState, useRef } from 'react';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiservices";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from "react-loading-skeleton";

let apiServices = new ApiService()
function Knowledge() {
    const [pageData ,setPageData] = useState({})
    const [videoData , setVideoData] = useState([])
    const [baseUrl , setBaseUrl] = useState("")
    const didMountRef = useRef(true)
    const [loading , setloading] = useState(false)
    useEffect(() => {
        if (didMountRef.current) {
            pagesData()
            setloading(true)
            apiServices.videolistPostRequest().then(res=>{
              if(res.data.status == "success"){
                setVideoData(res.data.data)
                setBaseUrl(res.data.base_url)
                setloading(false)
              }
              else{
                setloading(false)
              }
                  })
        }
        didMountRef.current = false;
    }, [])

    const pagesData=()=>{
        const dataString={
          "page_url":"knowledge"
        }
        apiServices.PagesDataPostRequest(dataString).then(res=>{
    if(res.data.status == "success"){
      setPageData(res.data.pagesData)
    }
        })
      }
return (
    <>

    <Header/>
    <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
<div className="container">
<div className = "row">
    <div className ='col-lg-12'>
    <h1>Video</h1>
<div className="breadcrumb-inner">
<ul className="edu-breadcrumb">
<li className="breadcrumb-item"><a href="/">Home</a></li>
<li className="separator"><i className="icon-angle-right"></i></li>
<li className="breadcrumb-item">Video</li>
{/* <li className="separator"><i className="icon-angle-right"></i></li>
<li className="breadcrumb-item active" aria-current="page"></li> */}
</ul>
</div>
</div>
</div>
</div>
</div>


<div className="edu-team-area team-area-1 section-gap-equal">
                <div className="container">
                <div  className="doctorslider">
                 
                        {
                            loading == true ?
                                <>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                </>
                                : <>
                                        <div className='row'>
                                    {
                                        videoData.map((value, index) => (
                                                <div className='col-lg-4'>
                                                <div className="edu-team-grid team-style-1">
                                                    <div className="inner">
                                                   
                                                            <div className="thumbnail">
                                                                <a>
                                                                    <video controls src={value.video_link != null ? baseUrl + value.video_link : ''} alt="team images" autoPlay/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="title-second"><a>{value.video_name}</a></h5>
                                                          
                                                        </div>
                                                    
                                                </div>
                                                </div>
                                                                                        

                                        ))
                                    }                                                                                        </div></>
                                    
                        }
                  
                  </div>
                </div>
            </div>

<Footer/>
    </>
)
}
export default Knowledge