import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import 'swiper/swiper.min.css'
import 'swiper/css/pagination'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function Home_Category() {
    const didMountRef = useRef(true)
    const [featuredcategory, setfeaturedcategory] = useState([])
    const [categoryImageUrl, setCategoryImageUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    const [loading, setloading] = useState(true)
    const [count, setcount] = useState(0)
    useEffect(() => {
        if (featuredcategory.length == 0) {
            getfeaturedcategory();
        }
    }, [])
    const getfeaturedcategory = () => {
        const dataString = {
        }
        apiServices.categoryPostRequest(dataString).then(res => {
            if (res.data.status === "success") {
                setloading(false);
                setfeaturedcategory(res.data.category)
                setCategoryImageUrl(res.data.category_url)
                setDefaultImage(res.data.default_image_baseurl)
            } else {
                setloading(false)
            }
        })
        // setloading(false)
    }

    const sliderRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, [])
    return (
        <div className="edu-categorie-area categorie-area-3 edu-section-gap bg-image">
            <div className="container">
                <div className="section-title section-center">
                    <h2 className="title">Most Recommended Treatment Procedures</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                 
                </div>
                <Swiper
                
                ref={sliderRef}
                    spaceBetween={15}
                    breakpoints={{
                        // when window width is >= 640px
                        340: {
                            width: 340,
                            slidesPerView: 3,
                        },
                        640: {
                            width: 640,
                            slidesPerView: 3,
                        },
                        // when window width is >= 768px
                        768: {
                            width: 768,
                            slidesPerView: 4,
                        },
                        1000: {
                            width: 1000,
                            slidesPerView: 6,
                        },
                        1200: {
                            width: 1200,
                            slidesPerView: 6,
                        },
                    }}
                    // pagination={{
                    //     clickable: true,
                    //   }}
                    //   modules={[Pagination]}

                    className="categoryslider">
                    {(() => {
                        if (loading === true) {
                            return (
                                <>
                                    <SwiperSlide>
                                        <Skeleton varient="rounded" borderRadius={"50%"} />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rounded" borderRadius={"50%"} />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rounded" borderRadius={"50%"} />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rounded" borderRadius={"50%"} />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rounded" borderRadius={"50%"} />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rounded" borderRadius={"50%"} />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                </>
                            );
                        } else {
                            return (
                                featuredcategory.map((value, index) => (
                                    <SwiperSlide key={index}>
                                        <a href={`/treatment/${value.category_slug}`} className="catbox-item">
                                            <div className="catbox-item-img">
                                                <img src={value.category_image != null ? categoryImageUrl + value.category_image : defaultImage} className="img-fluid" alt="Speciality" />
                                            </div>
                                            <h6><a href={`/treatment/${value.category_slug}`}>{value.category_name}</a></h6>
                                        </a>
                                    </SwiperSlide>
                                ))
                            );
                        }
                    })()}
                    <div className='swiper-arrow'>
<div className="prev-arrow" onClick={handlePrev} ><img src="/assets/images/right-chevron.png"/></div>
<div className="next-arrow"onClick={handleNext}><img src="/assets/images/right-chevron.png"/></div>
</div>
                </Swiper>
                {/* {loading == true ?
    <OwlCarousel className='owl-theme' {...slideroptions1}>
<div className='item'> 
<Skeleton varient="rounded"  width={150} height={150} borderRadius={"50%"} />
 <Skeleton varient="text" width={150} height={20} />
</div> 
<div className='item'> 
<Skeleton varient="rounded"  width={150} height={150} borderRadius={"50%"} />
<Skeleton varient="text" width={150} height={20} />
 </div> 
 <div className='item'> 
<Skeleton varient="rounded"  width={150} height={150} borderRadius={"50%"} />
 <Skeleton varient="text" width={150} height={20} />
 </div>
 <div className='item'> 
<Skeleton varient="rounded"  width={150} height={150} borderRadius={"50%"} />
 <Skeleton varient="text" width={150} height={20} />
 </div>
 <div className='item'> 
<Skeleton varient="rounded"  width={150} height={150} borderRadius={"50%"} />
 <Skeleton varient="text" width={150} height={20} />
 </div>
 <div className='item'> 
<Skeleton varient="rounded"  width={150} height={150} borderRadius={"50%"} />
 <Skeleton varient="text" width={150} height={20} />
 </div>
 </OwlCarousel>
:<OwlCarousel className='owl-theme' {...slideroptions1}>
{featuredcategory.length>0 && 
featuredcategory.map((value) => (
<div className='item'>
<a href={`/treatment/${value.category_slug}`} className="catbox-item">
<div className="catbox-item-img">
<img src={value.category_image != null ?categoryImageUrl + value.category_image:defaultImage} className="img-fluid" alt="Speciality"/>
</div>
<h6><a href={`/treatment/${value.category_slug}`}>{value.category_name}</a></h6>
</a>
</div>
))}
</OwlCarousel>
} */}
            </div>
        </div>
    )
}
export default Home_Category;