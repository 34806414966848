import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

function DeleteAccount(){
    return(
        <>
        <Header/>
        <section className="contact-us-area">
                <div className="container">
                    <div className="row g-5" style={{marginBottom:"100px"}}>
            
                        <div className="offset-xl-2 col-lg-6">
                            <div className="contact-form form-style-2">
                                <div className="section-title">
                                    <h4 className="title">Delete Account </h4>
                                    {/* <p>Fill out this form for booking a consultant advising session.</p> */}
                                </div>
                              
                                <form className="rnt-contact-form rwt-dynamic-form" id="contact-form" method="POST" action="mail.php">
                                    <div className="row row--10">
                                        <div className="form-group col-12">
                                            <input type="text" name="contact-name" id="contact-name" placeholder="Your name"  />
                                        </div>
                                        <div className="form-group col-12">
                                            <input type="email" name="contact-email" id="contact-email" placeholder="Enter your email"/>
                                        </div>
                                        <div className="form-group col-12">
                                            <input type="number" name="contact-phone" id="contact-phone" placeholder="Phone number"  />
                                        </div>
                                        <div className="form-group col-12">
                                            <textarea name="contact-message" id="contact-message" cols="30" rows="4" placeholder="Your message" ></textarea>
                                        </div>
                                      
                                        <div class="form-group col-12">
                                            <button type="button" className="rn-btn edu-btn btn-medium submit-btn" name="submit">Delete Account <i className="icon-4"></i></button>
                                        </div>
                                    </div>
                                </form>
                                <ul class="shape-group">
                                    <li className="shape-1 scene"><img data-depth="1" src="assets/images/about/shape-13.png" alt="Shape" /></li>
                                    <li className="shape-2 scene"><img data-depth="-1" src="assets/images/counterup/shape-02.png" alt="Shape" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer/>
        </>
    )
}
export default DeleteAccount