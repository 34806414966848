import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import TreatmentDoctorList from '../TreatmentDoctorList';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiservices";
import { Helmet } from "react-helmet";
let apiServices = new ApiService()
function PackagesDetails() {
  const didMountRef = useRef(true)
  const { slug } = useParams()
  const [packagesDetailData, setPackagesDetailData] = useState("")
  const [packageImageUrl, setPackageImageUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const [countryData, setCountryData] = useState("")
  const [cityData, setCityData] = useState([])
  const [allData, setAllData] = useState({ patientname: "", citycode: "", phone: ""})
  const [treatmentData, setTreatmentData] = useState("")
  const [facilityData, setFacilityData] = useState("")
  const [spinnerLoading, setSpinnerLoading] = useState()
  const [successMessage, setSuccessMessage] = useState("")
  const [packages, setPackages] = useState([])
  const [packagesImageUrl, setPackagesImageUrl] = useState('')  
  const [TreatementCategories, setTreatementCategories] = useState([]);
  const [medicalTreatmentActive , setMedicalTreatmentActive] = useState('')
  const [nationalityData, setNationalityData] = useState([])
  const [featuredCountries, setFeaturedCountries] = useState([])
  const [countriesData, setCountriesData] = useState([])
  const [image, setImage] = useState({ preview: "", raw: "" })
  const imageMimeType = /(jpg|jpeg|doc|docx|pdf|png)/i;
  useEffect(() => {
    if (didMountRef.current) {
      setSpinnerLoading(true)
      const dataString = {
        "packages_slug": slug
      }
      apiServices.packagesdetailsPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setPackagesDetailData(res.data.packageDetails)
          setCountryData(res.data.packageDetails.country.location_name)
          setTreatmentData(res.data.packageDetails.treatment.treatment_name)
          setFacilityData(res.data.facility)
          setPackageImageUrl(res.data.package_url)
          setDefaultImage(res.data.base_url)
          setSpinnerLoading(false)
        }
      })
      apiServices.citieslistGetRequest().then(res => {
        console.log(res)
        if (res.data.length > 0) {
          setCityData(res.data)

        }
      })
    

      apiServices.medicaltreatementparentcatdataGetRequest().then(res => {
        if (res.data.status == "success") {
          setTreatementCategories(res.data.data)
        }
      })
    }
    apiServices.nationalitylistGetRequest().then(res => {
      if (res.data.nationality.length > 0) {
        setNationalityData(res.data.nationality)
      }
    })
    apiServices.countrieslistGetRequest().then(res => {
      console.log(res)
      if (res.data.countries.length > 0) {
        setCountriesData(res.data.countries)
        setFeaturedCountries(res.data.featuredcountries)
      }
    })
    didMountRef.current = false;
  }, [])

  const handlechangedata = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setAllData({ ...allData, [key]: value })
  }

  const medicaltreatment=(slug)=>{
    setMedicalTreatmentActive(slug)
}
const getfreequotation = () => {
  console.log(allData)
  if (allData.treatmentname === "") {
    document.getElementById("treatmentname").style.border = "1px solid black"
    document.getElementById("treatmentname").focus()
    return false
  }
  if (allData.patientname === "") {
    document.getElementById("patientname").style.border = "1px solid black"
    document.getElementById("patientname").focus()
    return false
  }
  else if (allData.age === "") {
    document.getElementById("age").style.border = "1px solid black"
    document.getElementById("age").focus()
    return false
  }
  else if (allData.gender === "" || allData.gender == undefined) {
    document.getElementById("gender").style.border = "1px solid black"
    document.getElementById("gender").focus()
    return false
  }
  else if (allData.nationality === "") {
    document.getElementById("nationality").style.border = "1px solid black"
    document.getElementById("nationality").focus()
    return false
  }
  else if (allData.email === "") {
    document.getElementById("emailquote").style.border = "1px solid black"
    document.getElementById("emailquote").focus()
    return false
  }
  else if (allData.countrycode === "") {
    document.getElementById("countrycode").style.border = "1px solid black"
    document.getElementById("countrycode").focus()
    return false
  }
  else if (allData.phone === "") {
    document.getElementById("phone").style.border = "1px solid black"
    document.getElementById("phone").focus()
    return false
  }
  else if (allData.medicalconcerns === "") {
    document.getElementById("medicalconcerns").style.border = "1px solid black"
    document.getElementById("medicalconcerns")
    return false.focus()
  }
  const formData = new FormData()
  formData.append('page_type', "package/"+slug);
  formData.append('treatment_name', allData.treatmentname);
  formData.append('patient_name', allData.patientname)
  formData.append('age', allData.age)
  formData.append('gender', allData.gender)
  formData.append('nationality', allData.nationality)
  formData.append('email', allData.email)
  formData.append('country_code', allData.countrycode)
  formData.append('phone', allData.phone)
  formData.append('medical_concerns', allData.medicalconcerns)
  formData.append('medical_record_image', image.raw)
  apiServices.quotationprocessPostRequest(formData).then(res => {
    if (res.data.status == "success") {
      setSuccessMessage(res.data.message)
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    }
  })
}
  const handleImage = e => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Invalid Format, supported formats are | jpeg | jpg | doc | docx | pdf | png.");
      return;
    }
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };
  return (
    <>
    <Helmet>
        <title>{packagesDetailData.packages_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            packagesDetailData.packages_meta_desc != null
              ? packagesDetailData.packages_meta_desc
              : "JDMax Healthcare"
          }
        />
        {packagesDetailData.packages_meta_keyword != null ? (
          <meta
            name="keywords"
            content={packagesDetailData.packages_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}
       
        <meta
          property="og:title"
          content={packagesDetailData.packages_meta_title}
        />
      
      <meta name="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />
<link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            packagesDetailData.packages_meta_desc != null
              ? packagesDetailData.packages_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={packagesDetailData.packages_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            packagesDetailData.packages_meta_desc != null
              ? packagesDetailData.packages_meta_desc
              : "JDMax Healthcare"
          }
        />
       
      </Helmet>
      <Header />
      {spinnerLoading == false ? <>
       <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
          <div className="container">
            <div className="row">
              <div className='col-lg-12'>
                <h1>{packagesDetailData.packages_name}</h1>
                <div className="breadcrumb-inner">
                  <ul className="edu-breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item"><a href="/packages">Packages</a></li>
                    <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item">{packagesDetailData.packages_name}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mb-5 mt-5">
     <div className="row">
     <div className="col-lg-12">
                    <div className="hosbox-img">
                      <img src={packagesDetailData.packages_image != null ? packageImageUrl + packagesDetailData.packages_image : defaultImage} className="img-fluid" style={{width:"100%", height:"500px"}}></img>
                    </div>
                  </div>

</div>
</div>


        <div className="container mb-5">
     <div className="row">
      
      <div className="col-lg-8">
        
    <div className="features-area-2">
    <div>
        <div className="features-grid-wrap-2">
        <div className="features-box features-style-2 bordernone edublink-svg-animate">
                <div className="content text-center">
                <img className="svgInject pb-4" src="/assets/images/animated-svg-icons/certificate.svg" alt="animated icon" />
                    <h5 className="title2"><span>Country</span></h5>
                    <p className="titlesecond2">{countryData}</p>
                </div>
            </div>
            <div className="features-box features-style-2 bordernone edublink-svg-animate">
                <div className="content text-center">
                <img className="svgInject pb-4" src="/assets/images/animated-svg-icons/user.svg" alt="animated icon" />
                    <h5 className="title2"><span>Days In Hospital</span></h5>
                    <p className="titlesecond2">{packagesDetailData.packages_days_inside}</p>
                </div>
            </div>
         
            <div className="features-box features-style-2 bordernone edublink-svg-animate">
                <div className="content text-center">
                <img className="svgInject pb-4" src="/assets/images/animated-svg-icons/instructor.svg" alt="animated icon" />
                    <h5 className="title2"><span>Duration</span></h5>
                    <p className="titlesecond2">{packagesDetailData.packages_duration}</p>
                </div>
            </div>
            <div className="features-box features-style-2 bordernone edublink-svg-animate">
                <div className="content text-center">
                <img className="svgInject pb-4" src="/assets/images/animated-svg-icons/package.png" alt="animated icon" />
                    <h5 className="title2"><span>Package in USD</span></h5>
                    <p className="titlesecond2">${packagesDetailData.packages_usd_start} - ${packagesDetailData.packages_usd_upto}</p>
                </div>
            </div>
          
        </div>
    </div>
</div>
<div className="row g-3 mt-4">
                    <div className="col-lg-12">
                      <div className='package-content' dangerouslySetInnerHTML={{ __html: packagesDetailData.packages_desc }}></div>
                    </div>
                  </div>
  

</div>
<div className='col-lg-4'>
                <div className='sideform'>
                  <h2>Get Free Consultation</h2>
                  <div className='row'>
                    {successMessage != "" ?
                      <div class="alert alert-info">
                        <strong>{successMessage}</strong>
                      </div> : ""}
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Treatment Name <span >*</span></label>
                        <input type="text" name="treatmentname" placeholder="Treatment" id='treatmentname' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Patient Full Name <span >*</span></label>
                        <input type="text" name="patientname" placeholder="Patient" id='patientname' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Age <span >*</span></label>
                        <input type="number" name="age" placeholder="Age" id='age' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Gender <span >*</span></label>
                        <select name="gender" id='gender' onChange={handlechangedata}>
                          <option value="">Select</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Nationality (Passport)<span >*</span></label>
                        <select name="nationality" id='nationality' onChange={handlechangedata}>
                          <option >Select</option>
                          {nationalityData.length > 0 && nationalityData.map((value, index) => (
                            <option key={index} value={value.nationality_id}>{value.nationality_name}</option>))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Email <span >*</span></label>
                        <input type="email" name="email" placeholder="Email" id='emailquote' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Country Code  <span >*</span></label>
                        <select name="countrycode" id='countrycode' onChange={handlechangedata}>
                          <option >Select</option>
                          <option disabled style={{ fontWeight: "bold" }}>Popular Countries</option>
                          {featuredCountries.length > 0 && featuredCountries.map((value, index) => (
                            <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                          ))}
                          <option disabled style={{ fontWeight: "bold" }}>All Countries</option>
                          {countriesData.length > 0 && countriesData.map((value, index) => (
                            <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Phone <span >*</span></label>
                        <input type="number" name="phone" placeholder="Phone Number" id='phone' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Medical Concerns/Questions <span >*</span></label>
                        <textarea type="text" name="medicalconcerns" placeholder="Medical Concerns/Questions" id='medicalconcerns' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Upload Medical Records (jpeg, jpg, doc, docx, pdf, png)</label>
                        <div className="custom-file">
                          <input type="file" className="custom-file-input" id="customFile" onChange={handleImage} />
                          <label className="custom-file-label" for="customFile">{image.raw.name !== "" ? image.raw.name : "Choose file"}</label>
                        </div>
                      </div>
                    </div>
                    <div className='sideform-group'>
                      <button className='edu-btn02 btn-small btn-full mt-3' type='button' onClick={getfreequotation}>Get Free Quotation</button>
                    </div>
                  </div>
                </div>
                <div className="mt-5 filterbox filterbox-title p-5">
  <h5>Explore Treatments</h5>
  <ul style={{ listStyle: 'none' }}>
    {TreatementCategories.map((value, index) => (
      <li key={index}>
        <a href={'/treatment/' + value.category_slug} onClick={(e) => medicaltreatment(value.category_slug)}>
          {value.category_name}
        </a>
      </li>
    ))}
  </ul> 
</div>




</div>

</div>  
</div>


        {/* <section className="section-gap-equal-small">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h1 className="page-title-h1">{packagesDetailData.packages_name}</h1>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="hosbox-img">
                      <img src={packagesDetailData.packages_image != null ? packageImageUrl + packagesDetailData.packages_image : defaultImage} className="img-fluid"></img>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="hosbox-content">
                      <div className="hosbox-list" style={{ margin: "0px" }}>
                        <p style={{ margin: "0px" }}> <b>Package Name :</b> {packagesDetailData.packages_name}</p>
                        <p style={{ margin: "0px" }}> <b>Duration: </b> {packagesDetailData.packages_duration} </p>
                        <p style={{ margin: "0px" }}> <b>Medical Procedures: </b> {packagesDetailData.packages_category_name}    </p>
                        <p style={{ margin: "0px" }}> <b>Country: </b> {countryData}    </p>
                        <p style={{ margin: "0px" }}> <b>Package in INR: </b> ₹{packagesDetailData.packages_inr_start} - ₹{packagesDetailData.packages_inr_upto}</p>
                        <p style={{ margin: "0px" }}> <b>Package in USD: </b> ${packagesDetailData.packages_usd_start} - ${packagesDetailData.packages_usd_upto}</p>
                        <p style={{ margin: "0px" }}> <b>Treatment: </b> {treatmentData}    </p>
                        <p style={{ margin: "0px" }}><span><b>Days In Hospital :</b></span>{packagesDetailData.packages_days_inside}  &nbsp;  <span><b>Outside Hospital :</b></span> {packagesDetailData.packages_days_outside}</p>
                        <p style={{ margin: "0px" }}><b>Facility: </b>{facilityData} </p>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-lg-12">
                      <div className='package-content' dangerouslySetInnerHTML={{ __html: packagesDetailData.packages_desc }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <TreatmentDoctorList />
        <Footer />
      </> :
        <div className="loder">
          <img src="/assets/images/loading-gif.webp"></img>
        </div>}
    </>
  )
}
export default PackagesDetails