import React, { useEffect, useRef, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiservices";
import { Helmet } from "react-helmet";
let apiServices = new ApiService()
function Contact() {
    const didMountRef = useRef(true)
    const [imageUrl, setImageUrl] = useState('')
    const [settingData, setSettingData] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    const [Email, setEmail] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Name, setName] = useState('');
    const [Subject, setSubject] = useState('');
    const [Message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessmessage] = useState('')
    const [pageData ,setPageData] = useState({})
    useEffect(() => {
        if (didMountRef.current) {
            pagesData()
            apiServices.settingsdataGetRequest().then(res => {
                if (res.data.status == "success") {
                    setImageUrl(res.data.settings_url)
                    setSettingData(res.data.sitesettings)
                    setDefaultImage(res.data.default_image_baseurl)
                }
                else {
                }
            })
        }
        didMountRef.current = false;
    }, [])
    const submitmessage = (e) => {
        e.preventDefault()
        if (Name == "") {
            document.getElementById("contact-name").style.border = "1px solid red"
            return false
        }
        else if (Email == "") {
            document.getElementById("contact-email").style.border = "1px solid red"
            return false
        }
        else if (Mobile == "") {
            document.getElementById("contact-phone").style.border = "1px solid red"
            return false
        }
        else if (Message == "") {
            document.getElementById("contact-message").style.border = "1px solid red"
            return false
        }
        else {
            setName("")
            setEmail("")
            setMobile("")
            setMessage("")
            document.getElementById("contact-name").style.borderBottom = "1px solid var(--color-border)"
            document.getElementById("contact-email").style.borderBottom = "1px solid var(--color-border)"
            document.getElementById("contact-phone").style.borderBottom = "1px solid var(--color-border)"
            document.getElementById("contact-message").style.borderBottom = "1px solid var(--color-border)"
            const dataString = {
                "contact_name": Name,
                "contact_email": Email,
                "contact_mobile": Mobile,
                "contact_message": Message,
            }
            apiServices.contactusprocessPostRequest(dataString).then(res => {
                console.log(res)
                if (res.data.status === "success") {
                    setSuccessmessage(res.data.message)
                }
                else if (res.data.status === "error") {
                    setErrorMessage(res.data.message)
                }
            });
        }
    }
    const pagesData=()=>{
        const dataString={
          "page_url":"contact"
        }
        apiServices.PagesDataPostRequest(dataString).then(res=>{
    if(res.data.status == "success"){
      setPageData(res.data.pagesData)
    }
        })
      }
    return (
        <>
           <Helmet>
        <title>{pageData.page_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />
        {pageData.page_meta_keyword != null ? (
          <meta
            name="keywords"
            content={pageData.page_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}
       
        <meta
          property="og:title"
          content={pageData.page_meta_title}
        />
      
      <meta name="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />
<link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={pageData.page_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />
       
      </Helmet>
            <Header />
            <div className="edu-breadcrumb-area">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <div className="page-title">
                            <h1 className="title">Contact Us</h1>
                        </div>
                        <ul className="edu-breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="separator"><i className="icon-angle-right"></i></li>
                            <li className="breadcrumb-item">Contact Us</li>
                       
                        </ul>
                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1">
                        <span></span>
                    </li>
                    <li className="shape-2 scene"><img data-depth="2" src="assets/images/about/shape-13.png" alt="shape" /></li>
                    <li className="shape-3 scene"><img data-depth="-2" src="assets/images/about/shape-15.png" alt="shape" /></li>
                    <li className="shape-4">
                        <span></span>
                    </li>
                    <li className="shape-5 scene"><img data-depth="2" src="assets/images/about/shape-07.png" alt="shape" /></li>
                </ul>
            </div>
            <section className="contact-us-area">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-xl-4 col-lg-6">
                            <div className="contact-us-info">
                                <h3 className="heading-title">We're Always Eager to Hear From You!</h3>
                                <ul className="address-list">
                                    <li>
                                        <h5 className="title">Address</h5>
                                        <p>Jaipur</p>
                                    </li>
                                    <li>
                                        <h5 className="title">Email</h5>
                                        <p><a href={settingData.admin_email}>{settingData.admin_email}</a></p>
                                    </li>
                                    <li>
                                        <h5 className="title">Phone</h5>
                                        <p><a href={"tel:" + settingData.admin_support_mobile}>{settingData.admin_support_mobile}</a></p>
                                    </li>
                                </ul>
                                <ul className="social-share">
                                    {
                                        settingData.facebook_url != null ? <li>
                                            <a href={settingData.facebook_url} target="new"><i className="icon-facebook"></i></a>
                                        </li> : null}
                                    {
                                        settingData.linkedin_url != null ? <li>
                                            <a href={settingData.linkedin_url} target="new"><i className="icon-linkedin2"></i></a>
                                        </li> : null}
                                    {
                                        settingData.instagram_url != null ? <li>
                                            <a href={settingData.instagram_url} target="new"><i className="icon-instagram"></i></a>
                                        </li> : null}
                                    {
                                        settingData.twitter_url != null ? <li>
                                            <a href={settingData.twitter_url} target="new"><i className="icon-twitter"></i></a>
                                        </li> : null}
                                    {
                                        settingData.youtube_url != null ? <li>
                                            <a href={settingData.youtube_url} target="new" className="color-yt"><i className="icon-youtube"></i></a>
                                        </li> : null}
                                </ul>
                            </div>
                        </div>
                        <div className="offset-xl-2 col-lg-6">
                            <div className="contact-form form-style-2">
                                <div className="section-title">
                                    <h4 className="title">Get In Touch</h4>
                                    <p>Fill out this form for booking a consultant advising session.</p>
                                </div>
                                {successMessage != "" ?
                                    <div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div> : ""}
                                {errorMessage != "" ?
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div> : ""}
                                <form className="rnt-contact-form rwt-dynamic-form" id="contact-form" method="POST" action="mail.php">
                                    <div className="row row--10">
                                        <div className="form-group col-12">
                                            <input type="text" name="contact-name" id="contact-name" placeholder="Your name" onChange={(e) => setName(e.target.value)} value={Name} />
                                        </div>
                                        <div className="form-group col-12">
                                            <input type="email" name="contact-email" id="contact-email" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} value={Email} />
                                        </div>
                                        <div className="form-group col-12">
                                            <input type="number" name="contact-phone" id="contact-phone" placeholder="Phone number" onChange={(e) => setMobile(e.target.value)} value={Mobile} />
                                        </div>
                                        <div className="form-group col-12">
                                            <textarea name="contact-message" id="contact-message" cols="30" rows="4" placeholder="Your message" onChange={(e) => setMessage(e.target.value)} value={Message}></textarea>
                                        </div>
                                        {
                                            errorMessage != '' ? <p className="text text-danger">{errorMessage}</p> : null
                                        }
                                        <div class="form-group col-12">
                                            <button type="button" className="rn-btn edu-btn btn-medium submit-btn" name="submit" onClick={submitmessage}>Submit Message <i className="icon-4"></i></button>
                                        </div>
                                    </div>
                                </form>
                                <ul class="shape-group">
                                    <li className="shape-1 scene"><img data-depth="1" src="assets/images/about/shape-13.png" alt="Shape" /></li>
                                    <li className="shape-2 scene"><img data-depth="-1" src="assets/images/counterup/shape-02.png" alt="Shape" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="google-map-area">
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=melbourne,%20Australia&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact;