import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from "react-loading-skeleton";
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();

function HomeVideo(){
    const [loading, setloading] = useState(true)
    const [videoData , setVideoData] = useState()
    const [baseUrl , setBaseUrl] = useState("")
    const [defaultImage, setDefaultImage] = useState('')
    const didMountRef = useRef(true)
    useEffect(() => {
    
            apiServices.featuredvideolistPostRequest().then(res => {
                if (res.data.status === "success") {
                    setVideoData(res.data.data)
                    setBaseUrl(res.data.base_url)
                    setDefaultImage(res.data.defaultimage)
                    setloading(false)
                }
                else {
                    setloading(false)
                }
            })
        
      
    }, [])
    return(
        <>
  <div className="edu-team-area team-area-1 section-gap-equal">
                <div className="container">
                    <div className="section-title section-center">
                        <span className="pre-title">Doctors</span>
                        <h2 className="title">Popular Video</h2>
                        <span className="shape-line"><i className="icon-19"></i></span>
                       
                    </div>
                    <Swiper
                        spaceBetween={15}
                        breakpoints={{
                            // when window width is >= 640px
                            340: {
                                width: 340,
                                slidesPerView: 2,
                            },
                            640: {
                                width: 640,
                                slidesPerView: 2,
                            },
                            // when window width is >= 768px
                            768: {
                                width: 768,
                                slidesPerView: 2,
                            },
                            1000: {
                                width: 1000,
                                slidesPerView: 5,
                            },
                            1200: {
                                width: 1200,
                                slidesPerView: 5,
                            },
                        }}
                        className="doctorslider">
                        {
                            loading == true ?
                                <>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                </>
                                : <>
                                    {
                                        videoData.map((value, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="edu-team-grid team-style-1">
                                                    <div className="inner">
                                                        <div className="thumbnail-wrap">
                                                            <div className="thumbnail">
                                                                <a>
                                                                    <video controls src={value.video_link != null ? baseUrl + value.video_link : defaultImage} alt="team images"  autoPlay 
    muted/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="title-second"><a >{value.video_name}</a></h5>
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }</>
                        }
                    </Swiper>
                    <div className="course-view-all">
                        <a href="/video" className="edu-btn">More Video<i className="icon-4"></i></a>
                    </div>
                </div>
            </div>

        </>
    )
}
export default HomeVideo