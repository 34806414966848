import React, { useEffect, useState, useRef } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { useParams } from "react-router-dom";
let apiServices = new ApiService();
function PackageFilter({ updateFilterData }){
  const { slug1 } = useParams();
  const [countryData, setCountryData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [hospitalData, setHospitalData] = useState([])
  const [ReqFilterData, setReqFilterData] = useState([]);
  const [packageCategoryData, setPackageCategoryData] = useState([])
  const [packagesImageUrl, setPackagesImageUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const [selectedFilter, setSelectedFilter] = useState(null);
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      if (slug1 !== undefined || slug1 != null) {
        setReqFilterData(slug1);
      }
      
      apiServices.hospitalcategorylistGetRequest().then(res => {
        if (res.data.length > 0){
          setCategoryData(res.data)
        }
      })
      apiServices.doctorspecialitylistGetRequest().then(res => {
        console.log("spe", res)
        if (res.data.status === 'success') {
          setCategoryData(res.data.doctorsSpecialityList)
        }
      })
      
      const dataString={
        filterdata: slug1
      }
          apiServices.packageslistPostRequest(dataString).then(res => {
              if (res.data.status === "success") {
                  console.log()
                  setPackageCategoryData(res.data.categoryData)
                  // setPackages(res.data.categorydata.packagesData)
                  setPackagesImageUrl(res.data.packages_url)
                  setDefaultImage(res.data.default_image_baseurl)
              }
          })
    
    }
    didMountRef.current = false;
  }, [])

  // const addpackagefilter = (categorySlug) => {
  //   const updatedCategoryData = [...categoryData];
  
  //   const categoryIndex = updatedCategoryData.findIndex(
  //     (value) => value.category_slug === categorySlug
  //   );
  
  //   if (categoryIndex !== -1) {
  //     updatedCategoryData[categoryIndex].isChecked = !updatedCategoryData[categoryIndex].isChecked;
  //     const isAtLeastOneChecked = updatedCategoryData.some(
  //       (value) => value.isChecked
  //     );
  
  //     if (!isAtLeastOneChecked && !updatedCategoryData[categoryIndex].isChecked) {
  //       return;
  //     }
  
  //     setCategoryData(updatedCategoryData);
  //   }
  
  //   const newFilterString = slug1 ? `${slug1}+${categorySlug}` : categorySlug;
  
  //   setReqFilterData(newFilterString);
  

  
  //   const dataString = {
  //     filterdata: newFilterString,
  //   };
  
  //   apiServices.packageslistPostRequest(dataString).then((res) => {
  //     if (res.data.status === "success") {
  //       setPackageCategoryData(res.data.categoryData);
  //       setPackagesImageUrl(res.data.packages_url);
  //       setDefaultImage(res.data.default_image_baseurl);
  //       const filterResponse = {
  //         filter: newFilterString,
  //         responseData: res.data,
  //       };
    
  //       updateFilterData(filterResponse);
  //     }
  //   });
   
  
  // };

  const addpackagefilter = (categorySlug) => {
    let catslug = ""
    if (selectedFilter === categorySlug) {
      setSelectedFilter(null);
      catslug = null
  } else {
      setSelectedFilter(categorySlug); 
      catslug = categorySlug
  }
    const updatedCategoryData = [...categoryData];
  
    const categoryIndex = updatedCategoryData.findIndex(
      (value) => value.category_slug === categorySlug
    );
  
    if (categoryIndex !== -1) {
      updatedCategoryData[categoryIndex].isChecked = !updatedCategoryData[categoryIndex].isChecked;
      setCategoryData(updatedCategoryData); 
  

      const checkedCategories = updatedCategoryData
        .filter((value) => value.isChecked)
        .map((value) => value.category_slug);
  
      const newFilterString = checkedCategories.length > 0 ? checkedCategories.join('+') : null;
  
      setReqFilterData(newFilterString);
  
      const dataString = {
        filterdata: catslug,
      };
  
      apiServices.packageslistPostRequest(dataString).then((res) => {
        if (res.data.status === "success") {
          setPackageCategoryData(res.data.categoryData);
          setPackagesImageUrl(res.data.packages_url);
          setDefaultImage(res.data.default_image_baseurl);
          const filterResponse = {
            filter: newFilterString,
            responseData: res.data,
          };
  
          updateFilterData(filterResponse);
        }
      });
    };
  };
  
  
  
  
  return (
    <>
      <div className="col-lg-3 siderbar-filter">
    
        {categoryData.length > 0 ?
          <div className='filterbox mb-4'>
            <div className="filterbox-title">Procedures</div>
            <div className='filterbox-body '>
              <div className='filter-list'>
                <ul>
                {categoryData.map((value, index) => {
  const isChecked = value.category_slug === ReqFilterData; // Check if the category matches the filter data

  if (value.treatment != null && value.treatment.length > 0) {
    return (
      <li key={index}>
        <div className="filter-parent">
          <i
            className={selectedFilter === value.category_slug ? 'ri-checkbox-fill' : 'ri-checkbox-blank-line'}
            onClick={(e) => { addpackagefilter(value.category_slug) }}
          ></i> {value.category_name}
        </div>
      </li>
    );
  } else {
    return (
      <li key={index}>
        <div className="filter-parent">
          <i
           className={selectedFilter === value.category_slug ? 'ri-checkbox-fill' : 'ri-checkbox-blank-line'}
            onClick={(e) => { addpackagefilter(value.category_slug) }}
          ></i> {value.category_name}
        </div>
      </li>
    );
  }
})}



                </ul>
              </div>
            </div>
          </div> : ""}
   
      </div>
    </>
  )
}
export default PackageFilter


