import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function LoginPage() {
  const [userEmail, setUserEmail] = useState("")
  const [userPassword, setUserPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const navigate = useNavigate()
  const login = () => {
    if (userEmail == "") {
      document.getElementById("current-log-email").style.border = "1px solid red"
    }
    else if (userPassword == "") {
      document.getElementById("current-log-password").style.border = "1px solid red"
    }
    const dataString = {
      "user_email": userEmail,
      "user_password": userPassword
    }
    apiServices.logincheckPostRequest(dataString).then(res => {
      if (res.data.status == "success") {
        localStorage.setItem('USER_SESSION', JSON.stringify(res.data.data));
        navigate("/")
      }
      else {
        setErrorMessage(res.data.message)
      }
      console.log(localStorage.getItem('USER_SESSION'))
    })
  }
  return (
    <>
      <Header />
      <div id="main-wrapper" className="main-wrapper">
        <section className="account-page-area section-gap-equal">
          <div className="container position-relative">
            <div className="row g-5 justify-content-center">
              <div className="col-lg-5">
                <div className="login-form-box">
                  <h3 className="title">Sign in</h3>
                  <p>Don’t have an account? <a href="/register">Sign up</a></p>
                  <form>
                    {errorMessage != "" ?
                      <div className="alert alert-danger">
                        <strong>{errorMessage}</strong>
                      </div> : ""}
                    <div className="form-group">
                      <label for="current-log-email">Username or email*</label>
                      <input type="email" name="current-log-email" id="current-log-email" placeholder="Email or username" onChange={(e) => { setUserEmail(e.target.value) }} />
                    </div>
                    <div className="form-group">
                      <label for="current-log-password">Password*</label>
                      <input type="password" name="current-log-password" id="current-log-password" placeholder="Password" onChange={(e) => { setUserPassword(e.target.value) }} />
                    </div>
                    <div className="form-group">
                      <button type="button" className="edu-btn btn-medium" onClick={login}>Sign in <i className="icon-4"></i></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <ul className="shape-group">
              <li className="shape-1 scene"><img data-depth="2" src="assets/images/about/shape-07.png" alt="Shape" /></li>
              <li className="shape-2 scene"><img data-depth="-2" src="assets/images/about/shape-13.png" alt="Shape" /></li>
              <li className="shape-3 scene"><img data-depth="2" src="assets/images/counterup/shape-02.png" alt="Shape" /></li>
            </ul>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}
export default LoginPage;