import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useParams, Link } from "react-router-dom";
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import 'swiper/swiper.min.css';
import { ApiService } from "../../Components/Services/apiservices";
import TreatmentHospitalList from "../TreatmentHospitalList";
import TreatmentDoctorList from '../TreatmentDoctorList';
import TreatmentDetailsSideBar from '../TreatmentDetailsSideBar';
import InnerHTML from 'dangerously-set-html-content';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as DOMPurify from 'dompurify';
import { marked } from 'marked';
import TreatmentShortcodeForm from '../TreatmentShortcodeForm';
import { Helmet } from "react-helmet";
let apiServices = new ApiService();
function TreatmentDetails() {
    const didMountRef = useRef(true)
    const { slug } = useParams()
    const [countriesData, setCountriesData] = useState([])
    const [featuredCountries, setFeaturedCountries] = useState([])
    const [nationalityData, setNationalityData] = useState([])
    const [treatmentData, setTreatmentData] = useState([])
    const [defaultImage, setDefaultImage] = useState('')
    const [treatmentDetailUrl, setTreatmentDetailUrl] = useState('')
    const [bannerImage, setBannerImage] = useState('')
    const [tabDescription, setTabDescription] = useState([])
    const [freeQuotation, setFreeQuotation] = useState({ contact: "", patient: "", age: "", gender: "", nationality: "", email: "", confirm: "", code: "", phone: "", concerns: "" })
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [videoData, setVideoData] = useState([])
    const [treatmentListData , setTreatmentListData] = useState([])
    const [categoryImageUrl, setCategoryImageUrl] = useState('')
    const [categoryDefaultImage, setCategoryDefaultImage] = useState('')
    const handlechange = () => {
        console.log('aassdd')
    };
    const treatmentShortCodeForm =
        DOMPurify.sanitize(marked.parse(`<section className="section-gap-equal-small"><div className='container'><div className='row'><div className='col-lg-12'><h4 className="title">Get a Free Personalized quote for Bariatric Surgery in India</h4><input type="text" name="contact" id="contact"  placeholder="Patient"/><input type="text" name="age"  placeholder="Age" /><input type="text" name="gender"  placeholder="Gender"   /><input type="text" name="Nationality"  placeholder="Nationality(Passport)" /><input type="text" name="email" id="email" placeholder="Email"   /><input type="text" name="confirm" placeholder="Enter Same Email" /><input type="number" name="code" id="code" placeholder="Country Code" /><input type="number" name="Phone"  placeholder="Phone" /><input type="text" name="Concerns"  placeholder="Medical Concerns/Questions" /> <button className="" type="submit" >Get Free Quotation</button></div></div></div></section>`));
    useEffect(() => {
        if (didMountRef.current) {
            setSpinnerLoading(true)
            const dataString = {
                "treatment_slug": slug
            }
            apiServices.treatmentdetailsPostRequest(dataString).then(res => {
                if (res.data.status === "success") {
                    setTreatmentData(res.data.treatmentDetails)
                    setTabDescription(res.data.treatmentDetails.desc_tabs)
                    setDefaultImage(res.data.default_image_baseurl)
                    setTreatmentDetailUrl(res.data.treatment_url)
                    setBannerImage(res.data.treatmentDetails.treatment_image)
                    setSpinnerLoading(false)
                } else {
                    setSpinnerLoading(false)
                }
                const dataString = {
                    "category_slug": slug,
                    "type": 2
                  }
                  apiServices.treatmentbycategoriesPostRequest(dataString).then(res => {
                    if (res.data.status == "success") {
                      setTreatmentListData(res.data.treatmentData)
                    //   setCategoryName(res.data.category_name)
                    //   setCategryName(res.data.category.category_name)
                      setCategoryImageUrl(res.data.treatment_url)
                      setCategoryDefaultImage(res.data.default_image_baseurl)
                    }
                    else {
                    //   setloading(false)
                    }
                  })
            })
            apiServices.countrieslistGetRequest().then(res => {
                console.log(res)
                if (res.data.countries.length > 0) {
                    setCountriesData(res.data.countries)
                    setFeaturedCountries(res.data.featuredcountries)
                }
            })
            apiServices.nationalitylistGetRequest().then(res => {
                console.log(res)
                if (res.data.nationality.length > 0) {
                    setNationalityData(res.data.nationality)
                }
            })
            apiServices.relatedtreatmentvideosPostRequest(dataString).then(res => {
                if (res.data.status === "success") {
                    setVideoData(res.data.videoData)
                }
            })
        }
        didMountRef.current = false;
    }, [])
    return (
        <>
             <Helmet>
        <title>{treatmentData.treatment_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            treatmentData.treatment_meta_desc != null
              ? treatmentData.treatment_meta_desc
              : "JDMax Healthcare"
          }
        />
        {treatmentData.treatment_meta_keyword != null ? (
          <meta
            name="keywords"
            content={treatmentData.treatment_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}
       
        <meta
          property="og:title"
          content={treatmentData.treatment_meta_title}
        />
      
      <meta name="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />
<link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            treatmentData.treatment_meta_desc != null
              ? treatmentData.treatment_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={treatmentData.treatment_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            treatmentData.treatment_meta_desc != null
              ? treatmentData.treatment_meta_desc
              : "JDMax Healthcare"
          }
        />
       
      </Helmet> 
            <Header />
            {
                spinnerLoading === false ?
                    <>
                        <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
                            <div className="container">
                                <div className="row">
                                    <div className='col-lg-12'>
                                        <h2>{treatmentData.treatment_name}</h2>
                                        <div className="breadcrumb-inner">
                                            <ul className="edu-breadcrumb">
                                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                                <li className="separator"><i className="icon-angle-right"></i></li>
                                                <li className="breadcrumb-item"><a>Treatment</a></li>
                                                <li className="separator"><i className="icon-angle-right"></i></li>
                                                <li className="breadcrumb-item">{treatmentData.treatment_name}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="section-gap-equal-small">
                            <div className="container">
                                <div className="row g-5">
                                    <div className="col-lg-8">
                                        {treatmentData.treatment_desc != null && treatmentData.treatment_desc != "" ?
                                            <InnerHTML html={treatmentData.treatment_desc.replace('{{Form_Treatment}}', treatmentShortCodeForm)} />
                                            : ""}
                                        {
                                            tabDescription.map((value, index) => (
                                                <div id={value.tab_id}>
                                                    <h4 key={index}>{value.tab_name}</h4>
                                                    {
                                                        value.tab_description != null && value.tab_description != "" ?
                                                            <InnerHTML html={value.tab_description.replace('{{Form_Treatment}}', treatmentShortCodeForm)} />
                                                            : ""
                                                    }
                                                </div>
                                            ))
                                        }
                                            <div className="edu-team-area team-area-1 edu-section-gap">
            <div className="container">
                <div className="section-title section-center">
                    <h2 className="title">Treatments</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                 
                </div>
              <div className='row'>
                  
                              {treatmentListData.map((value, index) => (
                                   <div className='col-lg-4'>
                                        <a href={`/treatment/${value.treatment_slug}`} className="catbox-item">
                                            <div className="catbox-item-img">
                                                <img src={value.treatment_image != null ? categoryImageUrl + value.treatment_image : defaultImage} className="img-fluid" alt="Speciality" />
                                            </div>
                                            <h6><a href={`/treatment/${value.treatment_slug}`}>{value.treatment_name}</a></h6>
                                        </a>
                                 </div>
                                ))}
                           </div>
                 

               
            </div>
        </div>
                                    </div>
                                    <div className='col-lg-4 siderbar-filter' ><TreatmentDetailsSideBar />
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <section className="section-gap-equal-small bg-theme">
            <div className='container'>
            <div className='row'>
            <div className='col-lg-12'>
                <div className='text-center mb-5' >
                                <h4 className="title mb-2" style={{color:"white"}}>Get a Free Personalized quote for Bariatric Surgery in India</h4>
                                <p style={{color:"white"}}>Trusted by Patients from 50+ countries</p>
                                </div>
                                </div>
                                </div>
                <div className='row'>
                    <div className='col-lg-8'>
                        <div className='quotform'>
                            <div className='row'>
                                <div className='col-lg-12'>
                            <div className='quotform-group mb-4'>
                                <label>Patient Full Name <span >*</span></label>
                            <input type="text" name="patientname"   placeholder="Patient"   />
                            </div>
                                </div>
                                <div className='col-lg-4'>
                            <div className='quotform-group mb-4'>
                                <label>Age <span >*</span></label>
                                <input type="text" name="age"  placeholder="Age" />
                            </div>
                                </div>
                                <div className='col-lg-4'>
                            <div className='quotform-group mb-4'>
                                <label>Gender <span >*</span></label>
                                <select name="gender" >
                                <option >Select</option>
                                <option value="1">Male</option>
                                <option value="2">Female</option>
                                <option value="3">Other</option>
                                </select>
                            </div>
                                </div>
                                <div className='col-lg-4'>
                            <div className='quotform-group mb-4'>
                                <label>Nationality (Passport) <span >*</span></label>
                                <select name="nationality" >
                                <option >Select</option>
                                {nationalityData.map((value) => (
                                <option value={value.nationality_id}>{value.nationality_name}</option> ))}
                                </select>
                            </div>
                                </div>
                                <div className='col-lg-6'>
                            <div className='quotform-group mb-4'>
                                <label>Email <span >*</span></label>
                                <input type="email" name="email"  placeholder="Email" />
                            </div>
                                </div>
                                <div className='col-lg-6'>
                            <div className='quotform-group mb-4'>
                                <label>Enter Same Email <span >*</span></label>
                                <input type="email" name="sameemail"  placeholder="Confirm Email" />
                            </div>
                                </div>
                                <div className='col-lg-4'>
                            <div className='quotform-group mb-4'>
                                <label>Country Code <span >*</span></label>
                                <select name="countrycode" >
                                <option >Select</option>
                                <option disabled style={{ fontWeight: "bold"}}>Popular Countries</option> 
                                {featuredCountries.map((value) => (
                                     <option value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option> 
                                ))}

                                <option disabled style={{ fontWeight: "bold"}}>All Countries</option>
                                {countriesData.map((value) => (
                                     <option value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option> 
                                ))}
                                </select>
                            </div>
                                </div>
                                <div className='col-lg-8'>
                            <div className='quotform-group mb-4'>
                                <label>Phone <span >*</span></label>
                                <input type="number" name="phone"  placeholder="Phone Number" />
                            </div>
                                </div>
                                <div className='col-lg-12'>
                            <div className='quotform-group mb-4'>
                                <label>Medical Concerns/Questions <span >*</span></label>
                                <textarea type="text" name="medicalconcerns"  placeholder="Medical Concerns/Questions" />
                            </div>
                                </div>
                                <div className='col-lg-12'>
                            <div className='quotform-group mb-4'>
                                <label>Upload Medical Records (jpeg, jpg, doc, docx, pdf)</label>
                                <input type="file" name="file"  placeholder="Choose Files" />
                            </div>
                                </div>
                            </div>
                        </div>
                        <button  type="submit" >Get Free Quotation</button></div>  
                </div>
            </div>
        </section> */}
                        <TreatmentShortcodeForm />
                        <TreatmentHospitalList data={treatmentData.treatment_name} />
                        <TreatmentDoctorList />
                        {videoData.length > 0 ?
                            <div className="edu-team-area team-area-1 section-gap-equal bg-lighten01" style={{ marginBottom: "5px" }}>
                                <div className="container">
                                    <div className="section-title section-center">
                                        <span className="pre-title">Videos</span>
                                        <h2 className="title">Top Videos</h2>
                                        <span className="shape-line"><i className="icon-19"></i></span>
                                    </div>
                                    <Swiper
                                        spaceBetween={15}
                                        breakpoints={{
                                            // when window width is >= 640px
                                            340: {
                                                width: 340,
                                                slidesPerView: 2,
                                            },
                                            640: {
                                                width: 640,
                                                slidesPerView: 2,
                                            },
                                            // when window width is >= 768px
                                            768: {
                                                width: 768,
                                                slidesPerView: 2,
                                            },
                                            1000: {
                                                width: 1000,
                                                slidesPerView: 4,
                                            },
                                            1200: {
                                                width: 1200,
                                                slidesPerView: 4,
                                            },
                                        }}
                                        className="doctorslider">
                                        {videoData.map((value) => (
                                            <SwiperSlide>
                                                <div className="edu-team-grid team-style-1">
                                                    <div className="inner">
                                                        <div className="">
                                                            <div className="">
                                                                <iframe height="300" width="100%" src={value.video_link != null ? "https://www.youtube.com/embed/" + value.video_link + "?autoplay=1" : ""}></iframe>
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="title-second">{value.video_name}</h5>
                                                            <span className="designation">{value.doctor_designation}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                        ))}
                                    </Swiper>
                                </div>
                            </div> : ""}
                        <Footer />
                    </>
                    :
                    <div className="loder">
                        <img src="/assets/images/loading-gif.webp"></img>
                    </div>
            }
        </>
    )
}
export default TreatmentDetails;