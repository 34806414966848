import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
function MyAccount() {
    const navigate = useNavigate()
    const logout = () => {
        const windowconfirm = window.confirm("Are you sure?")
        if (windowconfirm === true) {
            localStorage.removeItem('USER_SESSION');
            navigate("/")
        }
    }
    return (
        <>
            <Header />
            <div id="main-wrapper" className="main-wrapper">
                <section className="account-page-area section-gap-equal">
                    <button className="edu-btn btn-medium" type="button" fdprocessedid="9ycm7" onClick={logout}>Logout <i class="icon-4"></i></button>
                </section>
            </div>
            <Footer />
        </>
    )
}
export default MyAccount