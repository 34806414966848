import React, { useEffect, useState, useRef } from "react";
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function Footer() {
    const [imageUrl, setImageUrl] = useState('')
    const [settingData, setSettingData] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    const [Email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessmessage] = useState('')
    const [footer1, setFooter1] = useState('')
    const [footer2, setFooter2] = useState('')
    const [footer3, setFooter3] = useState('')
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            apiServices.settingsdataGetRequest().then(res => {
                if (res.data.status === "success") {
                    setImageUrl(res.data.settings_url)
                    setSettingData(res.data.sitesettings)
                    setDefaultImage(res.data.default_image_baseurl)
                }
                else {
                }
            })
            apiServices.footersGetRequest().then(res => {
                if (res.data.status === "success") {
                    setFooter1(res.data.footerData.footer_desc1)
                    setFooter2(res.data.footerData.footer_desc2)
                    setFooter3(res.data.footerData.footer_desc3)
                }
                else {
                }
            })
        }
        didMountRef.current = false;
    }, [])
    const submitmessage = (e) => {
        e.preventDefault()
        //console.log("Email",Email);
        if (Email === "") {
            document.getElementById("newsletter_email").style.border = "1px solid red"
            return false
        }
        else {
            setEmail("")
            document.getElementById("newsletter_email").style.border = "1px solid var(--color-border)"
            const dataString = {
                "newsletter_email": Email,
            }
            apiServices.newsletterPostRequest(dataString).then(res => {
                console.log(res)
                if (res.data.status === "success") {
                    setSuccessmessage(res.data.message)
                }
                else if (res.data.status === "error") {
                    setErrorMessage(res.data.message)
                }
            });
        }
    }
    return (
        <>
            <footer className="edu-footer footer-lighten bg-image footer-style-1">
                <div className="footer-top">
                    <div className="container">
                        <div className="row g-3">
                            <div className="col-lg-3 col-md-6">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <a href="/">
                                            <img className="logo-light" src="/assets/images/footerlogo.png" alt="jdmaxhealthcare" />
                                            <img className="logo-dark" src="/assets/images/footerlogo.png" alt="jdmaxhealthcare" />
                                        </a>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: footer1 }}></div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-6" dangerouslySetInnerHTML={{ __html: footer2 }}>
                            </div>
                            <div className="col-lg-3 col-sm-6" dangerouslySetInnerHTML={{ __html: footer3 }}>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="edu-footer-widget">
                                    <h4 className="widget-title">Newsletter</h4>
                                    <div className="inner">
                                        <p className="description">Enter your email address to register to our newsletter subscription</p>
                                        {successMessage !== '' ? <div className="alert alert-success" role="alert">{successMessage}</div> : null}
                                        {errorMessage !== '' ? <div className="alert alert-danger" role="alert">{errorMessage}</div> : null}
                                        <div className="input-group footer-subscription-form">

                                            <input type="email" className="form-control" placeholder="Your email" id="newsletter_email" onChange={(e) => setEmail(e.target.value)} value={Email} />
                                            <button className="edu-btn btn-medium" type="button" onClick={submitmessage} >Subscribe <i className="icon-4"></i></button>
                                        </div>
                                        <ul className="social-share icon-transparent">
                                            {
                                                settingData.facebook_url != null ? <li>
                                                    <a href={settingData.facebook_url} target="new" className="color-fb"><i className="icon-facebook"></i></a>
                                                </li> : null
                                            }
                                            {
                                                settingData.linkedin_url != null ? <li>
                                                    <a href={settingData.linkedin_url} target="new" className="color-linkd"><i className="icon-linkedin2"></i></a>
                                                </li> : null
                                            }
                                            {
                                                settingData.instagram_url != null ? <li>
                                                    <a href={settingData.instagram_url} target="new" className="color-ig"><i className="icon-instagram"></i></a>
                                                </li> : null
                                            }

                                            {
                                                settingData.twitter_url != null ? <li>

                                                    <a href={settingData.twitter_url} target="new" className="color-twitter"><i className="icon-twitter"></i></a>
                                                </li> : null
                                            }

                                            {
                                                settingData.youtube_url != null ? <li>
                                                    <a href={settingData.youtube_url} target="new" className="color-yt"><i className="icon-youtube"></i></a>
                                                </li> : null
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>Copyright 2023 <a href="/" target="_blank">JD MAX Healthcare</a>. All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="whatsapp"><a href="https://wa.me/91" target="new"><img src="/assets/images/whatsapp.png" ></img></a></div>
        </>
    )
}
export default Footer