import React, { useEffect, useRef, useState } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ApiService } from "../../Components/Services/apiservices";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import { Helmet } from "react-helmet";
let apiServices = new ApiService()
function HospitalsDetails() {
  const { slug } = useParams()
  const [hospitalDetailData, setHospitalDetailsData] = useState([])
  const [doctorData, setDoctorData] = useState([])
  const [facilityData, setFacilityData] = useState([])
  const [doctorImageUrl, setDoctorImageUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const [videoData, setVideoData] = useState([])
  const [faqData, setFaqData] = useState([])
  const [featuredCountries, setFeaturedCountries] = useState([])
  const [nationalityData, setNationalityData] = useState([])
  const [countriesData, setCountriesData] = useState([])
  const [spinnerLoading, setSpinnerLoading] = useState()
  const [image, setImage] = useState({ preview: "", raw: "" })
  const [gallaryData, setGallaryData] = useState([])
  const [feedbackDetail, setFeedbackDetail] = useState([])
  const [feedbackCount, setFeedbackCount] = useState("")
  const [video, setVideo] = useState([])
  const [hospitalDesc, setHospitalDesc] = useState('')
  const [procedureClick, setProcedureClick] = useState("")
  const [specialityData, setSpecialityData] = useState([])
  const [reviewAllData, setReviewAllData] = useState({ reviewername: "", email: "", treatment: "", experience: "" })
  const [categoryProcedure, setCategoryProcedure] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [allData, setAllData] = useState({ treatmentname: "", patientname: "", age: "", gender: "", nationality: "", email: "", countrycode: "", phone: "", medicalconcerns: "" })
  const [successMessage, setSuccessMessage] = useState("")
  const [reviewSuccessmessage, setReviewSuccessMessage] = useState("")
  const imageMimeType = /(jpg|jpeg|doc|docx|pdf|png)/i;
  const didMountRef = useRef(true)
  const location = useLocation();
  useEffect(() => {
    if (didMountRef.current) {
      setSpinnerLoading(true)
      var starrating = []
      const dataString = {
        "hospital_slug": slug
      }
      apiServices.hospitalwisefaqsPostRequest(dataString).then(res => {
        if (res.data.status == "success") {
          setFaqData(res.data.faqData)
          // setDefaultImage(res.data.default_image_baseurl)
          // setDoctorImageUrl(res.data.base_url)          
        }
      })
      apiServices.hospitaldetailsPostRequest(dataString).then(res => {
        console.log(res)
        if (res.status == 200) {
          setHospitalDetailsData(res.data)
          setHospitalDesc(res.data.hospital_desc)
          setFeedbackDetail(res.data.feedbacks)
          setFeedbackCount(res.data.feedbackscount)
          setGallaryData(res.data.gallery)
          setVideo(res.data.video)
          if (res.data.hospital_facilities_name != null) {
            setFacilityData(res.data.hospital_facilities_name.split(","))
          }
          if (res.data.hospital_category_name != null) {
            setCategoryProcedure(res.data.hospital_category_name.split(","))
          }
          setCategoryData(res.data.category)
          setSpinnerLoading(false)
        }
        else {
          setSpinnerLoading(false)
        }
      })
      apiServices.hospitalwisedoctorsPostRequest(dataString).then(res => {
        if (res.data.status == "success") {
          setDoctorData(res.data.doctorData)
          setDefaultImage(res.data.default_image_baseurl)
          setDoctorImageUrl(res.data.base_url)
        }
      })
      apiServices.hospitalwisevideosPostRequest(dataString).then(res => {
        if (res.data.status == "success") {
          setVideoData(res.data.videoData)
        }
      })
      apiServices.countrieslistGetRequest().then(res => {
        if (res.data.countries.length > 0) {
          setCountriesData(res.data.countries)
          setFeaturedCountries(res.data.featuredcountries)
        }
      })
      apiServices.nationalitylistGetRequest().then(res => {
        if (res.data.nationality.length > 0) {
          setNationalityData(res.data.nationality)
        }
      })
      apiServices.hospitalspecialitylistGetRequest().then(res => {
        console.log(res)
        if (res.data.status == "success") {
          setSpecialityData(res.data.hospitalSpecialityList)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  const handleImage = e => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Invalid Format, supported formats are | jpeg | jpg | doc | docx | pdf | png.");
      return;
    }
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };
  const getfreequotation = () => {
    if (allData.treatmentname == "") {
      document.getElementById("treatmentname").style.border = "1px solid black"
      document.getElementById("treatmentname").focus()
      return false
    }
    if (allData.patientname === "") {
      document.getElementById("patientname").style.border = "1px solid black"
      document.getElementById("patientname").focus()
      return false
    }
    else if (allData.age === "") {
      document.getElementById("age").style.border = "1px solid black"
      document.getElementById("age").focus()
      return false
    }
    else if (allData.gender === "" || allData.gender == undefined) {
      document.getElementById("gender").style.border = "1px solid black"
      document.getElementById("gender").focus()
      return false
    }
    else if (allData.nationality === "") {
      document.getElementById("nationality").style.border = "1px solid black"
      document.getElementById("nationality").focus()
      return false
    }
    else if (allData.email === "") {
      document.getElementById("emailquote").style.border = "1px solid black"
      document.getElementById("emailquote").focus()
      return false
    }
    else if (allData.countrycode === "") {
      document.getElementById("countrycode").style.border = "1px solid black"
      document.getElementById("countrycode").focus()
      return false
    }
    else if (allData.phone === "") {
      document.getElementById("phone").style.border = "1px solid black"
      document.getElementById("phone").focus()
      return false
    }
    else if (allData.medicalconcerns === "") {
      document.getElementById("medicalconcerns").style.border = "1px solid black"
      document.getElementById("medicalconcerns").focus()
      return false
    }
    const formData = new FormData();
    formData.append('page_type', "hospitals");
    formData.append('treatment_name', allData.treatmentname);
    formData.append('patient_name', allData.patientname)
    formData.append('age', allData.age)
    formData.append('gender', allData.gender)
    formData.append('nationality', allData.nationality)
    formData.append('email', allData.email)
    formData.append('country_code', allData.countrycode)
    formData.append('phone', allData.phone)
    formData.append('medical_concerns', allData.medicalconcerns)
    formData.append('medical_record_image', image.raw)
    apiServices.quotationprocessPostRequest(formData).then(res => {
      if (res.data.status == "success") {
        setSuccessMessage(res.data.message)
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      }
    })
  }
  const handlechangedata = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setAllData({ ...allData, [key]: value })
  }
  const reviewhandlechangedata = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setReviewAllData({ ...reviewAllData, [key]: value })
    if (value.length > 0) {
      document.getElementById("reviewername").style.border = "1px solid white"
      document.getElementById("email").style.border = "1px solid white"
      document.getElementById("treatment").style.border = "1px solid white"
      document.getElementById("experience").style.border = "1px solid white"
    }
  }
  const submitreviewform = () => {
    console.log(reviewAllData)
    if (reviewAllData.reviewername === "") {
      document.getElementById("reviewername").style.border = "1px solid red"
      return false
    }
    else if (reviewAllData.email === "") {
      document.getElementById("email").style.border = "1px solid red"
      return false
    }
    else if (reviewAllData.treatment === "") {
      document.getElementById("treatment").style.border = "1px solid red"
      return false
    }
    else if (reviewAllData.experience === "") {
      document.getElementById("experience").style.border = "1px solid red"
      return false
    }
    const dataString = {
      "slug": hospitalDetailData.hospital_slug,
      "page_type": "hospital",
      "reviewer_name": reviewAllData.reviewername,
      "email": reviewAllData.email,
      "treatment": reviewAllData.treatment,
      "experience": reviewAllData.experience
    }
    apiServices.feedbackprocessPostRequest(dataString).then(res => {
      if (res.data.status == "success") {
        setReviewSuccessMessage(res.data.message)
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      }
    })
  }
  const specialityhospitaldoctor = (id) => {
    const dataString = {
      "speciality_id": id,
      "hospital_id": hospitalDetailData.hospital_id
    }
    apiServices.specialitywisedoctorsPostRequest(dataString).then(res => {
      if (res.data.status === "success") {
        setDoctorData(res.data.doctorData)
      }
    })
  }
  const procedureclick = (procedurename) => {
    setProcedureClick(procedurename)
  }
  return (
    <>
      <Helmet>
        <title>{hospitalDetailData.hospital_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            hospitalDetailData.hospital_meta_desc != null
              ? hospitalDetailData.hospital_meta_desc
              : "JDMax Healthcare"
          }
        />
        {hospitalDetailData.hospital_meta_keyword != null ? (
          <meta
            name="keywords"
            content={hospitalDetailData.hospital_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}

        <meta
          property="og:title"
          content={hospitalDetailData.hospital_meta_title}
        />

        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            hospitalDetailData.hospital_meta_desc != null
              ? hospitalDetailData.hospital_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={hospitalDetailData.hospital_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            hospitalDetailData.hospital_meta_desc != null
              ? hospitalDetailData.hospital_meta_desc
              : "JDMax Healthcare"
          }
        />

      </Helmet>
      <Header />
      {spinnerLoading == false ? <>
        <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
          <div className="container">
            <div className="row">
              <div className='col-lg-12'>
                {/* <h1> {hospitalDetailData.hospital_name}</h1> */}
                <div className="breadcrumb-inner">
                  <ul className="edu-breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item"><a href="/hospitals">Hospitals</a></li>
                    <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item">
                      {hospitalDetailData.hospital_name}
                    </li>
                    {/* <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item active" aria-current="page"></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-equal">
          <div className="container">
            <div className='row'>
              <div className='col-lg-8'>
                <div className='hospdetails-section'>
                  <div className='hospdetails-section-top'>
                    <div className='hst-img'>
                      <div className='hst-img-overlay'></div>
                      <img src={hospitalDetailData.base_url + hospitalDetailData.hospital_image}></img>
                      <div className='hst-content'>
                        <div className='hst-logo' >
                          <img src={hospitalDetailData.hospital_logo != null ? hospitalDetailData.base_url + hospitalDetailData.hospital_logo : hospitalDetailData.default_image} style={{ width: "100%", height: "100px", objectFit: "cover" }} />
                        </div>
                        <div className='hst-content-right'>
                          <h1>{hospitalDetailData.hospital_name}</h1>
                          {hospitalDetailData.hospital_address != null ?
                            <p className='mb-1'>{hospitalDetailData.hospital_address}</p> : ""}
                          <div className='hst-rating'>
                            {/* <div className='rating-star'>
                              {(() => {
                                var html = []
                                for (var i = 0; i < feedbackCount; i++) {
                                  html.push(<i className="ri-star-s-fill"></i>)
                                }
                                return html
                              })()}
                            </div> */}
                            <span className='star-rating'></span>
                            {feedbackCount != null ? <a href="#" className='star-review'>({feedbackCount} Reviews) </a> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='hospdetails-section-tabs'>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a href="#overview" className={location.pathname == `/hospital/${slug}#overview` ? "nav-link active" : "nav-link"} id="overview-tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true" style={{ textAlign: 'center' }}>Overview</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a href="#procedures" className={location.pathname == `/hospital/${slug}#procedures` ? "nav-link active" : "nav-link"} id="procedures-tab" data-bs-target="#procedures" type="button" role="tab" aria-controls="procedures" aria-selected="false" style={{ textAlign: 'center' }}>Procedures</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a href='#doctors' className="nav-link " id="doctors-tab" data-bs-target="#doctors" type="button" role="tab" aria-controls="doctors" aria-selected="false" style={{ textAlign: 'center' }}>Doctors</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a href='#facilities' className="nav-link " id="facilities-tab" data-bs-target="#facilities" type="button" role="tab" aria-controls="facilities" aria-selected="false" style={{ textAlign: 'center' }}>Facilities</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a href='#reviews' className="nav-link " id="reviews-tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false" style={{ textAlign: 'center' }}>Reviews</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a href='#gallery' className="nav-link " id="gallery-tab" data-bs-target="#gallery" type="button" role="tab" aria-controls="gallery" aria-selected="false" style={{ textAlign: 'center' }}>Gallery</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a href='#faqs' className="nav-link " id="faqs-tab" data-bs-target="#faqs" type="button" role="tab" aria-controls="faqs" aria-selected="false" style={{ textAlign: 'center' }}>FAQs</a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                        {hospitalDesc !== null
                          ? <h4>Overview</h4>
                          : ""}
                        {hospitalDesc != null ?
                          <div className='hospital-content' dangerouslySetInnerHTML={{ __html: hospitalDesc }}>
                          </div> : ""}
                        {/* <p>{hospitalDetailData.hospital_desc}</p> */}
                      </div>
                      <div className="tab-pane fade show active" id="procedures" role="tabpanel" aria-labelledby="procedures-tab">
                        <h4>Procedures</h4>
                        {/* {hospitalDesc != null ?
                        <p dangerouslySetInnerHTML={{ __html: hospitalDesc }}></p>:""} */}
                        <div className='hprocedure-list'>

                          <table border={"1"} cellPadding="1" cellSpacing="1" style={{ width: '100%' }}>
                            <tbody>
                              <tr>
                                <td><strong>Procedures</strong></td>
                                <td><strong>Click For</strong></td>
                              </tr>
                              {
                                categoryData.map((value) => (
                                  <tr>
                                    <td>{value.category_name}</td>
                                    <td>
                                      <Link to="/free-consultation" state={value.category_name}>
                                        Free Consultation
                                      </Link></td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>

                          {/* <td><a href={`/free-consultation/`}>Free Consultation</a></td> */}

                        </div>

                      </div>
                      <div className="tab-pane fade show active" id="doctors" role="tabpanel" aria-labelledby="doctors-tab">
                        <h4>Doctors</h4>
                        <div className="homeselect" style={{ marginBottom: "8px", marginTop: "-36px" }}>
                          <select onChange={(e) => specialityhospitaldoctor(e.target.value)}>
                            <option >Select</option>
                            {specialityData.map((value) => (
                              <option value={value.speciality_id}>{value.speciality_name}</option>
                            ))}
                          </select>
                        </div>
                        {doctorData.map((value, index) => (
                          <div className="hosbox doctors-list" key={index}>
                            <div className="row">
                              <div className="col-lg-2">
                                <div className="hosbox-img hosbox-img-small">
                                  <img src={value.doctor_image != null ? doctorImageUrl + value.doctor_image : defaultImage} className="img-fluid"></img>
                                </div>
                              </div>
                              <div className="col-lg-8">
                                <div className="hosbox-content">
                                  <h2 className="hosbox-name"><a href={`/doctor/${value.doctor_slug}`}> {value.doctor_name}</a></h2>
                                  <p className="hosbox-loc"> {value.doctor_qualification}  </p>
                                  <div className="hosbox-list">
                                    <ul>
                                      <li>
                                        {value.speciality !== null && value.speciality !== undefined
                                          ? <b>{value.speciality.speciality_name} | </b> : ""} <b>{value.doctor_designation} </b></li>
                                      {/* {value.doctor_designation != null ?
                                      <li><span>Designation :</span> {value.doctor_designation}</li> : ""} */}
                                      <li><span>Experience-</span>{value.doctor_experience} Years</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <div className="hosbox-content-footer">
                                  <a href="#" className="edu-btn01 btn-small btn-full" style={{ marginBottom: "4px" }}> Book Appointment</a>
                                  <a href={`/doctor/${value.doctor_slug}`} className="edu-btn btn-small btn-full">View Profile <i className="icon-4"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>))}
                      </div>
                      <div className="tab-pane fade show active" id="facilities" role="tabpanel" aria-labelledby="facilities-tab">
                        <h4>Facilities</h4>
                        <div className='hospdetails-f-list'>
                          <ul>
                            {facilityData !== ""
                              ? facilityData.map((value, index) => (
                                value != null && value != "" ?
                                  <li><i className="ri-check-double-line"></i>{value}</li> : ""
                              )) : ""}
                          </ul>
                        </div>
                      </div>
                      <div className="tab-pane fade show active" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                        <h4>Reviews</h4>

                        <div className='feedbackform mb-3'>
                          <h5 className='text-center'>Share your Feedback</h5>
                          <div className='row'>
                            {reviewSuccessmessage != "" ?
                              <div class="alert alert-info">
                                <strong>{reviewSuccessmessage}</strong>
                              </div> : ""}
                            <div className='col-lg-4' style={{ marginBottom: "14px" }}>
                              <div className='form-group'>
                                <label>Name</label>
                                <input type="text" name='reviewername' id='reviewername' className='form-control' placeholder="Your Name" onChange={reviewhandlechangedata} />
                              </div>
                            </div>
                            <div className='col-lg-4'>
                              <div className='form-group'>
                                <label>Email</label>
                                <input type="text" name="email" id='email' className='form-control' placeholder="Your Email" onChange={reviewhandlechangedata} />
                              </div>
                            </div>
                            <div className='col-lg-4' >
                              <div className='form-group'>
                                <label>Treatment</label>
                                <input type="text" name="treatment" id='treatment' className='form-control' placeholder="Treatment" onChange={reviewhandlechangedata} />
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className='form-group'>
                                <label>Tell us about your experience</label>
                                <textarea type="text" name='experience' id='experience' placeholder="Tell us about your experience(Required)" onChange={reviewhandlechangedata} />
                              </div>
                              <div className="text-end">
                                <div className="edu-btn btn-small btn-full">
                                  <button id="submit-btn" type="submit" className="edu-btn btn-medium" onClick={submitreviewform}>Submit</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {feedbackDetail.map((value) => (
                          <div className='cust-review'>
                            {/* <div className='cust-review-rating'>
                            <div className="cust-review-rating-star"><i className="ri-star-s-fill"></i><i className="ri-star-s-fill"></i><i className="ri-star-s-fill"></i><i className="ri-star-s-fill"></i><i className="ri-star-s-fill"></i></div>
                          </div> */}


                            <h6>{value.feedback_name}</h6>
                            <p>{moment(value.created_at).format('DD-MM-YYYY')}</p>
                            <p>{value.feedback_experience}</p>

                          </div>))}



                      </div>
                      <div className="tab-pane fade show active" id="gallery" role="tabpanel" aria-labelledby="gallery-tab">
                        {gallaryData.length > 0 ?
                          <h4>Image Gallery</h4>
                          : ""}
                        <div className='row'>
                          {gallaryData !== undefined && gallaryData != null && gallaryData != ""
                            ? gallaryData.map((value, index) => (
                              <div className='col-lg-3'>
                                <LightGallery
                                  speed={500}
                                  plugins={[lgThumbnail, lgZoom]}
                                >
                                  <a href={value.gallery_image}><img alt="img1" src={value.gallery_image} /></a>
                                </LightGallery>
                              </div>))
                            : ""}
                        </div>
                        {videoData.length > 0 ?
                          <h4>Video Gallery</h4>
                          : ""}
                        <div className='row'>
                          {videoData.map((value) => (
                            <div className='col-lg-4'>
                              <iframe height="200" width="100%" src={value.video_link != null ? "https://www.youtube.com/embed/" + value.video_link + "?autoplay=1" : ""}></iframe>
                            </div>))}
                        </div>
                      </div>
                      <div className="tab-pane fade show active" id="faqs" role="tabpanel" aria-labelledby="faqs-tab">
                        <h4>FAQs</h4>
                        <div className="accordion" id="accordionExample">
                          {faqData.map((value, index) => (
                            <div className="accordion-item">

                              <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapseOne' + index} aria-expanded="false" aria-controls="collapseOne">
                                  {value.faq_title}
                                </button>
                              </h2>
                              <div id={"collapseOne" + index} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body" dangerouslySetInnerHTML={{ __html: value.faq_description }}>
                                </div>
                              </div>
                            </div>))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'  >
                <div className='sideform' >
                  <h2>Get Free Consultation</h2>
                  <div className='row'>
                    {successMessage != "" ?
                      <div class="alert alert-info">
                        <strong>{successMessage}</strong>
                      </div> : ""}
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Treatment Name <span >*</span></label>
                        <input type="text" name="treatmentname" placeholder="Treatment" id='treatmentname' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Patient Full Name <span >*</span></label>
                        <input type="text" name="patientname" placeholder="Patient" id='patientname' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Age <span >*</span></label>
                        <input type="number" name="age" placeholder="Age" id='age' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Gender <span >*</span></label>
                        <select name="gender" id='gender' onChange={handlechangedata}>
                          <option value="">Select</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Nationality (Passport)<span >*</span></label>
                        <select name="nationality" id='nationality' onChange={handlechangedata}>
                          <option >Select</option>
                          {nationalityData.length > 0 && nationalityData.map((value, index) => (
                            <option key={index} value={value.nationality_id}>{value.nationality_name}</option>))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Email <span >*</span></label>
                        <input type="email" name="email" placeholder="Email" id='emailquote' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Country Code <span >*</span></label>
                        <select name="countrycode" id='countrycode' onChange={handlechangedata}>
                          <option >Select</option>
                          <option disabled style={{ fontWeight: "bold" }}>Popular Countries</option>
                          {featuredCountries.length > 0 && featuredCountries.map((value, index) => (
                            <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                          ))}
                          <option disabled style={{ fontWeight: "bold" }}>All Countries</option>
                          {countriesData.length > 0 && countriesData.map((value, index) => (
                            <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Phone <span >*</span></label>
                        <input type="number" name="phone" placeholder="Phone Number" id='phone' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Medical Concerns/Questions <span >*</span></label>
                        <textarea type="text" name="medicalconcerns" placeholder="Medical Concerns/Questions" id='medicalconcerns' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Upload Medical Records (jpeg, jpg, doc, docx, pdf, png)</label>
                        <div className="custom-file">
                          <input type="file" className="custom-file-input" id="customFile" onChange={handleImage} />
                          <label className="custom-file-label" for="customFile">{image.raw.name != "" ? image.raw.name : "Choose file"}</label>
                        </div>
                      </div>
                    </div>
                    <div className='sideform-group'>
                      <button className='edu-btn02 btn-small btn-full mt-3' type='button' onClick={getfreequotation}>Get Free Quotation</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {videoData.length > 0 ?
<div className="edu-team-area team-area-1 section-gap-equal bg-lighten01" style={{marginBottom:"5px"}}>
    <div className="container">
        <div className="section-title section-center">
            <span className="pre-title">Videos</span>
            <h2 className="title">Related Videos</h2>
            <span className="shape-line"><i className="icon-19"></i></span>
        </div>
        <Swiper 
    spaceBetween={15}
    breakpoints={{
        // when window width is >= 640px
        340: {
            width: 340,
            slidesPerView:2,
            },
        640: {
            width: 640,
            slidesPerView: 2,
        },
        // when window width is >= 768px
        768: {
            width: 768,
            slidesPerView: 2,
        },
        1000: {
            width: 1000,
            slidesPerView: 5,
            },
            1200: {
                width: 1200,
                slidesPerView: 5,
                },
        }}  
    className="doctorslider">
{videoData.map((value) => (
                <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                        <div className="inner">
                            <div className="">
                                <div className="">  
                       <iframe height="300" width="100%"  src={value.video_link != null ?"https://www.youtube.com/embed/"+value.video_link+"?autoplay=1":""}></iframe>
                            </div>
                            </div>
                            <div className="content">
                                <h5 className="title-second">{value.video_name}</h5>
                                <span className="designation">{value.doctor_designation}</span>
                            </div>
                        </div>
                </div>
                </SwiperSlide>
              
              ))} 
        </Swiper>
    </div>
</div>
:""} */}

        <Footer />
      </> :
        <div className="loder">
          <img src="/assets/images/loading-gif.webp"></img>
        </div>}
    </>
  )
}

export default HospitalsDetails