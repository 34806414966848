import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import { ApiService } from "../../Components/Services/apiservices";
import { Helmet } from "react-helmet";
let apiServices = new ApiService()
function DoctorsDetails() {
  const { slug } = useParams()
  const didMountRef = useRef(true)
  const [doctorDetailData, setDoctorDetailsData] = useState("")
  const [categoryData, setCategoryData] = useState([])
  const [treatmentData, setTreatmentData] = useState([])
  const [treatmentDefaultImage , setTreatmentDefaultImage] = useState('')
  const [treatmentImageUrl , setTreatmentImageUrl] = useState('')
  const [bannerUrl, setBannerUrl] = useState('')
  const [bannerImage, setBannerImage] = useState('')
  const [hospitalData, setHospitalData] = useState([])
  const [gallaryData, setGallaryData] = useState([])
  const [relatedDocs, setRelatedDocs] = useState([])
  const [relatedTestimonial, setRelatedTestimonial] = useState([])
  const [relatedBlogs, setRelatedBlogs] = useState([])
  const [testimonialImageUrl, setTestimonialImageUrl] = useState('')
  const [blogImageUrl, setBlogImageUrl] = useState('')
  const [feedbackDetail, setFeedbackDetail] = useState([])
  const [hospitalImageUrl, setHospitalImageUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const [videoData, setVideoData] = useState([])
  const [spinnerLoading, setSpinnerLoading] = useState()
  const [nationalityData, setNationalityData] = useState([])
  const [countriesData, setCountriesData] = useState([])
  const [image, setImage] = useState({ preview: "", raw: "" })
  const [allData, setAllData] = useState({ treatmentname: "", patientname: "", age: "", gender: "", nationality: "", email: "", countrycode: "", phone: "", medicalconcerns: "" })
  const [featuredCountries, setFeaturedCountries] = useState([])
  const [doctorDesc, setDoctorDesc] = useState('')
  const [reviewAllData, setReviewAllData] = useState({ reviewername: "", email: "", treatment: "", experience: "" })
  const [successMessage, setSuccessmessage] = useState("")
  const [loading, setloading] = useState(true)
  const [reviewSuccessmessage, setReviewSuccessMessage] = useState("")
  const imageMimeType = /(jpg|jpeg|doc|docx|pdf|png)/i;
  useEffect(() => {
    if (didMountRef.current) {
      setSpinnerLoading(true)
      const dataString = {
        "doctor_slug": slug
      }
      apiServices.doctordetailsPostRequest(dataString).then(res => {
        console.log(res)
        if (res.status === 200) {
          setDoctorDetailsData(res.data)
          setDoctorDesc(res.data.doctor_desc)
          setFeedbackDetail(res.data.feedbacks)
          setGallaryData(res.data.gallery)
          setRelatedDocs(res.data.relateddocs)
          setRelatedTestimonial(res.data.relatedTestimonials)
          setTestimonialImageUrl(res.data.testimonial_img_url)
          setBlogImageUrl(res.data.blog_url)
          setRelatedBlogs(res.data.relatedBlogs)
          setCategoryData(res.data.category[0])
         
          setTreatmentData(res.data?.category[0]?.treatment)
          
          setTreatmentDefaultImage(res.data.default_image_baseurl)
          setTreatmentImageUrl(res.data.treatment_url)
          setBannerUrl(res.data.base_url)
          setBannerImage(res.data.doctor_image)
          setSpinnerLoading(false)
        }
      })
      apiServices.doctorwisehospitalsPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setHospitalData(res.data.hospitalData)
          setDefaultImage(res.data.default_image_baseurl)
          setHospitalImageUrl(res.data.hospital_url)
        }
      })
      apiServices.doctorwisevideosPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setVideoData(res.data.videoData)
        }
      })
      apiServices.countrieslistGetRequest().then(res => {
        console.log(res)
        if (res.data.countries.length > 0) {
          setCountriesData(res.data.countries)
          setFeaturedCountries(res.data.featuredcountries)
        }
      })
      apiServices.nationalitylistGetRequest().then(res => {
        console.log(res)
        if (res.data.nationality.length > 0) {
          setNationalityData(res.data.nationality)
        }
      })
    }
    didMountRef.current = false;
  })
  const handlechangedata = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setAllData({ ...allData, [key]: value })
  }
  const handleUpload = async e => {
    e.current.click();
  }
  const handleImage = e => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Invalid Format, supported formats are | jpeg | jpg | doc | docx | pdf | png.");
      return;
    }
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };
  const getfreequotation = () => {
    console.log(allData)
    if (allData.treatmentname === "") {
      document.getElementById("treatmentname").style.border = "1px solid black"
      document.getElementById("treatmentname").focus()
      return false
    }
    if (allData.patientname === "") {
      document.getElementById("patientname").style.border = "1px solid black"
      document.getElementById("patientname").focus()
      return false
    }
    else if (allData.age === "") {
      document.getElementById("age").style.border = "1px solid black"
      document.getElementById("age").focus()
      return false
    }
    else if (allData.gender === "" || allData.gender == undefined) {
      document.getElementById("gender").style.border = "1px solid black"
      document.getElementById("gender").focus()
      return false
    }
    else if (allData.nationality === "") {
      document.getElementById("nationality").style.border = "1px solid black"
      document.getElementById("nationality").focus()
      return false
    }
    else if (allData.email === "") {
      document.getElementById("emailquote").style.border = "1px solid black"
      document.getElementById("emailquote").focus()
      return false
    }
    else if (allData.countrycode === "") {
      document.getElementById("countrycode").style.border = "1px solid black"
      document.getElementById("countrycode").focus()
      return false
    }
    else if (allData.phone === "") {
      document.getElementById("phone").style.border = "1px solid black"
      document.getElementById("phone").focus()
      return false
    }
    else if (allData.medicalconcerns === "") {
      document.getElementById("medicalconcerns").style.border = "1px solid black"
      document.getElementById("medicalconcerns")
      return false.focus()
    }
    const formData = new FormData()
    formData.append('page_type', "doctors");
    formData.append('treatment_name', allData.treatmentname);
    formData.append('patient_name', allData.patientname)
    formData.append('age', allData.age)
    formData.append('gender', allData.gender)
    formData.append('nationality', allData.nationality)
    formData.append('email', allData.email)
    formData.append('country_code', allData.countrycode)
    formData.append('phone', allData.phone)
    formData.append('medical_concerns', allData.medicalconcerns)
    formData.append('medical_record_image', image.raw)
    apiServices.quotationprocessPostRequest(formData).then(res => {
      if (res.data.status == "success") {
        setSuccessmessage(res.data.message)
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      }
    })
  }
  const reviewhandlechangedata = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setReviewAllData({ ...reviewAllData, [key]: value })
    if (value.length > 0) {
      document.getElementById("reviewername").style.border = "1px solid white"
      document.getElementById("email").style.border = "1px solid white"
      document.getElementById("treatment").style.border = "1px solid white"
      document.getElementById("experience").style.border = "1px solid white"
    }
  }
  const submitreviewform = () => {
    console.log(reviewAllData)
    if (reviewAllData.reviewername === "") {
      document.getElementById("reviewername").style.border = "1px solid red"
      return false
    }
    else if (reviewAllData.email === "") {
      document.getElementById("email").style.border = "1px solid red"
      return false
    }
    else if (reviewAllData.treatment === "") {
      document.getElementById("treatment").style.border = "1px solid red"
      return false
    }
    else if (reviewAllData.experience === "") {
      document.getElementById("expt").style.border = "1px solid red"
      return false
    }
    const dataString = {
      "slug": doctorDetailData.doctor_slug,
      "page_type": "doctor",
      "reviewer_name": reviewAllData.reviewername,
      "email": reviewAllData.email,
      "treatment": reviewAllData.treatment,
      "experience": reviewAllData.experience
    }
    apiServices.feedbackprocessPostRequest(dataString).then(res => {
      if (res.data.status == "success") {
        setReviewSuccessMessage(res.data.message)
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      }
    })
  }
  return (
    <>
    <Helmet>
        <title>{doctorDetailData.doctor_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            doctorDetailData.doctor_meta_desc != null
              ? doctorDetailData.doctor_meta_desc
              : "JDMax Healthcare"
          }
        />
        {doctorDetailData.doctor_meta_keyword != null ? (
          <meta
            name="keywords"
            content={doctorDetailData.doctor_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}
       
        <meta
          property="og:title"
          content={doctorDetailData.doctor_meta_title}
        />
      
      <meta name="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />
<link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            doctorDetailData.doctor_meta_desc != null
              ? doctorDetailData.doctor_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={doctorDetailData.doctor_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            doctorDetailData.doctor_meta_desc != null
              ? doctorDetailData.doctor_meta_desc
              : "JDMax Healthcare"
          }
        />
       
      </Helmet>
      <Header />
      {spinnerLoading === false ? <>
        <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
          <div className="container">
            <div className="row">
              <div className='col-lg-8'>
                {/* <h1>{doctorDetailData.doctor_name}</h1> */}
                <div className="breadcrumb-inner">
                  <ul className="edu-breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item"><a href="/doctors">Doctors</a></li>
                    <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item">
                      {doctorDetailData.doctor_name}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-equal">
          <div className="container">
            <div className='row'>
              <div className='col-lg-8'> <div className='hospdetails-section'>
                <div className="hosbox doctors-list" >
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="hosbox-img">
                        <img src={bannerUrl + bannerImage} className="img-fluid"></img>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="hosbox-content">
                        <h2 className="hosbox-name"> {doctorDetailData.doctor_name}</h2>
                        <p className="hosbox-loc"> {doctorDetailData.doctor_qualification}  </p>
                        <div className="hosbox-list">
                          <ul>
                            <li>{doctorDetailData.speciality !== null && doctorDetailData.speciality !== undefined
                              ? <b>{doctorDetailData.speciality.speciality_name} | </b> : ""} <b>{doctorDetailData.doctor_designation} </b></li>
                            <li><span>Experience-</span>{doctorDetailData.doctor_experience} <span>Years</span></li>

                            {doctorDetailData.hospital != null ?
                              <li><span style={{ display: "inline" }}>Works at-</span> {doctorDetailData.hospital.hospital_name}</li> : ""}
                            {doctorDetailData.doctor_city_name != null && doctorDetailData.doctor_country_name != null && doctorDetailData.doctor_city_name != "" && doctorDetailData.doctor_country_name != ""
                              ?
                              <li><span style={{ display: "inline" }}> </span>{doctorDetailData.doctor_city_name} , {doctorDetailData.doctor_country_name}</li> : ""}
                          </ul>

                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                              <div className="hosbox-content-footer">
                              <a href={"/free-consultation/2/"+doctorDetailData.doctor_id} className="edu-btn btn-small btn-full" style={{ marginBottom: "4px" }}>Book Appointment</a>
                                  <a href="https://wa.me/91" target="new" className="edu-btn03 btn-small btn-full">WhatsApp Us </a>
                              </div>
                            </div>
                  </div>
                </div>
                <div className='hospdetails-section-tabs'>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a href="#overview" className="nav-link active" id="overview-tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true" style={{ textAlign: 'center'}}>Overview</a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <button className="nav-link " id="experience-tab" data-bs-target="#experience" type="button" role="tab" aria-controls="experience" aria-selected="false">Experience</button>
                    </li> */}
                    <li className="nav-item" role="presentation">
                      <a href="#hospitals" className="nav-link " id="hospitals-tab" data-bs-target="#hospitals" type="button" role="tab" aria-controls="hospitals" aria-selected="false" style={{ textAlign: 'center'}}>Hospitals/Clinics</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a href="#procedures" className="nav-link " id="procedures-tab" data-bs-target="#procedures" type="button" role="tab" aria-controls="procedures" aria-selected="false" style={{ textAlign: 'center'}}>Procedures</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a href="#gallery" className="nav-link " id="gallery-tab" data-bs-target="#gallery" type="button" role="tab" aria-controls="gallery" aria-selected="false" style={{ textAlign: 'center'}}>Gallery</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a href="#reviews" className="nav-link " id="reviews-tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false" style={{ textAlign: 'center'}}>Reviews</a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent" >
                    <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="overview-tab" >
                      {doctorDesc != null
                        ? <h4>Overview</h4>
                        : ""}
                      {doctorDesc != null ?
                        <div className='doctor-content' dangerouslySetInnerHTML={{ __html: doctorDesc }}>
                        </div> : ""}
                    </div>
                    <div className="tab-pane fade show active" id="hospitals" role="tabpanel" aria-labelledby="hospitals-tab">
                      <h4>Hospitals/Clinics</h4>
                      {hospitalData.map((value) => (
                        <div className="hosbox">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="hosbox-img">
                                <img src={value.hospital_image != null ? hospitalImageUrl + value.hospital_image : defaultImage} className="img-fluid"></img>
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="hosbox-content">
                                {value.hospital_name != null ? <h2 className="hosbox-name"><a href={`/hospital/${value.hospital_slug}`}>{value.hospital_name}</a></h2> : ""}
                                {value.hospital_city_name != null && value.hospital_country_name != null && value.hospital_city_name !== "" && value.hospital_country_name !== "" ?
                                  <p className="hosbox-loc"> {value.hospital_city_name}, {value.hospital_country_name} </p> : ""}
                                <div className="hosbox-list-second mt-2">
                                  <ul>
                                    {value.hospital_type != null ?
                                      <li>{value.hospital_type === 1 ? "Single Speciality" : ""} {value.hospital_type === 2 ? "Multiple Speciality" : ""} {value.hospital_type === 3 ? "Super Speciality" : ""} </li> : ""}
                                    {value.hospital_bed != null ? <li>No. of Beds : {value.hospital_bed} </li> : ""}
                                  </ul>
                                </div>
                                <p className='short-des'>{value.hospital_short_description != null ? value.hospital_short_description : ""}</p>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="haccreditation_image mb-5">
                                {value.accreditation != null && value.accreditation != undefined && value.accreditation.map((subvalue) => (
                                  <img src={subvalue.accreditation_image != null ? hospitalImageUrl + subvalue.accreditation_image : defaultImage} className="img-fluid" style={{ width: "20%" }}></img>
                                ))}
                              </div>
                              <div className="hosbox-content-footer" >
                                <a href="#" className="edu-btn01 btn-small btn-full" style={{ marginBottom: "4px" }}> Free Consultation</a>
                                <a href={`/hospital/${value.hospital_slug}`} className="edu-btn btn-small btn-full">View Profile <i className="icon-4"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>))}
                    </div>
                    {treatmentData ?
                    <div className="tab-pane fade show active" id="procedures" role="tabpanel" aria-labelledby="procedures-tab">
                      <h4>Procedures</h4>
                      <div className='hprocedure-list'>
                        {/* <ul>
                          <li><a href="#">All Procedures</a></li>
                          {categoryData.map((value) => (
                            <li><a href="#">{value.category_name}</a></li>
                          ))}
                        </ul> */}

<table border={"1"} cellPadding="1" cellSpacing="1" style={{width: '100%'}}>
	<tbody>
		<tr>
			<td><strong>Procedures</strong></td>
			<td><strong>Click For</strong></td>
		</tr>
    

{treatmentData?.map((value) => (
		<tr>
			<td>{value.treatment_name}</td>
			<td>
<Link to= "/free-consultation" state =  {value.treatment_name}>
Free Consultation
</Link></td>
		</tr>
	))}
	</tbody>
</table>

			{/* <td><a href={`/free-consultation/`}>Free Consultation</a></td> */}

                      </div>
                    </div>:''}
                    <div className="tab-pane fade show active" id="gallery" role="tabpanel" aria-labelledby="gallery-tab">
                      {gallaryData.length > 0 ?
                        <h4>Image Gallery</h4>
                        : ""}
                      <div className='row'>
                        {gallaryData !== undefined && gallaryData != null && gallaryData != ""
                          ? gallaryData.map((value, index) => (
                            <div className='col-lg-3'>
                              <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgZoom]}
                              >
                                <a href={value.gallery_image}><img alt="img1" src={value.gallery_image} /></a>
                              </LightGallery>
                            </div>))
                          : ""}
                      </div>
                      {videoData.length > 0 ?
                        <h4>Video Gallery</h4>
                        : ""}
                      <div className='row'>
                        {videoData.map((value) => (
                          <div className='col-lg-4'>
                            <iframe height="200" width="100%" src={value.video_link != null ? "https://www.youtube.com/embed/" + value.video_link + "?autoplay=1" : ""}></iframe>
                          </div>))}
                      </div>
                    </div>
                    <div className="tab-pane fade show active" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                      <h4>Reviews</h4>
                      <div className='feedbackform mb-3'>
                        <h5 className='text-center'>Share your Feedback</h5>
                        <div className='row'>
                          {reviewSuccessmessage != "" ?
                            <div className="alert alert-info">
                              <strong>{reviewSuccessmessage}</strong>
                            </div> : ""}
                          <div className='col-lg-4' style={{ marginBottom: "14px" }}>
                            <div className='form-group'>
                              <label>Name</label>
                              <input type="text" name='reviewername' id='reviewername' className='form-control' placeholder="Your Name" onChange={reviewhandlechangedata} />
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <div className='form-group'>
                              <label>Email</label>
                              <input type="text" name="email" id='email' className='form-control' placeholder="Your Email" onChange={reviewhandlechangedata} />
                            </div>
                          </div>
                          <div className='col-lg-4' >
                            <div className='form-group'>
                              <label>Treatment</label>
                              <input type="text" name="treatment" id='treatment' className='form-control' placeholder="Treatment" onChange={reviewhandlechangedata} />
                            </div>
                          </div>
                          <div className='col-lg-12'>
                            <div className='form-group'>
                              <label>Tell us about your experience</label>
                              <textarea type="text" name='experience' id='expt' placeholder="Tell us about your experience(Required)" onChange={reviewhandlechangedata} />
                            </div>
                            <div className="text-end">
                              <div className="edu-btn btn-small btn-full">
                                <button id="submit-btn" type="submit" className="edu-btn btn-medium" onClick={submitreviewform}>Submit</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {feedbackDetail.map((value) => (
                        <div className='cust-review'>
                          {/* <div className='cust-review-rating'>
                            <div className="cust-review-rating-star"><i className="ri-star-s-fill"></i><i className="ri-star-s-fill"></i><i className="ri-star-s-fill"></i><i className="ri-star-s-fill"></i><i className="ri-star-s-fill"></i></div>
                          </div> */}
                     
                          
                          <h6>{value.feedback_name}</h6>
                          <p>{moment(value.created_at).format('DD-MM-YYYY')}</p>
                          <p>{value.feedback_experience}</p>
                        
                          </div>  ))}

                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className='col-lg-4'>
                <div className='sideform'>
                  <h2>Get Free Consultation</h2>
                  <div className='row'>
                    {successMessage != "" ?
                      <div class="alert alert-info">
                        <strong>{successMessage}</strong>
                      </div> : ""}
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Treatment Name <span >*</span></label>
                        <input type="text" name="treatmentname" placeholder="Treatment" id='treatmentname' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Patient Full Name <span >*</span></label>
                        <input type="text" name="patientname" placeholder="Patient" id='patientname' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Age <span >*</span></label>
                        <input type="number" name="age" placeholder="Age" id='age' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Gender <span >*</span></label>
                        <select name="gender" id='gender' onChange={handlechangedata}>
                          <option value="">Select</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Nationality (Passport)<span >*</span></label>
                        <select name="nationality" id='nationality' onChange={handlechangedata}>
                          <option >Select</option>
                          {nationalityData.length > 0 && nationalityData.map((value, index) => (
                            <option key={index} value={value.nationality_id}>{value.nationality_name}</option>))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Email <span >*</span></label>
                        <input type="email" name="email" placeholder="Email" id='emailquote' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Country Code  <span >*</span></label>
                        <select name="countrycode" id='countrycode' onChange={handlechangedata}>
                          <option >Select</option>
                          <option disabled style={{ fontWeight: "bold" }}>Popular Countries</option>
                          {featuredCountries.length > 0 && featuredCountries.map((value, index) => (
                            <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                          ))}
                          <option disabled style={{ fontWeight: "bold" }}>All Countries</option>
                          {countriesData.length > 0 && countriesData.map((value, index) => (
                            <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Phone <span >*</span></label>
                        <input type="number" name="phone" placeholder="Phone Number" id='phone' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Medical Concerns/Questions <span >*</span></label>
                        <textarea type="text" name="medicalconcerns" placeholder="Medical Concerns/Questions" id='medicalconcerns' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Upload Medical Records (jpeg, jpg, doc, docx, pdf, png)</label>
                        <div className="custom-file">
                          <input type="file" className="custom-file-input" id="customFile" onChange={handleImage} />
                          <label className="custom-file-label" for="customFile">{image.raw.name !== "" ? image.raw.name : "Choose file"}</label>
                        </div>
                      </div>
                    </div>
                    <div className='sideform-group'>
                      <button className='edu-btn02 btn-small btn-full mt-3' type='button' onClick={getfreequotation}>Get Free Quotation</button>
                    </div>
                  </div>
                </div>

{treatmentData ?
<div className='filterbox mb-4'>
    <div className="filterbox-title" style={{marginTop : "24px"}}>You May Also Interested in</div>
    <div className='filterbox-body '>
    {
treatmentData.map((value) => (
           <div className="latest-post">
           <div className="thumbnail">
             <a href= {'/treatment/' + categoryData.category_slug+"/"+ value.treatment_slug}>
               <img src={value.treatment_image!=null ? treatmentImageUrl +value.treatment_image :defaultImage}  alt="Image"></img>
               </a>
             </div>
             <div className="post-content">
               <h6 className="title"><a href={'/treatment/' + categoryData.category_slug+"/"+ value.treatment_slug}>Best {value.treatment_name} in India</a></h6>
               {/* <ul className="blog-meta"><li>Read more</li></ul> */}
               <a href={'/treatment/' + categoryData.category_slug+"/"+ value.treatment_slug} className='lp-readmore'> Read more</a>
               </div>
               </div>
         
          ))}</div>
      </div>:""}




</div>
            </div>
          </div>
        </section>


 {relatedDocs?
          <div className="edu-team-area team-area-1 section-gap-equal" >
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Doctors</span>
                <h2 className="title">Related Doctors</h2>
                <span className="shape-line"><i className="icon-19"></i></span>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 5,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5,
                  },
                }}
                className="doctorslider">
                {relatedDocs.map((value) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                      <div className="inner">
                        <div className="thumbnail-wrap">
                          <div className="thumbnail">
                            <a href={`/doctor/${value.doctor_slug}`}>
                              <img src={value.doctor_image != null ? bannerUrl + value.doctor_image : defaultImage} ></img>
                            </a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title-second"><a href={`/doctor/${value.doctor_slug}`}>{value.doctor_name} </a></h5>
                          <span className="designation">{value.doctor_designation}</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div> : ""}


          {relatedTestimonial ?
          <div className="edu-team-area team-area-1 section-gap-equal" >
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Testimonials</span>
                <h2 className="title">Related Testimonials</h2>
                <span className="shape-line"><i className="icon-19"></i></span>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 5,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5,
                  },
                }}
                className="doctorslider">
                {relatedTestimonial.map((value) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                      <div className="inner">
                        <div className="thumbnail-wrap">
                          <div className="thumbnail">
                            <a href={`/patient-testimonials/${value.testimonial_url}`}>
                              <img src={value.testimonial_profile != null ? testimonialImageUrl + value.testimonial_profile : defaultImage} ></img>
                            </a>
                          </div>
                        </div>
                        <div className="content">
                          
                          <h5 className="title-second"><a href={`/patient-testimonials/${value.testimonial_url}`}>{value.testimonial_title} </a></h5>
                          {/* <span className="designation">{value.doctor_designation}</span> */}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div> : ""}

      

          {relatedBlogs ?
          <div className="edu-team-area team-area-1 section-gap-equal" >
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Blogs</span>
                <h2 className="title">Related Blogs</h2>
                <span className="shape-line"><i className="icon-19"></i></span>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 5,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5,
                  },
                }}
                className="doctorslider">
                {relatedBlogs.map((value) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                      <div className="inner">
                        <div className="thumbnail-wrap">
                          <div className="thumbnail">
                            <a href={`/blogs/${value.blog_slug}`}>
                              <img src={value.blog_image != null ? blogImageUrl + value.blog_image : defaultImage} ></img>
                            </a>
                          </div>
                        </div>
                        <div className="content">
                          
                          <h5 className="title-second"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name} </a></h5>
                          {/* <span className="designation">{value.doctor_designation}</span> */}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div> : ""}






        {/* {hospitalData.length > 0 ?
          <div className="edu-team-area team-area-1 section-gap-equal" >
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Hospitals</span>
                <h2 className="title">Related Hospitals</h2>
                <span className="shape-line"><i className="icon-19"></i></span>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 5,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5,
                  },
                }}
                className="doctorslider">
                {hospitalData.map((value) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                      <div className="inner">
                        <div className="thumbnail-wrap">
                          <div className="thumbnail">
                            <a href={`/hospitals/${value.hospital_slug}`}>
                              <img src={value.hospital_image != null ? hospitalImageUrl + value.hospital_image : defaultImage}></img>
                            </a>
                          </div>
                        </div>
                        <div className="content">
                          <h6 className="title"><a href={`/hospitals/${value.hospital_slug}`}>{value.hospital_name}</a></h6>
                          {value.hospital_city_name != "" && value.hospital_country_name != "" ?
                            <p>{value.hospital_city_name} , {value.hospital_country_name}</p> : ""}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                ))}
              </Swiper>
            </div>
          </div> : ""}
        {videoData.length > 0 ?
          <div className="edu-team-area team-area-1 section-gap-equal bg-lighten01" style={{ marginBottom: "5px" }}>
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Videos</span>
                <h2 className="title">Related Videos</h2>
                <span className="shape-line"><i className="icon-19"></i></span>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 5,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5,
                  },
                }}
                className="doctorslider">
                {videoData.map((value) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                      <div className="inner">
                        <div className="">
                          <div className="">

                            <iframe height="300" width="100%" src={value.video_link != null ? "https://www.youtube.com/embed/" + value.video_link + "?autoplay=1" : ""}></iframe>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title-second">{value.video_name}</h5>
                          <span className="designation">{value.doctor_designation}</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                ))}
              </Swiper>
            </div>
          </div>: ""} */}
        <Footer />
      </> :
        <div className="loder">
          <img src="/assets/images/loading-gif.webp"></img>
        </div>}

    </>
  )
}

export default DoctorsDetails