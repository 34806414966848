import axios from 'axios';
import constant from './constant';

const categorydUrl = "categorydata";
const appearencemenusdataUrl = "appearencemenusdata";
const blogsdataUrl = "blog-list"
const newsblogsUrl = "news-blog"
const testimonialsUrl = "testimonials"
const settingsdataUrl = "settings"
const hospitalsUrl = "featured-hospitals"
const doctorsUrl = "featured-doctors"
const footersUrl = "footers"
const headerUrl = "header";
const doctorslistUrl = "doctors-list";
const hospitallistUrl = "hospital-list";
const packages = "packagesdata";
const packageslist = "packages-list";
const populartreatmentUrl = "popular-treatment";
const medicaltreatementparentcatdataUrl = "medicaltreatementparentcatdata";
const hospitalbycategories = "categories-wise-hospital";
const treatmentbycategories = "categories-wise-treatment";
const hospitalcountrylistUrl = "hospital-country-list" 
const pagesUrl = "page";
const bloglistsidebardataUrl = "blog-list-sidebar-data";
const categorywisebloglistUrl = "category-wise-blog-list";
const relatedblogsUrl = "related-blogs";
const treatmentmenus = "treatment-menus";
const treatmentdetailsUrl = "treatment-details"
const hospitalcategorylistUrl = "hospital-category-list" 
const hospitalfacilitylistUrl = "hospital-facilities-list"
const doctorcountrylistUrl = "doctor-country-list" 
const doctorspecialitylistUrl = "doctor-speciality-list"
const doctorhospitallistUrl  =  "doctor-hospital-list";
const blogdetailsUrl = "blog-details"
const testimonialdetailUrl ="testimonial-detail"
const contactusprocessUrl ="contact-us-process";
const testimonialcategoryUrl = "testimonial-category"
const treatmenthospitalsUrl ="related-treatment-hospitals";
const treatmentdoctorsUrl = "related-treatment-doctors"
const hospitalscitiesUrl = "hospitals-cities"
const cityfeaturedhospitalsUrl = "city-wise-featured-hospitals";
const citywisefeatureddoctorsUrl = "city-wise-featured-doctors";
const hospitalfiltersUrl = "hospital-filters"
const doctorsFilterUrl = "doctorsFilter"
const packagesdetails = "packages-details"
const testimoniallist = "testimonial-list"
const newsletterurl = "newsletter"
const bloghospitalsUrl ="relatedblogsHospitals";
const blogdoctorsUrl ="relatedblogDoctors";
const testimonialfilterUrl = "testimonial-filter";
const categorywisetestimonialUrl = "category-wise-testimonial";
const relatedblogvideosUrl = "related-blog-videos";
const relatedtestimonialhospitalsUrl = "related-testimonial-hospitals";
const relatedtestimonialdoctorsUrl = "related-testimonial-doctors";
const relatedtestimonialvideosUrl = "related-testimonial-videos";
const hospitaldetailsUrl = "hospital-details"
const hospitalwisedoctorsUrl = "hospital-wise-doctors"
const hospitalwisevideosUrl = "hospital-wise-videos"
const doctordetailsUrl = "doctor-details"
const doctorwisehospitalsUrl = "doctor-wise-hospitals"
const doctorwisevideosUrl = "doctor-wise-videos"
const countrieslistUrl = "countries-list"
const nationalitylistUrl = "nationality-list"
const relatedcategoryhospitalsUrl = "related-category-hospitals"
const relatedcategorydoctorsUrl = "related-category-doctors"
const relatedcategoryvideosUrl = "related-category-videos"
const relatedtreatmentvideosUrl = "related-treatment-videos"
const hospitalwisefaqsUrl = "hospital-wise-faqs"
const hospitalspecialitylistUrl = "hospital-speciality-list";
const specialitywisedoctorsUrl = "speciality-wise-doctors";
const enquiryprocessUrl = "enquiry-process";
const quotationprocessUrl = "quotation-process";
const packageenquiryUrl = "package-enquiry-process";
const feedbackprocessUrl = "feedback-process";
const saveFreeConsultationLeadsUrl = "saveFreeConsultationLeads";
const userRegisterUrl = "userRegister";
const logincheckUrl = "logincheck";
const countrydataUrl = "country-data";
const citiesdataUrl = "city-data";

const userregisterprocessUrl = "user-register-process"
const PagesDataUrl = "PagesData"
const dashboardsearchUrl = "dashboardsearch"
const doctorsearchbynameUrl = "doctorsearchbyname"
const videolistUrl = "videolist"
const featuredvideolistUrl = "featuredvideolist"


const client = axios.create({
    baseURL: constant.API_URL,
  });
  export class ApiService {
    categoryPostRequest(request) {
      return client.post(categorydUrl, request)
    }
    // appearencemenusdataPostRequest(request) {
    //   return client.post(appearencemenusdataUrl, request)
    // }
    newsblogsdataGetRequest() {
      return client.get(blogsdataUrl);
    }
    newsblogsUrlPostRequest(request) {
      return client.post(newsblogsUrl, request)
    }
    testimonialsGetRequest() {
      return client.get(testimonialsUrl)
    }
    blogsdataGetRequest() {
      return client.get(blogsdataUrl);
    }
    settingsdataGetRequest() {
      return client.get(settingsdataUrl);
    }
    featuredhospitalsGetRequest() {
      return client.get(hospitalsUrl);
    }
    doctorsGetRequest() {
      return client.get(doctorsUrl);
    }
    footersGetRequest() {
      return client.get(footersUrl);
    }
    headerGetRequest() {
      return client.get(headerUrl);
    }
    doctorslistPostRequest(request) {
      return client.post(doctorslistUrl , request);
    }
    hospitallistPostRequest(request) {
      return client.post(hospitallistUrl,request);
    }
    packagesGetRequest() {
      return client.get(packages);
    }
    populartreatmentGetRequest() {
      return client.get(populartreatmentUrl);
    }
    medicaltreatementparentcatdataGetRequest() {
      return client.get(medicaltreatementparentcatdataUrl);
    }
    pagesPostRequest(request) {
      return client.post(pagesUrl, request)
    }
    hospitalbycategoriesPostRequest(request) {
      return client.post(hospitalbycategories, request)
    }
    treatmentbycategoriesPostRequest(request) {
      return client.post(treatmentbycategories, request)
    }
    hospitalcountrylistGetRequest() {
      return client.get(hospitalcountrylistUrl);
    }
    bloglistsidebardataGetRequest() {
      return client.get(bloglistsidebardataUrl);
    }
    categorywisebloglistPostRequest(request) {
      return client.post(categorywisebloglistUrl, request)
    }
    relatedblogsPostRequest(request) {
      return client.post(relatedblogsUrl, request)
    }
    treatmentmenusGetRequest() {
      return client.get(treatmentmenus);
    }
    treatmentdetailsPostRequest(request) {
      return client.post(treatmentdetailsUrl, request)
    }
    hospitalcategorylistGetRequest() {
      return client.get(hospitalcategorylistUrl);
    }
    hospitalfacilitylistGetRequest() {
      return client.get(hospitalfacilitylistUrl);
    }
    doctorcountrylistGetRequest() {
      return client.get(doctorcountrylistUrl);
    }
    doctorspecialitylistGetRequest() {
      return client.get(doctorspecialitylistUrl);
    }
    doctorhospitallistGetRequest() {
      return client.get(doctorhospitallistUrl);
    }
    blogdetailsPostRequest(request) {
      return client.post(blogdetailsUrl, request)
    }
    testimonialdetailPostRequest(request) {
      return client.post(testimonialdetailUrl, request)
    }
    contactusprocessPostRequest(request) {
      return client.post(contactusprocessUrl, request)
    }
    testimonialcategoryGetRequest() {
      return client.get(testimonialcategoryUrl);
    }
    treatmenthospitalsPostRequest(request) {
      return client.post(treatmenthospitalsUrl, request)
    }
    treatmentdoctorsPostRequest(request) {
      return client.post(treatmentdoctorsUrl, request)
    }
    hospitalscitiesGetRequest() {
      return client.get(hospitalscitiesUrl);
    }
    cityfeaturedhospitalsPostRequest(request) {
      return client.post(cityfeaturedhospitalsUrl, request)
    }
    citywisefeatureddoctorsPostRequest(request) {
      return client.post(citywisefeatureddoctorsUrl, request)
    }
    hospitalfiltersPostRequest(request) {
      return client.post(hospitalfiltersUrl, request)
    }
    doctorsFilterPostRequest(request) {
      return client.post(doctorsFilterUrl, request)
    }
    packageslistPostRequest(request) {
      return client.post(packageslist, request)
    }
  
    packagesdetailsPostRequest(request) {
      return client.post(packagesdetails, request)
    }
    testimoniallistGetRequest() {
      return client.get(testimoniallist);
    }
    newsletterPostRequest(request) {
      return client.post(newsletterurl, request)
    }
    bloghospitalsPostRequest(request) {
      return client.post(bloghospitalsUrl, request)
    }
    blogdoctorsPostRequest(request) {
      return client.post(blogdoctorsUrl, request)
    }
    testimonialfilterPostRequest(request) {
      return client.post(testimonialfilterUrl, request)
    }
    categorywisetestimonialPostRequest(request) {
      return client.post(categorywisetestimonialUrl, request)
    }
    relatedblogvideosPostRequest(request) {
      return client.post(relatedblogvideosUrl, request)
    }
    relatedtestimonialhospitalsPostRequest(request) {
      return client.post(relatedtestimonialhospitalsUrl, request)
    }
    relatedtestimonialdoctorsPostRequest(request) {
      return client.post(relatedtestimonialdoctorsUrl, request)
    }
    relatedtestimonialvideosPostRequest(request) {
      return client.post(relatedtestimonialvideosUrl, request)
    }
    hospitaldetailsPostRequest(request) {
      return client.post(hospitaldetailsUrl, request)
    }
    hospitalwisedoctorsPostRequest(request) {
      return client.post(hospitalwisedoctorsUrl, request)
    }
    hospitalwisevideosPostRequest(request) {
      return client.post(hospitalwisevideosUrl, request)
    }
    doctordetailsPostRequest(request) {
      return client.post(doctordetailsUrl, request)
    }
    doctorwisehospitalsPostRequest(request) {
      return client.post(doctorwisehospitalsUrl, request)
    }
    doctorwisevideosPostRequest(request) {
      return client.post(doctorwisevideosUrl, request)
    }
    countrieslistGetRequest() {
      return client.get(countrieslistUrl);
    }
    nationalitylistGetRequest() {
      return client.get(nationalitylistUrl);
    }
    relatedcategoryhospitalsPostRequest(request) {
      return client.post(relatedcategoryhospitalsUrl, request)
    }
    relatedcategorydoctorsPostRequest(request) {
      return client.post(relatedcategorydoctorsUrl, request)
    }
    relatedcategoryvideosPostRequest(request) {
      return client.post(relatedcategoryvideosUrl, request)
    }
    relatedtreatmentvideosPostRequest(request) {
      return client.post(relatedtreatmentvideosUrl, request)
    }
    hospitalwisefaqsPostRequest(request) {
      return client.post(hospitalwisefaqsUrl, request)
    }
    hospitalspecialitylistGetRequest() {
      return client.get(hospitalspecialitylistUrl);
    }
    specialitywisedoctorsPostRequest(request) {
      return client.post(specialitywisedoctorsUrl,request)
    }
    enquiryprocessPostRequest(request) {
      return client.post(enquiryprocessUrl,request)
    }
    quotationprocessPostRequest(request) {
      return client.post(quotationprocessUrl,request)
    }
    feedbackprocessPostRequest(request) {
      return client.post(feedbackprocessUrl,request)
    }
    saveFreeConsultationLeadsPostRequest(request) {
      return client.post(saveFreeConsultationLeadsUrl,request)
    }
    userRegisterPostRequest(request) {
      return client.post(userRegisterUrl,request)
    }
    logincheckPostRequest(request) {
      return client.post(logincheckUrl,request)
    }
    countrydataGetRequest() {
      return client.get(countrydataUrl);
    }
    userregisterprocessPostRequest(request) {
      return client.post(userregisterprocessUrl,request)
    }
    packageenquiryPostRequest(request) {
      return client.post(packageenquiryUrl,request)
    }
    citieslistGetRequest() {
      return client.get(citiesdataUrl);
    }
    PagesDataPostRequest(request) {
      return client.post(PagesDataUrl,request)
    }
    
    dashboardsearchPostRequest(request) {
      return client.post(dashboardsearchUrl,request)
    }
    doctorsearchbynamePostRequest(request) {
      return client.post(doctorsearchbynameUrl,request)
    }
    
    videolistPostRequest() {
      return client.get(videolistUrl,)
    }
    featuredvideolistPostRequest() {
      return client.get(featuredvideolistUrl)
    }
    
 }