import React, { useEffect, useState, useRef } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import HospitalFilter from "./hospitalfilter";
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
import ListingShortcodeForm from "../ListingShortcodeForm";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
let apiServices = new ApiService();
function Hospitals() {
  const [hospitalData, setHospitalData] = useState([])
  const [imageUrl, setImageUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const [loading, setloading] = useState(true)
  const [ReqFilterData, setReqFilterData] = useState([]);
  const [metaTags , setMetaTags] = useState([])
  const { slug1 } = useParams();
  const didMountRef = useRef(true)
  const Navigate = useNavigate()
  useEffect(() => {
    if (didMountRef.current) {
      console.log("hospital", slug1);
      var datastring = {};
      if (slug1 != undefined && slug1 != null && slug1.split('+').length > 0) {
        datastring = {
          filterdata: slug1.split('+')
        }
      } else {
        datastring = {}
      }
      apiServices.hospitallistPostRequest(datastring).then(res => {
        if (res.data.status == "success") {
          setloading(false)
          setHospitalData(res.data.hospitalList)
          setMetaTags(res.data.metatags)
          setImageUrl(res.data.hospital_url)
          setDefaultImage(res.data.default_image_baseurl)
        }
        else {
          setloading(false)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  return (
    <>
       <Helmet>
        <title>{metaTags?.title ? metaTags?.title:"JDMax Healthcare"}</title>
        <meta name="description" itemprop="description" content={metaTags?.desc != null ?metaTags?.desc:"JDMax Healthcare"} />
        <meta name="keywords" content={metaTags?.keyword ? metaTags?.keyword:"JDMax Healthcare"} />  
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={metaTags?.title ? metaTags?.title:"JDMax Healthcare"} />
        <meta property="og:image" content= ""/>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content= {metaTags.desc != null ?metaTags.desc:"JDMax Healthcare"} />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={metaTags?.title? metaTags?.title: "JDMax Healthcare"} />
        <meta name="twitter:description" content={metaTags?.desc != null ?metaTags?.desc:"JDMax Healthcare"} />
        <meta property="twitter:image" content= ""/>
    </Helmet>
      <Header />
      <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
        <div className="container">
          <div className="row">
            <div className='col-lg-12'>
              <h1>Clinics and hospitals</h1>
              <div className="breadcrumb-inner">
                <ul className="edu-breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="separator"><i className="icon-angle-right"></i></li>
                  <li className="breadcrumb-item">Hospitals</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-gap-equal-small">
        <div className="container">
          <div className="row g-5">
            <HospitalFilter />
            <div className="col-lg-9">
              {/* <h1 className="page-title-h1">Clinics and hospitals</h1> */}
              <div className="shortby">
                <div className="shortby-count">Showing {hospitalData.length} entries</div>
                {/* <div className="shortby-list">
                 <span>Sort by</span>
                 <select></select>
                </div> */}
              </div>
              {loading == true ? <>
                <div className="hosbox doctors-list">
                  <div className="row">
                    <div className="col-lg-3">
                      <Skeleton varient="rectangular" width={200} height={200} />
                    </div>
                    <div className="col-lg-7">
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                    </div>
                    <div className="col-lg-2">
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                    </div>
                  </div>
                </div>
                <div className="hosbox doctors-list">
                  <div className="row">
                    <div className="col-lg-3">
                      <Skeleton varient="rectangular" width={200} height={200} />
                    </div>
                    <div className="col-lg-7">
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                    </div>
                    <div className="col-lg-2">
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                    </div>
                  </div>
                </div>
                <div className="hosbox doctors-list">
                  <div className="row">
                    <div className="col-lg-3">
                      <Skeleton varient="rectangular" width={200} height={200} />
                    </div>
                    <div className="col-lg-7">
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                    </div>
                    <div className="col-lg-2">
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                    </div>
                  </div>
                </div>
                <div className="hosbox doctors-list">
                  <div className="row">
                    <div className="col-lg-3">
                      <Skeleton varient="rectangular" width={200} height={200} />
                    </div>
                    <div className="col-lg-7">
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                    </div>
                    <div className="col-lg-2">
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                    </div>
                  </div>
                </div>
                <div className="hosbox doctors-list">
                  <div className="row">
                    <div className="col-lg-3">
                      <Skeleton varient="rectangular" width={200} height={200} />
                    </div>
                    <div className="col-lg-7">
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                      <Skeleton varient="rectangular" width={200} height={20} />
                    </div>
                    <div className="col-lg-2">
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                      <Skeleton varient="rectangular" width={"100%"} height={50} />
                    </div>
                  </div>
                </div></>
                : <>
                  {hospitalData.map((value, index) => {
                    if (index > 0 && (index + 1) % 5 == 0) {
                      return (
                        <>
                          <div className="hosbox">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="hosbox-img" onClick={()=>{Navigate('/hospital/'+value.hospital_slug)}}>
                                  <img src={value.hospital_image != null ? imageUrl + value.hospital_image : defaultImage} className="img-fluid" ></img>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="hosbox-content">
                                  {value.hospital_name != null ? <h2 className="hosbox-name"><a href={`/1/${value.hospital_slug}`}>{value.hospital_name}</a></h2> : ""}
                                  {value.hospital_city_name != null && value.hospital_country_name != null && value.hospital_city_name != "" && value.hospital_country_name != "" ?
                                    <p className="hosbox-loc"> {value.hospital_city_name}, {value.hospital_country_name} </p> : ""}
                                  <div className="hosbox-list-second mt-2">
                                    <ul>
                                      {value.hospital_type != null ?
                                        <li>{value.hospital_type == 1 ? "Single Speciality" : ""} {value.hospital_type == 2 ? "Multiple Speciality" : ""} {value.hospital_type == 3 ? "Super Speciality" : ""} </li> : ""}
                                      {value.hospital_bed != null ? <li>No. of Beds : {value.hospital_bed} </li> : ""}
                                    </ul>
                                  </div>
                                  {value.hospital_short_description != null ? <p className='short-des'> {value.hospital_short_description.substring(0, 150) }<a hreef="'/hospital/'+value.hospital_slug" >Read More...</a></p>: ""}
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <div className="haccreditation_image mb-5">
                                  {value.accreditation != null && value.accreditation != undefined && value.accreditation.map((subvalue) => (
                                    <img src={subvalue.accreditation_image != null ? imageUrl + subvalue.accreditation_image : defaultImage} className="img-fluid" style={{ width: "20%" }}></img>
                                  ))}
                                </div>
                                <div className="hosbox-content-footer" >
                                  {/* <a href={"/free-consultation/hospital/"+value.hospital_id} className="edu-btn01 btn-small btn-full" > Free Consultation</a>
                                  <a href={`/hospital/${value.hospital_slug}`} className="edu-btn btn-small btn-full">View Profile <i className="icon-4"></i></a> */}
                                    <a href={"/free-consultation/2/"+value.hospital_id} className="edu-btn btn-small btn-full" style={{ marginBottom: "4px" }}>Book Appointment <i className="icon-4"></i></a>
                                  <a href="https://wa.me/91" target="new" className="edu-btn03 btn-small btn-full">WhatsApp Us <i className="icon-4"></i></a>
                                </div>

                            
                              
                              </div>
                            </div>
                          </div>
                          <ListingShortcodeForm />
                        </>
                      )
                    }
                    else {
                      return (
                        <div className="hosbox">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="hosbox-img" onClick={()=>{Navigate('/hospital/'+value.hospital_slug)}}>
                                <img src={value.hospital_image != null ? imageUrl + value.hospital_image : defaultImage} ></img>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="hosbox-content">
                                {value.hospital_name != null}<h2 className="hosbox-name"><a href={`/hospital/${value.hospital_slug}`}>{value.hospital_name}</a></h2>
                                {value.hospital_city_name != null && value.hospital_country_name != null && value.hospital_city_name != "" && value.hospital_country_name != "" ?
                                  <p className="hosbox-loc">{value.hospital_city_name} , {value.hospital_country_name}  </p> : ""}
                                <div className="hosbox-list-second mt-2">
                                  <ul>
                                    {value.hospital_type != null ?
                                      <li>{value.hospital_type == 1 ? "Single Speciality" : ""} {value.hospital_type == 2 ? "Multiple Speciality" : ""} {value.hospital_type == 3 ? "Super Speciality" : ""} </li> : ""}
                                    {value.hospital_bed != null ? <li>No. of Bed: {value.hospital_bed}</li> : ""}
                                  </ul>
                                </div>
                                {value.hospital_short_description != null ? <p className='short-des'> {value.hospital_short_description.substring(0, 150) } <a href="'/hospital/'+value.hospital_slug" >Read More...</a></p>: ""}
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="hosboxlist-side">
                                <div className="haccreditation_image mb-5">
                                  {value.accreditation != null && value.accreditation != undefined && value.accreditation.map((subvalue) => (
                                    <img src={subvalue.accreditation_image != null ? imageUrl + subvalue.accreditation_image : defaultImage} className="img-fluid" style={{ width: "20%" }}></img>
                                  ))}
                                </div>
                                <div className="hosbox-content-footer" >
                                  <a href={"/free-consultation/1/"+value.hospital_id} className="edu-btn01 btn-small btn-full mb-2" > Free Consultation</a>
                                  <a href={`/hospital/${value.hospital_slug}`} className="edu-btn btn-small btn-full">View Profile <i className="icon-4"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })}</>}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
export default Hospitals