import React, { useEffect, useState, useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
let apiServices = new ApiService();
const slideroptions = {
    margin: 30,
    responsiveclass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 2,
        }
    },
};
function Home_Testimonials() {
    const [defaultImage, setDefaultImage] = useState('')
    const [testimonialData, setTestimonialData] = useState([])
    const [testimonialImageUrl, setTestimonialImageUrl] = useState('')
    const [loading, setLoading] = useState(true)
    const didMountRef = useRef(true)
    useEffect(() => {
        if (testimonialData.length == 0) {
            apiServices.testimonialsGetRequest().then(res => {
                if (res.data.status == "success") {
                    setTestimonialData(res.data.testimonials)
                    setTestimonialImageUrl(res.data.testimonial_img_url)
                    setDefaultImage(res.data.default_image_baseurl)
                    setLoading(false)
                }
            })
        }
    }, [])
    return (
        <div className='edu-section-gap  bg-lighten01'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-6'>
                        <div className="section-title section-center">
                            <span className="pre-title">Testimonials</span>
                            <h2 className="title">Our Satisfied Patient</h2>
                          
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {loading == true ? <>
                        <div className='col-lg-6'>
                            <div className='storibox'>
                                <div className='storibox-media'>
                                    <Skeleton varient="rectangular" width={200} height={150} />
                                </div>
                                <div className='storibox-content'>
                                    <Skeleton varient="rectangular" width={250} height={20} />
                                    <Skeleton varient="rectangular" width={150} height={20} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='storibox'>
                                <div className='storibox-media'>
                                    <Skeleton varient="rectangular" width={200} height={150} />
                                </div>
                                <div className='storibox-content'>
                                    <Skeleton varient="rectangular" width={250} height={20} />
                                    <Skeleton varient="rectangular" width={150} height={20} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='storibox'>
                                <div className='storibox-media'>
                                    <Skeleton varient="rectangular" width={200} height={150} />
                                </div>
                                <div className='storibox-content'>
                                    <Skeleton varient="rectangular" width={250} height={20} />
                                    <Skeleton varient="rectangular" width={150} height={20} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='storibox'>
                                <div className='storibox-media'>
                                    <Skeleton varient="rectangular" width={200} height={150} />
                                </div>
                                <div className='storibox-content'>
                                    <Skeleton varient="rectangular" width={250} height={20} />
                                    <Skeleton varient="rectangular" width={150} height={20} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='storibox'>
                                <div className='storibox-media'>
                                    <Skeleton varient="rectangular" width={200} height={150} />
                                </div>
                                <div className='storibox-content'>
                                    <Skeleton varient="rectangular" width={250} height={20} />
                                    <Skeleton varient="rectangular" width={150} height={20} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='storibox'>
                                <div className='storibox-media'>
                                    <Skeleton varient="rectangular" width={200} height={150} />
                                </div>
                                <div className='storibox-content'>
                                    <Skeleton varient="rectangular" width={250} height={20} />
                                    <Skeleton varient="rectangular" width={150} height={20} />
                                </div>
                            </div>
                        </div></> : <>
                        {testimonialData.map((value, index) => (
                            <div className='col-lg-6' key={index}>
                                <div className='storibox'>
                                    <div className='storibox-media'>
                                        <a href={`/patient-testimonials/${value.testimonial_url}`}><img src={value.testimonial_profile !== null ? testimonialImageUrl + value.testimonial_profile : defaultImage} className='img-fluid'></img></a>
                                    </div>
                                    <div className='storibox-content'>
                                        <h3><a href={`/patient-testimonials/${value.testimonial_url}`}>{value.testimonial_title}</a></h3>
                                        {value.testimonial_short_description ? <p>{value.testimonial_short_description}</p> : ""}
                                        <a href={`/patient-testimonials/${value.testimonial_url}`} className='storibox-read-more'> Read more</a>
                                    </div>
                                </div>
                            </div>))}</>
                    }
                </div>
                <div className='text-center'>
                    <a href="/patient-testimonials" className="edu-btn btn-large">View All<i className="icon-4"></i></a>
                </div>
                {/* {
testimonialData.map((value) => (
<div className='item'>
<div class="home-one-testimonial ">
<div class="testimonial-grid">
<div class="thumbnail">
<img src={value.testimonial_profile != null ?testimonialImageUrl + value.testimonial_profile:defaultImage} alt="Testimonial"/>
<span class="qoute-icon"><i class="icon-26"></i></span>
</div>
<div class="content" >
<span ></span>
<div class="rating-icon">
<i class="icon-23"></i>
<i class="icon-23"></i>
<i class="icon-23"></i>
<i class="icon-23"></i>
<i class="icon-23"></i>
</div>
<h5 class="title">{value.testimonial_name}</h5>
<span class="subtitle">{value.testimonial_destination}</span>
</div>
</div>
</div>
</div>))} */}
            </div>
        </div>
    )
}
export default Home_Testimonials;