import React, { useEffect, useState, useRef } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { useParams } from "react-router-dom";
let apiServices = new ApiService();
function DoctorFilter() {
  const { slug1 } = useParams();
  const [countryData, setCountryData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [hospitalData, setHospitalData] = useState([])
  const [ReqFilterData, setReqFilterData] = useState([]);
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      if (slug1 !== undefined || slug1 != null) {
        setReqFilterData(slug1.split('+'));
    
      }
      apiServices.doctorcountrylistGetRequest().then(res => {
        console.log("cate", res)
        if (res.data.length > 0) {
          setCountryData(res.data)
        }
      })
      apiServices.hospitalcategorylistGetRequest().then(res => {
        if (res.data.length > 0){
          setCategoryData(res.data)
        }
      })
      apiServices.doctorspecialitylistGetRequest().then(res => {
        console.log("spe", res)
        if (res.data.status === 'success') {
          setCategoryData(res.data.doctorsSpecialityList)
        }
      })
      apiServices.doctorhospitallistGetRequest().then(res => {
        if (res.data.status === 'success') {
          setHospitalData(res.data.doctorsHospitalList)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  const addfilter = (filterslug) => {
    window.location.href = '/doctors/' + filterslug;

    console.log("slug1", slug1);
    if (slug1 === undefined || slug1 == null) {
      window.location.href = '/doctors/' + filterslug;
    } else {
      const newArray = slug1.split('+');
      const index = newArray.indexOf(filterslug, 0);
      if (index !== -1) {
        newArray.splice(index, 1);
        var newslug = newArray.join('+');
        window.location.href = '/doctors/' + newslug;
      } else {
        var newslug = slug1 + '+' + filterslug;
        window.location.href = '/doctors/' + newslug;
      }
    }
    console.log(filterslug);
  }
  const countryaddfilter = (filterslug) => {
  console.log(filterslug)
  window.location.href = '/doctors/' + filterslug;
  }
  return (
    <>
    
      <div className="col-lg-3 siderbar-filter">
        <div className='filterbox mb-4'>
          <div className="filterbox-title">Country</div>
          <div className='filterbox-body filter-height-auto'>
            <div className='filter-list'>
              <ul>
                {countryData.map((value, index) => {
                  if (value.children != null && value.children.length > 0) {
                    return (
                      <li key={index}>
                        <div className="filter-parent">
                          <i className={ReqFilterData.includes(value.location_url_title) ? 'ri-checkbox-fill' : 'ri-checkbox-blank-line'} onClick={(e) => { countryaddfilter(value.location_url_title) }} id={"country" + value.location_id}></i> {value.location_name}

                          <div className="fpicon-right">
                            <a data-bs-toggle="collapse" href={'#collapseExample' + index} role="button" aria-expanded={ReqFilterData.includes(value.location_url_title) ? "true" : "false"} aria-controls="collapseExample" className={ReqFilterData.includes(value.location_url_title) ? "" : "collapsed"}>
                              <i className="ri-arrow-down-s-line"></i>
                            </a>
                          </div>
                        </div>
                        <div className={ReqFilterData.includes(value.location_url_title) ? "collapse filter-item-collapse show" : "collapse filter-item-collapse"} id={'collapseExample' + index}>
                          <ul>
                            {
                              value.children.map((key, index) => (
                                <li onClick={(e) => { countryaddfilter(key.location_url_title) }} ><i className={ReqFilterData.includes(key.location_url_title) ? 'ri-checkbox-fill mr-10' : 'ri-checkbox-blank-line mr-10'} ></i>{key.location_name}</li>
                              ))}
                          </ul>
                        </div>
                      </li>);
                  } else {
                    return (
                      <li key={index}>
                        <div className="filter-parent">
                          <i className={ReqFilterData.includes(value.location_url_title) ? 'ri-checkbox-fill' : 'ri-checkbox-blank-line'} onClick={(e) => { countryaddfilter(value.location_url_title) }}></i> {value.location_name}
                        </div>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
        {categoryData.length > 0 ?
          <div className='filterbox mb-4'>
            <div className="filterbox-title">Procedures</div>
            <div className='filterbox-body '>
              <div className='filter-list'>
                <ul>
                  {categoryData.map((value, index) => {
                    if (value.treatment != null && value.treatment.length > 0) {
                      return (
                        <li >
                          <div className="filter-parent">
                            <i className={ReqFilterData.includes(value.category_slug) ? 'ri-checkbox-fill' : 'ri-checkbox-blank-line'} onClick={(e) => { addfilter(value.category_slug) }} ></i> {value.category_name}
                            <div className="fpicon-right">
                              <a data-bs-toggle="collapse" href={'#collapseExampleCategory' + index} role="button" aria-expanded={ReqFilterData.includes(value.category_slug) ? "true" : "false"} aria-controls="collapseExample" className={ReqFilterData.includes(value.category_slug) ? "" : "collapsed"}>
                                <i className="ri-arrow-down-s-line"></i>
                              </a>
                            </div>
                          </div>
                          <div className={ReqFilterData.includes(value.category_slug) ? "collapse filter-item-collapse show" : "collapse filter-item-collapse"} id={'collapseExampleCategory' + index}>
                            <ul>
                              {
                                value.treatment.map((key, index) => (
                                  <li onClick={(e) => { addfilter(key.treatment_slug) }} ><i className={ReqFilterData.includes(key.treatment_slug) ? 'ri-checkbox-fill' : 'ri-checkbox-blank-line'} ></i>{key.treatment_name}</li>
                                ))}
                            </ul>
                          </div>
                        </li>)
                    }
                    else {
                      return (
                        <li key={index}>
                          <div className="filter-parent">
                            <i className={ReqFilterData.includes(value.category_slug) ? 'ri-checkbox-fill' : 'ri-checkbox-blank-line'} onClick={(e) => { addfilter(value.category_slug) }}></i> {value.category_name}
                          </div>
                        </li>
                      )
                    }
                  })}
                </ul>
              </div>
            </div>
          </div> : ""}
        {hospitalData.length > 0 ?
          <div className='filterbox mb-4'>
            <div className="filterbox-title">Hospitals</div>
            <div className='filterbox-body'>
              <div className='filter-list'>
                <ul>
                    {hospitalData.map((value) => (
                         <li>
                           <div className="filter-parent">
                            <i className={ReqFilterData.includes(value.hospital_slug) ? 'ri-checkbox-fill' : 'ri-checkbox-blank-line'} onClick={(e) => { addfilter(value.hospital_slug) }} id={"hospital" + value.hospital_id}></i> {value.hospital_name}
                    </div>
                    </li>
                    ))}
                  </ul>
              </div>
            </div>
          </div> : ""}
      </div>
    </>
  )
}
export default DoctorFilter


