import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
let apiServices = new ApiService();
function Home_Packages() {
    const [packages, setPackages] = useState([])
    const [packagesImageUrl, setPackagesImageUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    const [loading, setloading] = useState(true)
    const didMountRef = useRef(true)
    useEffect(() => {
        if (packages.length == 0) {

            apiServices.packagesGetRequest().then(res => {
                if (res.data.status == "success") {
                    setloading(false)
                    setPackages(res.data.packagesData)
                    setPackagesImageUrl(res.data.packages_url)
                    setDefaultImage(res.data.default_image_baseurl)
                }
                else {
                    setloading(false)
                }
            })
        }
    }, [])
    return (
        <div className="edu-course-area course-area-1 edu-section-gap bg-lighten01">
            <div className="container">
                <div className="section-title section-center" >
                    <span className="pre-title">Popular Packages</span>
                    <h2 className="title">Most Affordable Treatment Packages</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                </div>
                <Swiper
                    spaceBetween={15}
                    breakpoints={{
                        // when window width is >= 640px
                        340: {
                            width: 340,
                            slidesPerView: 2,
                        },
                        640: {
                            width: 640,
                            slidesPerView: 2,
                        },
                        // when window width is >= 768px
                        768: {
                            width: 768,
                            slidesPerView: 2,
                        },
                        1000: {
                            width: 1000,
                            slidesPerView: 4,
                        },
                        1200: {
                            width: 1200,
                            slidesPerView: 4,
                        },
                    }}
                    className="packageslider">
                    {loading == true ? <>
                        <SwiperSlide>
                            <Skeleton varient="rectangular" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Skeleton varient="rectangular" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Skeleton varient="rectangular" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Skeleton varient="rectangular" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Skeleton varient="rectangular" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                            <Skeleton varient="text" className="skeleton-bottom" />
                        </SwiperSlide>
                    </>
                        : <>
                            {
                                packages.map((value, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="edu-course course-style-1 hover-button-bg-white">
                                            <div className="inner">
                                                <div className="thumbnail">
                                                    <a href={`/packages/${value.packages_slug}`}>
                                                        <img src={value.packages_image != null ? packagesImageUrl + value.packages_image : defaultImage}
                                                            alt="Packages" />
                                                    </a>
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">
                                                        <a href={`/packages/${value.packages_slug}`}>{value.packages_name}</a>
                                                    </h6>
                                                    <div className="course-price">${value.packages_usd_start}-${value.packages_usd_upto}</div>
                                                    <a href={`/packages/${value.packages_slug}`} className="edu-btn btn-secondary btn-small">Inquiry <i className="icon-4"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}</>
                    }
                </Swiper>
                <div className="course-view-all" >
                    <a href="/packages" className="edu-btn">Browse More Packages <i className="icon-4"></i></a>
                </div>
            </div>
        </div>
    )
}
export default Home_Packages;
{/* <div className="col-md-6 col-xl-3" > */ }