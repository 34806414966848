import React, { useEffect, useState, useRef } from 'react';
import { ApiService } from "../../Components/Services/apiservices";
import { useParams,Link } from 'react-router-dom';
import moment from 'moment';
let apiServices = new ApiService();
function TreatmentDetailsSideBar() {
    const didMountRef = useRef(true)
    const {category, slug} = useParams()

    const [treatmentData, setTreatmentData] = useState([])
    const [defaultImage, setDefaultImage] = useState('')
    const [treatmentDetailUrl, setTreatmentDetailUrl] = useState('')
    const [bannerImage, setBannerImage] = useState('')
    const [tabDescription, setTabDescription] = useState([])
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [loading , setloading] = useState(true)
    const [treatmentListData , setTreatmentListData] = useState([])
    const [categoryName , setCategoryName] = useState('') 
    const [categryName , setCategryName] = useState('')
    const [relatedBlogData , setRelatedBlogsData] = useState([])
    const [relatedBlogImageUrl , setRelatedBlogImageUrl] = useState('')
    const[relatedDefaultImage , setRelatedBlogsDefaultImage] = useState('')
    const [categoryImageUrl , setCategoryImageUrl] = useState('')
    const [categoryDefaultImage , setCategoryDefaultImage] = useState('')
    useEffect(() => {
        if (didMountRef.current) {
            setSpinnerLoading(true)
            const dataString = {
                "treatment_slug": slug
            }
            apiServices.treatmentdetailsPostRequest(dataString).then(res => {
                if (res.data.status == "success") {
                    setTreatmentData(res.data.treatmentDetails)
                    setTabDescription(res.data.treatmentDetails.desc_tabs)
                    setDefaultImage(res.data.default_image_baseurl)
                    setTreatmentDetailUrl(res.data.treatment_url)
                    setBannerImage(res.data.treatmentDetails.treatment_image)
                    setSpinnerLoading(false)
                } else {
                    setSpinnerLoading(false)
                }
            })
            const dataString2 = {
                "category_slug": slug,
                "type": 2
              }
            apiServices.treatmentbycategoriesPostRequest(dataString2).then(res => {
                  if(res.data.status == "success"){
                  setTreatmentListData(res.data.treatmentData)
                  setCategoryName(res.data.category_name)
                  setCategryName(res.data.category.category_name)
                  setCategoryImageUrl(res.data.treatment_url)
                  setCategoryDefaultImage(res.data.default_image_baseurl)
                }
                  else{
                    setloading(false)
                  }
                   })
                   const dataString3 ={
                    "category_slug" : slug,
                    "type" : 2
                   }
                   apiServices.relatedblogsPostRequest(dataString3).then(res => {
                    if(res.data.status == "success"){
                        setRelatedBlogsData(res.data.blogsData)
                        setRelatedBlogImageUrl(res.data.blog_url)
                        setRelatedBlogsDefaultImage(res.data.default_image_baseurl)              
                    }
                })             
        }
        didMountRef.current = false;
    }, [])
    return (
        <>
{/* <div className='filterbox mb-4'>
    <div className="filterbox-title">{treatmentData.treatment_name}</div>
    <div className='filterbox-body filter-height-auto'>
    {
              tabDescription.map((value) => (
           <div className="latest-post">
           <div className="thumbnail">
             </div>
             <div className="post-content">
               <h6 className="title"><i className="icon-4"></i> <a href="">{value.tab_name}</a></h6>
               
               
               </div>
               </div>
                  ))}
         </div>
    </div> */}
    {tabDescription != null && tabDescription != "" ?
            <div className='filterbox mb-4'>
                <div className="filterbox-title">{treatmentData.treatment_name}</div>
                <div className='filterbox-body filter-height-auto'>
                  <div className='filter-list-second'>
                    <ul>
                    {tabDescription.map((value , index ) => (
                      <li key ={index}><a href={`/treatment/`+category+"/"+value.treatment_slug+`#`+ value.tab_id}><i className="icon-angle-right"></i>{value.tab_name}</a></li>
                      ))}
                      </ul>
                  </div>
                 
                </div>
            </div>:""}
    {treatmentListData.length > 0 ?
    <div className='filterbox mb-4'>
    <div className="filterbox-title">{treatmentData.treatment_name} Treatment</div>
    <div className='filterbox-body '>
       {treatmentListData.map((value) => (  
           <div className="latest-post">
           <div className="thumbnail">
             <a href={'/treatment/' + category+"/"+ value.treatment_slug}>
               <img src={value.treatment_image!=null ?categoryImageUrl+value.treatment_image :categoryDefaultImage}  alt="Category Image"></img>
               </a>
             </div>
             <div className="post-content">
               <h6 className="title"><a href={'/treatment/' + category+"/"+ value.treatment_slug}>{value.treatment_name}</a></h6>
               {/* <ul className="blog-meta"><li>Read more</li></ul> */}
               <a href={'/treatment/' + category+"/"+ value.treatment_slug} className='lp-readmore'> Read more</a>
               </div>
               </div>
          ))}</div>
      </div>:""}
    {relatedBlogData.length > 0 ? 
    <div className='filterbox mb-4'>
    <div className="filterbox-title">Related Articles</div>
    <div className='filterbox-body filter-height-auto '>
    {
      relatedBlogData.map((value) => ( 
    <div className="latest-post">
      <div className="thumbnail">
        <a href={`/blogs/${value.blog_slug}`}>
          <img src={value.blog_image!=null ?relatedBlogImageUrl+value.blog_image :relatedDefaultImage}  alt="Blog Images"></img>
          </a>
        </div>
        <div className="post-content">
          <h6 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h6>
          <ul className="blog-meta"><li><i className="icon-27"></i>{moment(value.created_at).format('DD-MM-YYYY')}</li></ul>
          </div>
          </div>))}
    </div>
    </div>:""}
        </>
    )
}
export default TreatmentDetailsSideBar;
