import React, { useEffect, useState, useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ApiService } from "../../Components/Services/apiservices";
import moment from 'moment';
import Skeleton from "react-loading-skeleton";
let apiServices = new ApiService();
const slideroptions = {
  margin: 5,
  responsiveclass: true,
  nav: false,
  dots: true,
  autoplay: false,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 2,
    }
  },
};
function Home_Articles() {
  const didMountRef = useRef(true)
  const [blogData, setBlogData] = useState([])
  const [blogImageUrl, setBlogImageUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const [loading, setloading] = useState(true)
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
      }
      apiServices.newsblogsUrlPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setBlogData(res.data.blogs)
          setBlogImageUrl(res.data.blog_url)
          setDefaultImage(res.data.default_image_baseurl)
          setloading(false)
        }
        else {
        }
      })
    }
    didMountRef.current = false;
  }, [])
  return (
    <>
      <div className="edu-blog-area blog-area-1 edu-section-gap">
        <div className="container">
          <div className="section-title section-center" >
            <span className="pre-title">Latest Articles</span>
            <h2 className="title">Get News with JD MAX Healthcare</h2>
            <span className="shape-line"><i className="icon-19"></i></span>
          </div>
          <div className="row g-5">
            {loading == true ? <>
              <div className='col-lg-4 col-md-6 col-12'>
                <Skeleton varient="rectangular" width={"100%"} height={250} />
                <Skeleton varient="text" width={150} height={20} />
                <Skeleton varient="text" width={300} height={20} />
                <Skeleton varient="text" width={300} height={20} />
                <Skeleton varient="text" width={300} height={20} />
                <Skeleton varient="text" width={300} height={20} />
              </div>
              <div className='col-lg-4 col-md-6 col-12'>
                <Skeleton varient="rectangular" width={"100%"} height={250} />
                <Skeleton varient="text" width={150} height={20} />
                <Skeleton varient="text" width={300} height={20} />
                <Skeleton varient="text" width={300} height={20} />
                <Skeleton varient="text" width={300} height={20} />
                <Skeleton varient="text" width={300} height={20} />
              </div>
              <div className='col-lg-4 col-md-6 col-12'>
                <Skeleton varient="rectangular" width={"100%"} height={250} />
                <Skeleton varient="text" width={150} height={20} />
                <Skeleton varient="text" width={300} height={20} />
                <Skeleton varient="text" width={300} height={20} />
                <Skeleton varient="text" width={300} height={20} />
                <Skeleton varient="text" width={300} height={20} />
              </div></>
              : <>
                {
                  blogData.map((value, index) => (
                    <div className="col-lg-4 col-md-6 col-12" key={index}>
                      <div className="edu-blog blog-style-1">
                        {loading === true ? <>
                          <div className='inner'>
                            <Skeleton varient="rectangular" width={"100%"} height={250} />
                            <Skeleton varient="text" width={150} height={20} />
                          </div></>
                          :
                          <div className="inner">
                            <div className="thumbnail list-thumb">
                              <a href={`/blogs/${value.blog_slug}`} >
                                <img src={value.blog_image != null ? blogImageUrl + value.blog_image : ""} alt="Blog Images" />
                              </a>
                            </div>
                            <div className="content position-top">
                              <div className="read-more-btn">
                                <a href={`/blogs/${value.blog_slug}`} className="btn-icon-round" ><i className="icon-4"></i></a>
                              </div>
                              <ul className="blog-meta">
                                <li><i className="icon-27"></i>{moment(value.created_at).format('DD-MM-YYYY')}</li>
                              </ul>
                              <h5 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name != null ? value.blog_name : ""}</a></h5>
                              <p className='short-des'>{value.blog_short_description != null ? value.blog_short_description : ""}</p>
                            </div>
                          </div>}
                      </div>
                    </div>
                  ))}</>
            }
            <div className="course-view-all">
              <a href="/blogs" className="edu-btn">View All<i className="icon-4"></i></a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Home_Articles;