import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
function CategoryDetails() {
  return (
    <>
<Header/>












<Footer/>
    </>
  )
}

export default CategoryDetails ;