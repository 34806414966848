import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
let apiServices = new ApiService();
function Home_Doctors() {
    const didMountRef = useRef(true)
    const [doctors, setDoctors] = useState([])
    const [doctorsImageUrl, setDoctorsImageUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    const [loading, setloading] = useState(true)
    const [citiesData, setCitiesData] = useState([])
    const [hospitalCity, setHospitalCity] = useState('Jaipur')
    useEffect(() => {
        if (doctors.length === 0) {
            apiServices.doctorsGetRequest().then(res => {
                if (res.data.status === "success") {
                    setDoctors(res.data.doctorData)
                    setDoctorsImageUrl(res.data.doctor_url)
                    setDefaultImage(res.data.default_image_baseurl)
                    setloading(false)
                }
                else {
                    setloading(false)
                }
            })
        }
        apiServices.hospitalscitiesGetRequest().then(res => {
            if (res.data.status === "success") {
                setCitiesData(res.data.cities)
            }
        })
    }, [])
    const citydata = (cityid) => {
        var arr = cityid.split(',');
      
        setHospitalCity(arr[1])
        const dataString = {
            "city_id": arr[0]
        }
        apiServices.citywisefeatureddoctorsPostRequest(dataString).then(res => {
            if (res.data.status === "success") {
                console.log(res.data.doctorData)
                setDoctors(res.data.doctorData)
                setDoctorsImageUrl(res.data.doctor_url)
                setDefaultImage(res.data.default_image_baseurl)
            }
        })
    }
    return (
        <>
           <div className="edu-team-area team-area-1 section-gap-equal">
                <div className="container">
                    <div className="section-title section-center">
                        <span className="pre-title">Doctors</span>
                        <h2 className="title">Popular Doctors in {hospitalCity}</h2>
                        <span className="shape-line"><i className="icon-19"></i></span>
                        <div className="homeselect">
                            <select onChange={(e) => citydata(e.target.value)}>
                                {citiesData.map((value, index) => (
                                    <option selected={value.location_name === "Jaipur" ? true : ""} value={value.location_id + ',' + value.location_name} key={index}>{value.location_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <Swiper
                        spaceBetween={15}
                        breakpoints={{
                            // when window width is >= 640px
                            340: {
                                width: 340,
                                slidesPerView: 2,
                            },
                            640: {
                                width: 640,
                                slidesPerView: 2,
                            },
                            // when window width is >= 768px
                            768: {
                                width: 768,
                                slidesPerView: 2,
                            },
                            1000: {
                                width: 1000,
                                slidesPerView: 5,
                            },
                            1200: {
                                width: 1200,
                                slidesPerView: 5,
                            },
                        }}
                        className="doctorslider">
                        {
                            loading == true ?
                                <>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Skeleton varient="rectangular" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                        <Skeleton varient="text" className="skeleton-bottom" />
                                    </SwiperSlide>
                                </>
                                : <>
                                    {
                                        doctors.map((value, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="edu-team-grid team-style-1">
                                                    <div className="inner">
                                                        <div className="thumbnail-wrap">
                                                            <div className="thumbnail">
                                                                <a href={`/doctor/${value.doctor_slug}`}>
                                                                    <img src={value.doctor_image != null ? doctorsImageUrl + value.doctor_image : defaultImage} alt="team images"></img>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="title-second"><a href={`/doctor/${value.doctor_slug}`}>{value.doctor_name}</a></h5>
                                                            <span className="designation">{value.doctor_speciality_name}</span>
                                                            <span className="designation">{value.doctor_experience} Years of Experience</span>
                                                            <span className="designation">{value.hospital.hospital_name} , {value.doctor_city_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }</>
                        }
                    </Swiper>
                    <div className="course-view-all">
                        <a href="/doctors" className="edu-btn">More Doctors<i className="icon-4"></i></a>
                    </div>
                </div>
            </div>
            <div className="edu-cta-banner-area home-one-cta-wrapper bg-image">
                <div className="container">
                    <div className="edu-cta-banner">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="section-title section-center" >
                    
                                    <h2 className="title">Free Consultation with best doctors in India</h2>
                                    <a href="/free-consultation" className="edu-btn">Get started now<i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                        <ul className="shape-group">
                            <li className="shape-01 scene">
                                <img data-depth="2.5" src="/assets/images/cta/shape-10.png" alt="shape" />
                            </li>
                            <li className="shape-02 scene">
                                <img data-depth="-2.5" src="/assets/images/cta/shape-09.png" alt="shape" />
                            </li>
                            <li className="shape-03 scene">
                                <img data-depth="-2" src="/assets/images/cta/shape-08.png" alt="shape" />
                            </li>
                            <li className="shape-04 scene">
                                <img data-depth="2" src="/assets/images/about/shape-13.png" alt="shape" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Home_Doctors;