import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Swiper, SwiperSlide } from 'swiper/react';
import TreatmentListSideBar from "../TreatmentListSideBar";
import { ApiService } from "../../Components/Services/apiservices";
import TreatmentShortcodeForm from '../TreatmentShortcodeForm';
import { Helmet } from "react-helmet";
let apiServices = new ApiService();
function Treatment() {
  const didMountRef = useRef(true)
  const [loading, setloading] = useState(true)
  const { slug } = useParams()
  const [categoryData, setCategoryData] = useState({})
  const [TreatmentImageUrl, setTreatmentImageUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const [bannerUrl, setBannerUrl] = useState('')
  const [bannerImage, setBannerImage] = useState('')
  const [categoryDesc, setCategoryDesc] = useState('')
  const [spinnerLoading, setSpinnerLoading] = useState()
  const [countriesData, setCountriesData] = useState([])
  const [featuredCountries, setFeaturedCountries] = useState([])
  const [nationalityData, setNationalityData] = useState([])
  const [treatmentHospitalData, setTreatmentHospitalData] = useState([])
  const [treatmentHospitalImageUrl, setTreatmentHospitalImageUrl] = useState('')
  const [treatmentDoctorData, setTreatmentDoctorData] = useState([])
  const [treatmentDoctorImageUrl, setTreatmentDoctorImageUrl] = useState('')
  const [videoData, setVideoData] = useState([])
  const [faqData , setFaqData] = useState([])
  const [testimonialData , setTestimonialData] = useState([])
  const [testimonialImageUrl , setTestimonialImageUrl] = useState([])
  const [treatmentData , setTreatmentData] = useState({})
  const [treatmentListData , setTreatmentListData] = useState([])
  const [categoryImageUrl, setCategoryImageUrl] = useState('')
  const [categoryDefaultImage, setCategoryDefaultImage] = useState('')
  useEffect(() => {
    if (didMountRef.current) {
      setSpinnerLoading(true)
      const dataString = {
        "category_slug": slug
      }
      apiServices.hospitalbycategoriesPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setCategoryData(res.data.category)
          setTreatmentImageUrl(res.data.hospital_url)
          setDefaultImage(res.data.default_image_baseurl)
          setBannerUrl(res.data.category_url)
          setBannerImage(res.data.category.category_banner)
          setTreatmentData(res.data.category)
          setCategoryDesc(res.data.category.category_desc)
          setFaqData(res.data.faqData)
          setTestimonialData(res.data.testimonials)
          setTestimonialImageUrl(res.data.testimonial_img_url)
          setloading(false)
          setSpinnerLoading(false)
        }
        else {
          setloading(false)
          setSpinnerLoading(false)
        }
      })
      const dataString3 = {
        "category_slug": slug,
        "type": 1
      }
      apiServices.treatmentbycategoriesPostRequest(dataString3).then(res => {
        if (res.data.status == "success") {
          setTreatmentListData(res.data.treatmentData)
          // setCategoryName(res.data.category_name)
          // setCategryName(res.data.category.category_name)
          setCategoryImageUrl(res.data.treatment_url)
          setCategoryDefaultImage(res.data.default_image_baseurl)
        }
        else {
          setloading(false)
        }
      })
      apiServices.countrieslistGetRequest().then(res => {
        console.log(res)
        if (res.data.countries.length > 0) {
          setCountriesData(res.data.countries)
          setFeaturedCountries(res.data.featuredcountries)
        }
      })
      apiServices.nationalitylistGetRequest().then(res => {
        console.log(res)
        if (res.data.nationality.length > 0) {
          setNationalityData(res.data.nationality)
        }
      })
      apiServices.relatedcategoryhospitalsPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setTreatmentHospitalData(res.data.hospitalData)
          setTreatmentHospitalImageUrl(res.data.hospital_url)
          setDefaultImage(res.data.default_image_baseurl)
        }
      })
      apiServices.relatedcategorydoctorsPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setTreatmentDoctorData(res.data.doctorData)
          setDefaultImage(res.data.default_image_baseurl)
          setTreatmentDoctorImageUrl(res.data.doctor_url)
        }
      })
      apiServices.relatedcategoryvideosPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setVideoData(res.data.videoData)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  return (
    <>
     <Helmet>
        <title>{treatmentData.category_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            treatmentData.category_meta_desc != null
              ? treatmentData.category_meta_desc
              : "JDMax Healthcare"
          }
        />
        {treatmentData.category_meta_keyword != null ? (
          <meta
            name="keywords"
            content={treatmentData.category_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}
       
        <meta
          property="og:title"
          content={treatmentData.category_meta_title}
        />
      
      <meta name="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />
<link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            treatmentData.category_meta_desc != null
              ? treatmentData.category_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={treatmentData.category_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            treatmentData.category_meta_desc != null
              ? treatmentData.category_meta_desc
              : "JDMax Healthcare"
          }
        />
       
      </Helmet>
      <Header />
      {spinnerLoading === false ? <>
        <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
          <div className="container">
            <div className="row">
              <div className='col-lg-12'>
                <h2>{categoryData.category_name}</h2>
                <div className="breadcrumb-inner">
                  <ul className="edu-breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a></li>
                    <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item">
                      Treatments
                    </li>
                    <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item">
                      {categoryData.category_name}
                    </li>
                  </ul>
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-equal-small">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-8">
                {bannerImage != null ?
                  <img src={bannerUrl + bannerImage} className="img-fluid mb-5" /> : ""}
                {categoryDesc != null ?
                  <div className='treatment-content' dangerouslySetInnerHTML={{ __html: categoryDesc }}>
                  </div> : ""}
                  <div className="edu-team-area team-area-1 edu-section-gap">
            <div className="container">
                <div className="section-title section-center">
                    <h2 className="title">Treatments</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                 
                </div>
              <div className='row'>
                  
                              {treatmentListData.map((value, index) => (
                                   <div className='col-lg-4'>
                                        <a href={`/treatment/${value.treatment_slug}`} className="catbox-item">
                                            <div className="catbox-item-img">
                                                <img src={value.treatment_image != null ? categoryImageUrl + value.treatment_image : defaultImage} className="img-fluid" alt="Speciality" />
                                            </div>
                                            <h6><a href={`/treatment/${value.treatment_slug}`}>{value.treatment_name}</a></h6>
                                        </a>
                                 </div>
                                ))}
                           </div>
                 

               
            </div>
        </div>
              </div>
              <div className="col-lg-4 siderbar-filter">
                <TreatmentListSideBar />
              </div>
            </div>
          </div>
        </section>
        <TreatmentShortcodeForm />
        {treatmentHospitalData.length > 0 ?
          <div className="edu-team-area team-area-1 edu-section-gap bg-lighten01" style={{ marginBottom: "5px" }} >
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Hospitals</span>
                <h2 className="title">{categoryData.category_name} Hospitals in India</h2>
                <p dangerouslySetInnerHTML={{__html:categoryData.category_hospital_desc}}></p>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 5,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5,
                  },
                }}
                className="hospitalslider">
                {/* {loading == true ?<>
    <SwiperSlide>
    <Skeleton varient="rectangular"  />
    <Skeleton varient="text" className="skeleton-bottom"  />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular"  />
    <Skeleton varient="text" className="skeleton-bottom"  />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular" />
    <Skeleton varient="text" className="skeleton-bottom"  />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular" />
    <Skeleton varient="text" className="skeleton-bottom" />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular" />
    <Skeleton varient="text" className="skeleton-bottom" />
    </SwiperSlide>
    </> */}
                {treatmentHospitalData.map((value, index) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1" key={index} >
                      <div className="inner">
                        <div className="thumbnail-wrap">
                          <div className="thumbnail">
                            <a href={`/hospital/${value.hospital_slug}`}>
                              <img src={value.hospital_image != null ? treatmentHospitalImageUrl + value.hospital_image : defaultImage} alt="team images"></img>
                            </a>
                          </div>
                        </div>
                        <div className="content">
                          <h6 className="title"><a href={`/hospital/${value.hospital_slug}`}>{value.hospital_name}</a></h6>
                          {value.hospital_city_name !== "" && value.hospital_country_name !== "" ?
                            <p>{value.hospital_city_name} , {value.hospital_country_name}</p> : ""}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div> : ""}
        {treatmentDoctorData.length > 0 ?
          <div className="edu-team-area team-area-1 section-gap-equal">
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Doctors</span>
                <h2 className="title">Popular Doctors</h2>
                <p dangerouslySetInnerHTML={{__html:categoryData.category_doctor_desc}}></p>
                <span className="shape-line"><i className="icon-19"></i></span>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 5,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5,
                  },
                }}
                className="doctorslider">
                {treatmentDoctorData.map((value) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                      <div className="inner">
                        <div className="thumbnail-wrap">
                          <div className="thumbnail">
                            <a href={`/doctor/${value.doctor_slug}`}>
                              <img src={value.doctor_image != null ? treatmentDoctorImageUrl + value.doctor_image : defaultImage} ></img>
                            </a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title-second"><a href={`/doctor/${value.doctor_slug}`}>{value.doctor_name} </a></h5>
                          <span className="designation">{value.doctor_designation}</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div> : ""}
          {faqData.length > 0 ?
        <div class="edu-team-area team-area-1 section-gap-equal bg-lighten01">
            <div class="container">
            <div className='row justify-content-center'>
                    <div className='col-lg-6'>
                        <div className="section-title section-center">
                            <span className="pre-title">FAQs</span>
                            <h2 className="title">Frequently Asked Questions</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
        
    <div class="col-lg-12" >
        <div class="tab-content faq-page-tab-content" >
            <div class="tab-pane fade show active" role="tabpanel">
                <div class="faq-accordion">
                   {faqData.map((value, index) => (
                    <div class="accordion-item">
                                            <h5 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#question-${index}`} aria-expanded="false">
                                                {value.faq_title}
                                                </button>
                                            </h5>
                                            <div id={`question-${index}`}class="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                                                <div class="accordion-body">
                                                    <p dangerouslySetInnerHTML={{__html:value.faq_description}}></p>
                                                </div>
                                            </div>
                                        </div>))}
                </div>
            </div>
        </div>
    </div>


                </div>
            </div>
        </div>:""}
          {testimonialData.length > 0 ?
          <div className='edu-team-area team-area-1 section-gap-equal'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-6'>
                        <div className="section-title section-center">
                            <span className="pre-title">Testimonials</span>
                            <h2 className="title">Our Satisfied Patient</h2>
                        </div>
                    </div>
                </div>
                <div className='row'>
                   
                        {testimonialData.map((value, index) => (
                            <div className='col-lg-6' key={index}>
                                <div className='storibox'>
                                    <div className='storibox-media'>
                                        <a href={`/patient-testimonials/${value.testimonial_url}`}><img src={value.testimonial_profile !== null ? testimonialImageUrl + value.testimonial_profile : defaultImage} className='img-fluid'></img></a>
                                    </div>
                                    <div className='storibox-content'>
                                        <h3><a href={`/patient-testimonials/${value.testimonial_url}`}>{value.testimonial_title}</a></h3>
                                        {value.testimonial_short_description ? <p>{value.testimonial_short_description}</p> : ""}
                                        <a href={`/patient-testimonials/${value.testimonial_url}`} className='storibox-read-more'> Read more</a>
                                    </div>
                                </div>
                            </div>))}
                    
                </div>
              
                
            </div>
        </div>:""}
     
        {videoData.length > 0 ?
          <div className="edu-team-area team-area-1 section-gap-equal bg-lighten01" style={{ marginBottom: "5px" }}>
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Videos</span>
                <h2 className="title">Top Videos</h2>
                <span className="shape-line"><i className="icon-19"></i></span>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 4,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 4,
                  },
                }}
                className="doctorslider">
                {videoData.map((value) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                      <div className="inner">
                        <div className="">
                          <div className="">
                            <iframe height="300" width="100%" src={value.video_link != null ? "https://www.youtube.com/embed/" + value.video_link + "?autoplay=1" : ""}></iframe>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title-second">{value.video_name}</h5>
                          <span className="designation">{value.doctor_designation}</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>))}
              </Swiper>
            </div>
          </div> : ""}
        
        <Footer />
      </>
        : <div className="loder">
          <img src="/assets/images/loading-gif.webp"></img>
        </div>}
    </>
  )
}
export default Treatment