import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService()
function TestimonialsDetailsSideBar(props) {
  const didMountRef = useRef(true)
  const { type } = useParams()
  const navigate = useNavigate()
  const [testimonialcategory, setTestimonialCategory] = useState([])
  const [successMessage, setSuccessmessage] = useState("")
  const [allData, setAllData] = useState({ treatmentname: "", patientname: "", age: "", gender: "", nationality: "", email: "", countrycode: "", phone: "", medicalconcerns: "" })
  const [featuredCountries, setFeaturedCountries] = useState([])
  const [countriesData, setCountriesData] = useState([])
  const [image, setImage] = useState({ preview: "", raw: "" })
  const [nationalityData, setNationalityData] = useState([])
  const imageMimeType = /(jpg|jpeg|doc|docx|pdf|png)/i;
  useEffect(() => {
    if (didMountRef.current) {
      apiServices.doctorspecialitylistGetRequest().then(res => {
        if (res.data.status === "success") {
          setTestimonialCategory(res.data.doctorsSpecialityList)
        }
      })
      apiServices.countrieslistGetRequest().then(res => {
        console.log(res)
        if (res.data.countries.length > 0) {
          setCountriesData(res.data.countries)
          setFeaturedCountries(res.data.featuredcountries)
        }
      })
      apiServices.nationalitylistGetRequest().then(res => {
        console.log(res)
        if (res.data.nationality.length > 0) {
          setNationalityData(res.data.nationality)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  const handlechangedata = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setAllData({ ...allData, [key]: value })
  }
  const handleImage = e => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Invalid Format, supported formats are | jpeg | jpg | doc | docx | pdf | png.");
      return;
    }
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };
  const getfreequotation = () => {
    console.log(allData)
    if (allData.treatmentname === "") {
      document.getElementById("treatmentname").style.border = "1px solid black"
      document.getElementById("treatmentname").focus()
      return false
    }
    if (allData.patientname === "") {
      document.getElementById("patientname").style.border = "1px solid black"
      document.getElementById("patientname").focus()
      return false
    }
    else if (allData.age === "") {
      document.getElementById("age").style.border = "1px solid black"
      document.getElementById("age").focus()
      return false
    }
    else if (allData.gender === "" || allData.gender == undefined) {
      document.getElementById("gender").style.border = "1px solid black"
      document.getElementById("gender").focus()
      return false
    }
    else if (allData.nationality === "") {
      document.getElementById("nationality").style.border = "1px solid black"
      document.getElementById("nationality").focus()
      return false
    }
    else if (allData.email === "") {
      document.getElementById("emailquote").style.border = "1px solid black"
      document.getElementById("emailquote").focus()
      return false
    }
    else if (allData.countrycode === "") {
      document.getElementById("countrycode").style.border = "1px solid black"
      document.getElementById("countrycode").focus()
      return false
    }
    else if (allData.phone === "") {
      document.getElementById("phone").style.border = "1px solid black"
      document.getElementById("phone").focus()
      return false
    }
    else if (allData.medicalconcerns === "") {
      document.getElementById("medicalconcerns").style.border = "1px solid black"
      document.getElementById("medicalconcerns")
      return false.focus()
    }
    const formData = new FormData()
    formData.append('page_type', "testimonial");
    formData.append('treatment_name', allData.treatmentname);
    formData.append('patient_name', allData.patientname)
    formData.append('age', allData.age)
    formData.append('gender', allData.gender)
    formData.append('nationality', allData.nationality)
    formData.append('email', allData.email)
    formData.append('country_code', allData.countrycode)
    formData.append('phone', allData.phone)
    formData.append('medical_concerns', allData.medicalconcerns)
    formData.append('medical_record_image', image.raw)
    apiServices.quotationprocessPostRequest(formData).then(res => {
      if (res.data.status == "success") {
        setSuccessmessage(res.data.message)
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      }
    })
  }
  return (
    <>
      <div className='filterbox mb-4'>
        {/* <div className="filterbox-title">Categories</div>
        <div className='filterbox-body '>
          <div className='content'>
            <ul className="category-list">
              {testimonialcategory.map((value) => (
                <>
                  <li><a href={`/patient-testimonials/category/` + value.category_slug}> {props.data == value.category_name ? <b>{value.category_name}</b> : ""}{props.data !== value.category_name ? value.category_name : ""} </a></li>
                </>
              ))}
            </ul>
          </div>
        </div> */}
<div className='sideform'>
<h2>Get Free Consultation</h2>
                  <div className='row'>
                    {successMessage != "" ?
                      <div class="alert alert-info">
                        <strong>{successMessage}</strong>
                      </div> : ""}
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Treatment Name <span >*</span></label>
                        <input type="text" name="treatmentname" placeholder="Treatment" id='treatmentname' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Patient Full Name <span >*</span></label>
                        <input type="text" name="patientname" placeholder="Patient" id='patientname' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Age <span >*</span></label>
                        <input type="number" name="age" placeholder="Age" id='age' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Gender <span >*</span></label>
                        <select name="gender" id='gender' onChange={handlechangedata}>
                          <option value="">Select</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Nationality (Passport)<span >*</span></label>
                        <select name="nationality" id='nationality' onChange={handlechangedata}>
                          <option >Select</option>
                          {nationalityData.length > 0 && nationalityData.map((value, index) => (
                            <option key={index} value={value.nationality_id}>{value.nationality_name}</option>))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Email <span >*</span></label>
                        <input type="email" name="email" placeholder="Email" id='emailquote' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Country Code <span >*</span></label>
                        <select name="countrycode" id='countrycode' onChange={handlechangedata}>
                          <option >Select</option>
                          <option disabled style={{ fontWeight: "bold" }}>Popular Countries</option>
                          {featuredCountries.length > 0 && featuredCountries.map((value, index) => (
                            <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                          ))}
                          <option disabled style={{ fontWeight: "bold" }}>All Countries</option>
                          {countriesData.length > 0 && countriesData.map((value, index) => (
                            <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='sideform-group mb-3'>
                        <label>Phone <span >*</span></label>
                        <input type="number" name="phone" placeholder="Phone Number" id='phone' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Medical Concerns/Questions <span >*</span></label>
                        <textarea type="text" name="medicalconcerns" placeholder="Medical Concerns/Questions" id='medicalconcerns' onChange={handlechangedata} />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='sideform-group mb-3'>
                        <label>Upload Medical Records (jpeg, jpg, doc, docx, pdf, png)</label>
                        <div className="custom-file">
                          <input type="file" className="custom-file-input" id="customFile" onChange={handleImage} />
                          <label className="custom-file-label" for="customFile">{image.raw.name != "" ? image.raw.name : "Choose file"}</label>
                        </div>
                      </div>
                    </div>
                    <div className='sideform-group'>
                      <button className='edu-btn02 btn-small btn-full mt-3' type='button' onClick={getfreequotation}>Get Free Quotation</button>
                    </div>
                  </div>
      </div>

      </div>
    </>
  )
}
export default TestimonialsDetailsSideBar