import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import moment from 'moment';
import { ApiService } from "../../Components/Services/apiservices";
import { Helmet } from "react-helmet";

let apiServices = new ApiService();
function Blogs() {
    const { type } = useParams()
    const { slug } = useParams()
    const didMountRef = useRef(true)
    const [blogData, setBlogData] = useState([])
    const [categoryData, setCategoryData] = useState('')
    const [blogImageUrl, setBlogImageUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    const [latestBlogData, setLatestBlogData] = useState([])
    const [latestCategoryData, setLatestCategoryData] = useState([])
    const [latestBlogImageUrl, setLatestBlogImageUrl] = useState('')
    const [blogListDefaultImage, setBlogListDefaultImage] = useState('')
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [pageData ,setPageData] = useState({})
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            setSpinnerLoading(true)
            pagesData()
            if (type == "category") {
                categorywisebloglist(slug)
            }
            else {
                apiServices.blogsdataGetRequest().then(res => {
                    if (res.data.status == "success") {
                        setBlogData(res.data.blogsData)
                        setBlogImageUrl(res.data.blog_url)
                        setDefaultImage(res.data.default_image_baseurl)
                    }
                })
            }
            apiServices.bloglistsidebardataGetRequest().then(res => {
                if (res.data.status == "success") {
                    setLatestCategoryData(res.data.blogCategories)
                    setLatestBlogData(res.data.latestblogdata)
                    setLatestBlogImageUrl(res.data.blog_url)
                    setBlogListDefaultImage(res.data.default_image_baseurl)
                    setSpinnerLoading(false)
                }
                else {
                    setSpinnerLoading(false)
                }
            })
        }
        didMountRef.current = false;
    }, [])
    const categorywisebloglist = (category_slug) => {
        const dataString = {
            "category_slug": category_slug
        }
        apiServices.categorywisebloglistPostRequest(dataString).then(res => {
            if (res.data.status === "success") {
                setBlogData(res.data.blogsData)
                setCategoryData(res.data.categoryData.category_name)
                setBlogImageUrl(res.data.blog_url)
                setDefaultImage(res.data.default_image_baseurl)
            }
        })
    }
    const pagesData=()=>{
        const dataString={
          "page_url":"blogs"
        }
        apiServices.PagesDataPostRequest(dataString).then(res=>{
    if(res.data.status == "success"){
      setPageData(res.data.pagesData)
    }
        })
      }
    return (
        <>
         <Helmet>
        <title>{pageData.page_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />
        {pageData.page_meta_keyword != null ? (
          <meta
            name="keywords"
            content={pageData.page_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}
       
        <meta
          property="og:title"
          content={pageData.page_meta_title}
        />
      
      <meta name="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />
<link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={pageData.page_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />
       
      </Helmet>
            <Header />
              {spinnerLoading === false ?
                <>
                    <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
                        <div className="container">
                            <div className="row">
                                <div className='col-lg-12'>
                                    <h1>News & Blogs</h1>
                                    <div className="breadcrumb-inner">
                                        <ul className="edu-breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="separator"><i className="icon-angle-right"></i></li>
                                            <li className="breadcrumb-item"><a href="/blogs">News & Blogs</a></li>
                                              <li className="separator"><i className="icon-angle-right"></i></li>
                                            <li className="breadcrumb-item active" aria-current="page">{categoryData}</li> 
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="main-wrapper" className="main-wrapper">
                        <section className="section-gap-equal">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-8">
                                        {
                                            blogData.map((value) => (
                                                <div className="edu-blog blog-style-list" >
                                                    <div className="inner">
                                                        <div className="thumbnail">
                                                            <a href={`/blogs/${value.blog_slug}`}>
                                                                <img src={value.blog_image != null && value.blog_image != "" ? blogImageUrl + value.blog_image : defaultImage} alt="Blog Images" />
                                                            </a>
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h5>
                                                            <ul className="blog-meta">
                                                                <li><i className="icon-27"></i>{moment(value.created_at).format('DD-MM-YYYY')}</li>
                                                            </ul>
                                                            <p>{value.blog_short_description != null ? value.blog_short_description : ""}</p>
                                                            <div className="read-more-btn">
                                                                <a className="edu-btn btn-border btn-medium" href={`/blogs/${value.blog_slug}`}>Read More <i className="icon-4"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>))}
                                        </div>
                                    <div className="col-lg-4 siderbar-filter">
                                        <div className='filterbox mb-4'>
                                            <div className="filterbox-title">Latest Post</div>
                                            <div className='filterbox-body'>
                                                {
                                                    latestBlogData.map((value) => (
                                                        <div className="latest-post">
                                                            <div className="thumbnail">
                                                                <a href={`/blogs/${value.blog_slug}`}>
                                                                    <img src={value.blog_image != null ? latestBlogImageUrl + value.blog_image : blogListDefaultImage} alt="Blog Images"></img>
                                                                </a>
                                                            </div>
                                                            <div className="post-content">
                                                                <h6 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h6>
                                                                <ul className="blog-meta"><li><i className="icon-27"></i>{moment(value.created_at).format('DD-MM-YYYY')}</li></ul>
                                                            </div>
                                                        </div>))}
                                                  </div>
                                            </div>
                                        <div className='filterbox mb-4'>
                                            <div className="filterbox-title">Categories</div>
                                            <div className='filterbox-body'>
                                                <div className="content">
                                                    <ul className="category-list">
                                                        {
                                                            latestCategoryData.map((value) => (
                                                                <li onClick={(e) => categorywisebloglist(value.category_slug)}><a href={`/blogs/category/` + value.category_slug}>{value.category_name} {value.blog_count != 0 ? <span>({value.blog_count})</span> : ""}</a></li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>                
                    <Footer />
                </>
                : <div className="loder">
                    <img src="/assets/images/loading-gif.webp"></img>
                </div>}
        </>)
}

export default Blogs
