import React, { useEffect, useRef, useState } from 'react'
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiservices";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
let apiServices = new ApiService();
function Pages() {
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [page, setPage] = useState('');
  const [pageContent, setPageContent] = useState('');
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "page_url": slug
      }
      apiServices.pagesPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          {
            if (res.data.pageData != null)
              setPage(res.data.pageData)
          }
          setPageContent(res.data.pageData.page_content)
        }
      })
    }
    didMountRef.current = false;
  });
  return (
    <>
      <Helmet>
        <title>{page.page_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            page.page_meta_desc != null
              ? page.page_meta_desc
              : "JDMax Healthcare"
          }
        />
        {page.page_meta_keyword != null ? (
          <meta
            name="keywords"
            content={page.page_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}
       
        <meta
          property="og:title"
          content={page.page_meta_title}
        />
      
      <meta name="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />
<link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            page.page_meta_desc != null
              ? page.page_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={page.page_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            page.page_meta_desc != null
              ? page.page_meta_desc
              : "JDMax Healthcare"
          }
        />
       
      </Helmet>
      <Header />
      <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
        <div className="container">
          <div className="row">
            <div className='col-lg-12'>
              <h1>{page.page_name}</h1>
              <div className="breadcrumb-inner">
                <ul className="edu-breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="separator"><i className="icon-angle-right"></i></li>
                  <li className="breadcrumb-item">Subtreatment</li>
                  {/* <li className="separator"><i className="icon-angle-right"></i></li>
<li className="breadcrumb-item active" aria-current="page">Blog 1</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
      <Footer />
    </>
  )
}
export default Pages