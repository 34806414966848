import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function RegisterPage() {
  const [saveAllData, setSaveAllData] = useState({ regfullname: '', logemail: '', lognumber: '', logpassword: '', countrycode:'101' })
  const [errorMessage, setErrorMessage] = useState("")
  const [countryData , setCountryData] = useState([])
  const navigate = useNavigate()
const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {

apiServices.countrydataGetRequest().then(res=>{
if(res.data.status == "success"){
  setCountryData(res.data.data)
}
})
    }
    didMountRef.current = false;
}, [])
  const handlechangedata = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setSaveAllData({ ...saveAllData, [key]: value })
  }
  const register = () => {
    if (saveAllData.regfullname == "") {
      document.getElementById("reg-first-name").style.border = "1px solid red"
    }
    else if (saveAllData.lognumber == "") {
      document.getElementById("log-number").style.border = "1px solid red"
    }
    else if (saveAllData.logemail == "") {
      document.getElementById("log-email").style.border = "1px solid red"
    }
    else if (saveAllData.logpassword == "") {
      document.getElementById("log-password").style.border = "1px solid red"
    }

    console.log(saveAllData)
    const dataString = {
      "user_first_name": saveAllData.regfullname,
      "user_email": saveAllData.logemail,
      "user_contact": saveAllData.lognumber,
      "user_password": saveAllData.logpassword,
      "user_country": saveAllData.countrycode
    }
    apiServices.userregisterprocessPostRequest(dataString).then(res => {
      if (res.data.status == "success") {
        localStorage.setItem('USER_SESSION', JSON.stringify(res.data.data))
        navigate("/")
      }
    })
  }
  return (
    <>
      <Header />
      <div id="main-wrapper" className="main-wrapper">
        <section className="account-page-area section-gap-equal">
          <div className="container position-relative">
            <div className="row g-5 justify-content-center">
              <div className="col-lg-5">
                <div className="login-form-box registration-form">
                  <h3 className="title">Registration</h3>
                  <p>Already have an account? <a href="/login">Sign in</a></p>
                  <form>
                    <div className="form-group">
                      <label for="reg-name">Full Name*</label>
                      <input type="text" name="regfullname" id="reg-first-name" placeholder="Full name" onChange={handlechangedata} />
                    </div>
                    <div className="form-group">
                      <label for="log-number">Mobile Number*</label>
                    <div className="row">
                      <div className="col-lg-2">
                         <select name="countrycode" id="reg-country-code" onChange={handlechangedata} >
                          {countryData.map((value)=>(
                          <option value={value.country_id} selected={value.country_id == "101" ?true :""}>+{value.country_phonecode}</option>))}
                          </select>
                      </div>
                      <div className="col-lg-10">
                       <input type="number" name="lognumber" id="log-number" placeholder="Mobile Number" onChange={handlechangedata} />
                      </div>
                    </div>
                     
                    </div>
                    <div className="form-group">
                      <label for="log-email">Email Address</label>
                      <input type="email" name="logemail" id="log-email" placeholder="Email Address" onChange={handlechangedata} />
                    </div>
                    <div className="form-group">
                      <label for="log-password">Password*</label>
                      <input type="password" name="logpassword" id="log-password" placeholder="Password" onChange={handlechangedata} />
                    </div>
                  
                    {
                      errorMessage != '' ? <p className="text text-danger">{errorMessage}</p> : null
                    }
                    <div className="form-group">
                      <button type="button" class="edu-btn btn-medium" onClick={register}>Create Account <i class="icon-4"></i></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <ul className="shape-group">
              <li className="shape-1 scene"><img data-depth="2" src="assets/images/about/shape-07.png" alt="Shape" /></li>
              <li className="shape-2 scene"><img data-depth="-2" src="assets/images/about/shape-13.png" alt="Shape" /></li>
              <li className="shape-3 scene"><img data-depth="2" src="assets/images/counterup/shape-02.png" alt="Shape" /></li>
            </ul>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}
export default RegisterPage;