import React, { useEffect, useState, useRef } from 'react';
import { ApiService } from "../../Components/Services/apiservices";
import { useParams } from 'react-router-dom';
import moment from 'moment';
let apiServices = new ApiService();
function TreatmentListSideBar() {
  const didMountRef = useRef(true)
  const [loading, setloading] = useState(true)
  const [treatmentListData, setTreatmentListData] = useState([])
  const [categoryName, setCategoryName] = useState('')
  const [categryName, setCategryName] = useState('')
  const [relatedBlogData, setRelatedBlogsData] = useState([])
  const [relatedBlogImageUrl, setRelatedBlogImageUrl] = useState('')
  const [relatedDefaultImage, setRelatedBlogsDefaultImage] = useState('')
  const [categoryImageUrl, setCategoryImageUrl] = useState('')
  const [categoryDefaultImage, setCategoryDefaultImage] = useState('')
  const { slug } = useParams()
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "category_slug": slug,
        "type": 1
      }
      apiServices.treatmentbycategoriesPostRequest(dataString).then(res => {
        if (res.data.status == "success") {
          setTreatmentListData(res.data.treatmentData)
          setCategoryName(res.data.category_name)
          setCategryName(res.data.category.category_name)
          setCategoryImageUrl(res.data.treatment_url)
          setCategoryDefaultImage(res.data.default_image_baseurl)
        }
        else {
          setloading(false)
        }
      })
      const dataString2 = {
        "category_slug": slug,
        "type": 1
      }
      apiServices.relatedblogsPostRequest(dataString2).then(res => {
        if (res.data.status == "success") {
          setRelatedBlogsData(res.data.blogsData)
          setRelatedBlogImageUrl(res.data.blog_url)
          setRelatedBlogsDefaultImage(res.data.default_image_baseurl)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  // const treatment = (order_id, slug) => {
  //   if (document.getElementById("treatment" + order_id).classList.contains('ri-checkbox-fill')) {
  //       document.getElementById("treatment" + order_id).className = 'ri-checkbox-blank-line';
  //   } else {
  //       document.getElementById("treatment" + order_id).className = 'ri-checkbox-fill';
  //   }
  // window.location.href = '/treatments' + slug;
  // }
  return (
    <>
      {treatmentListData.length > 0 ?
        <div className='filterbox mb-4'>
          <div className="filterbox-title">{categryName} Treatment</div>
          <div className='filterbox-body'>
            {treatmentListData.map((value) => (
              <div className="latest-post">
                <div className="thumbnail">
                  <a href={'/treatment/' + slug+"/"+ value.treatment_slug}>
                    <img src={value.treatment_image != null ? categoryImageUrl + value.treatment_image : categoryDefaultImage} alt="Category Image"></img>
                  </a>
                </div>
                <div className="post-content">
                  <h6 className="title"><a href={'/treatment/' +slug+"/"+ value.treatment_slug}>{value.treatment_name}</a></h6>
                  {/* <ul className="blog-meta"><li>Read more</li></ul> */}
                  <a href={'/treatment/' +slug+"/"+ value.treatment_slug} className='lp-readmore'> Read more</a>
                </div>
              </div>
            
            ))}
          </div>
        </div> : ""}
      {relatedBlogData.length > 0 ?
        <div className='filterbox mb-4'>
          <div className="filterbox-title">Related Articles</div>
          <div className='filterbox-body filter-height-auto'>
            {
              relatedBlogData.map((value) => (
                <div className="latest-post">
                  <div className="thumbnail">
                    <a href={`/blogs/${value.blog_slug}`}>
                      <img src={value.blog_image != null ? relatedBlogImageUrl + value.blog_image : relatedDefaultImage} alt="Blog Images"></img>
                    </a>
                  </div>
                  <div className="post-content">
                    <h6 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h6>
                    <ul className="blog-meta"><li><i className="icon-27"></i>{moment(value.created_at).format('DD-MM-YYYY')}</li></ul>
                  </div>
                </div>))}
          </div>
        </div> : ""}
      <div className='filterbox mb-4'>
        <img src='/assets/images/treatmentsidebar.webp' />
      </div>
      {/* <div className='filterbox mb-4'>
    <div className="filterbox-title">Facilities</div>
    <div className='filterbox-body'>
      <div className='filter-list'>
        <ul>
          <li><i class="ri-checkbox-blank-line"></i> India</li>
        </ul>
      </div>
    </div>
    </div> */}
    </>
  )
}
export default TreatmentListSideBar;

