import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import ListingShortcodeForm from "../ListingShortcodeForm";
import { ApiService } from "../../Components/Services/apiservices";
import { Helmet } from "react-helmet";
let apiServices = new ApiService();
function Testimonials() { 
  const [defaultImage, setDefaultImage] = useState('')
  const [testimonialData, setTestimonialData] = useState([])
  const [testimonialImageUrl, setTestimonialImageUrl] = useState('')
  const [categoryData, setCategoryData] = useState([])
  const [categoryRes, setCategoryRes] = useState('')
  const [spinnerLoading, setSpinnerLoading] = useState()
  const [pageData ,setPageData] = useState({})
  const didMountRef = useRef(true)
  const { slug } = useParams()
  const { type } = useParams()
  useEffect(() => {
    if (didMountRef.current) {
      pagesData()
      localStorage.removeItem("CATEGRY_FILTER");
      if (type == "category") {
        testimonialcategorylist()
      }
      else {
        if (testimonialData.length == 0) {
          apiServices.testimoniallistGetRequest().then(res => {
            if (res.data.status == "success") {
              console.log()
              setTestimonialData(res.data.testimonialsData)
              setTestimonialImageUrl(res.data.testimonial_img_url)
              setDefaultImage(res.data.default_image_baseurl)
            }
          })
        }
      }
      setSpinnerLoading(true)
      apiServices.doctorspecialitylistGetRequest().then(res => {
        if (res.data.status == 'success') {
          setCategoryData(res.data.doctorsSpecialityList)
          setSpinnerLoading(false)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  var categoryArray = []
  const categoryfilter = (id, slug) => {
    if (localStorage.getItem("CATEGRY_FILTER") != null) {
      categoryArray = JSON.parse(localStorage.getItem("CATEGRY_FILTER"));
    }
    var index = categoryArray.indexOf(id)
    if (document.getElementById("category" + id).classList.contains('ri-checkbox-fill')) {
      document.getElementById("category" + id).className = "ri-checkbox-blank-line"
      categoryArray.splice(index, 1);
    }
    else {
      document.getElementById("category" + id).className = "ri-checkbox-fill"
      categoryArray.push(id)
    }
    localStorage.setItem("CATEGRY_FILTER", JSON.stringify(categoryArray));
    doctorsfilter()
  }
  const doctorsfilter = () => {
    const dataString = {
      "testimonial_category_id": JSON.parse(localStorage.getItem("CATEGRY_FILTER")),
    }
    apiServices.testimonialfilterPostRequest(dataString).then(res => {
      setTestimonialData(res.data.testimonialCatList)
      setTestimonialImageUrl(res.data.doctor_url)
      setDefaultImage(res.data.default_image_baseurl)
    })
  }
  const testimonialcategorylist = () => {
    const dataString = {
      "category_slug": slug
    }
    apiServices.categorywisetestimonialPostRequest(dataString).then(res => {
      if (res.data.status == "success") {
        setTestimonialData(res.data.testimonialsData)
        setCategoryRes(res.data.category.category_name)
        setTestimonialImageUrl(res.data.testimonial_img_url)
        setDefaultImage(res.data.default_image_baseurl)
      }
    })
  }
  const pagesData=()=>{
    const dataString={
      "page_url":"testimonial"
    }
    apiServices.PagesDataPostRequest(dataString).then(res=>{
if(res.data.status == "success"){
  setPageData(res.data.pagesData)
}
    })
  }
  return (
    <>
        <Helmet>
        <title>{pageData.page_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />
        {pageData.page_meta_keyword != null ? (
          <meta
            name="keywords"
            content={pageData.page_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}
       
        <meta
          property="og:title"
          content={pageData.page_meta_title}
        />
      
      <meta name="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />
<link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={pageData.page_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />
       
      </Helmet>
      <Header />
      {spinnerLoading == false ?
        <>
          <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
            <div className="container">
              <div className="row">
                <div className='col-lg-12'>
                  <h1>Patient Testimonials</h1>
                  <div className="breadcrumb-inner">
                    <ul className="edu-breadcrumb">
                      <li className="breadcrumb-item"><a href="/">Home</a></li>
                      <li className="separator"><i className="icon-angle-right"></i></li>
                      <li className="breadcrumb-item"><a href="/patient-testimonials">Patient Testimonials</a></li>
                      <li className="separator"><i className="icon-angle-right"></i></li>
                      <li className="breadcrumb-item active" aria-current="page">{categoryRes}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="section-gap-equal-small">
            <div className="container">
              <div className="row ">
                <div className="col-lg-3">
                  {categoryData.length > 0 ?
                    <div className='filterbox mb-4'>
                      <div className="filterbox-title">Categories</div>
                      <div className='filterbox-body '>
                        <div className='filter-list'>
                          <ul>
                            {categoryData.map((value) => (
                              <li><i className="ri-checkbox-blank-line" onClick={(e) => { categoryfilter(value.category_id, value.category_slug) }} id={"category" + value.category_id}></i> {value.category_name}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div> : ""}
                </div>

                <div className='col-lg-9' >
                {testimonialData.map((value, index) => {
                    if (index > 0 && (index + 1) % 5 === 0) {
                      return (
                        <>
            
                    <div className='storibox'>
                      <div className='storibox-media'>
                        <a href={`/patient-testimonials/${value.testimonial_url}`}>
                          <img src={value.testimonial_profile != null ? testimonialImageUrl + value.testimonial_profile : defaultImage} className='img-fluid'></img></a>
                      </div>
                      <div className='storibox-content'>
                        <h3><a href={`/patient-testimonials/${value.testimonial_url}`}>{value.testimonial_title}</a></h3>
                        {value.testimonial_short_description ? <p>{value.testimonial_short_description}</p> : ""}
                        <a href={`/patient-testimonials/${value.testimonial_url}`} className='storibox-read-more'> Read more</a>
                      </div>
                    </div>
      
                <ListingShortcodeForm />
                </>
                      )
                }
                else {
                  return (
      
                      <div className='storibox'>
                        <div className='storibox-media'>
                          <a href={`/patient-testimonials/${value.testimonial_url}`}><img src={value.testimonial_profile != null ? testimonialImageUrl + value.testimonial_profile : defaultImage} className='img-fluid'></img></a>
                        </div>
                        <div className='storibox-content'>
                          <h3><a href={`/patient-testimonials/${value.testimonial_url}`}>{value.testimonial_title}</a></h3>
                          {value.testimonial_short_description ? <p>{value.testimonial_short_description}</p> : ""}
                          <a href={`/patient-testimonials/${value.testimonial_url}`} className='storibox-read-more'> Read more</a>
                        </div>
                      </div>
                  )
                }
              })}
                  </div>
              </div>
            </div>
          </section>
          <Footer />
        </> :
        <div className="loder">
          <img src="/assets/images/loading-gif.webp"></img>
        </div>}
    </>
  )
}
export default Testimonials