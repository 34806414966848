import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import TestimonialsDetailsSideBar from "../TestimonialsDetailsSideBar";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Helmet } from "react-helmet";
import moment from 'moment';
let apiServices = new ApiService()
function TestimonialsDetails() {
  const didMountRef = useRef(true)
  const { slug } = useParams()
  const [testimonialDetailData, setTestimonialDetailData] = useState("")
  const [testimonialImageUrl, setTestimonialImageUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const [spinnerLoading, setSpinnerLoading] = useState()
  const [testimonialHospitalData, setTestimonialHospitalData] = useState([])
  const [testimonialHospitalImageUrl, setTestimonialHospitalImageUrl] = useState('')
  const [testimonialDoctorData, setTestimonialDoctorData] = useState([])
  const [testimonialDoctorImageUrl, setTestimonialDoctorImageUrl] = useState('')
  const [videoData, setVideoData] = useState([])
  useEffect(() => {
    if (didMountRef.current) {
      setSpinnerLoading(true)
      const dataString = {
        "testimonial_url": slug
      }
      apiServices.testimonialdetailPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setTestimonialDetailData(res.data.testimonialDetails)
          setTestimonialImageUrl(res.data.testimonial_img_url)
          setDefaultImage(res.data.default_image_baseurl)
          setSpinnerLoading(false)
        }
        else {
          setSpinnerLoading(false)
        }
      })
      apiServices.relatedtestimonialhospitalsPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setTestimonialHospitalData(res.data.hospitalData)
          setTestimonialHospitalImageUrl(res.data.hospital_url)
          setDefaultImage(res.data.default_image_baseurl)
        }

      })
      apiServices.relatedtestimonialdoctorsPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setTestimonialDoctorData(res.data.doctorData)
          setDefaultImage(res.data.default_image_baseurl)
          setTestimonialDoctorImageUrl(res.data.doctor_url)
        }
      })
      apiServices.relatedtestimonialvideosPostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          setVideoData(res.data.videoData)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  return (
    <>
     <Helmet>
        <title>{testimonialDetailData.
testimonial_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            testimonialDetailData.
testimonial_meta_desc != null
              ? testimonialDetailData.
testimonial_meta_desc
              : "JDMax Healthcare"
          }
        />
        {
testimonialDetailData.
testimonial_meta_keyword != null ? (
          <meta
            name="keywords"
            content={testimonialDetailData.
testimonial_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}
       
        <meta
          property="og:title"
          content={testimonialDetailData.
testimonial_meta_title}
        />
      
      <meta name="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />
<link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            testimonialDetailData.
testimonial_meta_desc != null
              ? testimonialDetailData.
testimonial_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={testimonialDetailData.
testimonial_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            testimonialDetailData.
testimonial_meta_desc != null
              ? testimonialDetailData.
testimonial_meta_desc
              : "JDMax Healthcare"
          }
        />
       
      </Helmet>
      <Header />
      {spinnerLoading === false ? <>
        <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
          <div className="container">
            <div className="row">
              <div className='col-lg-12'>
                {/* <h1>{testimonialDetailData.testimonial_title}</h1> */}
                <div className="breadcrumb-inner">
                  <ul className="edu-breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a></li>
                    <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item">
                      <a href="/patient-testimonials">Patient Testimonials</a>
                    </li>
                    <li className="separator"><i className="icon-angle-right"></i></li>
                    <li className="breadcrumb-item">
                      {testimonialDetailData.testimonial_title}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-equal-small">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-8">
                <h1 className="page-title-h1">{testimonialDetailData.testimonial_title}</h1>
                <div className="hosbox">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="hosbox-img">
                        <img src={testimonialDetailData.testimonial_profile != null ? testimonialImageUrl + testimonialDetailData.testimonial_profile : defaultImage} className="img-fluid"></img>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="hosbox-content">
                        <h2 className="hosbox-name"><a href=""></a></h2>
                        <p className="hosbox-loc"> </p>
                        <div className="hosbox-list">
                          <ul>
                            <li><span>Name :</span> {testimonialDetailData.testimonial_name}</li>
                            <li><span>From : </span> {testimonialDetailData.testimonial_destination} </li>
                            <li><span>Treatment :</span>{testimonialDetailData.testimonial_category_name} </li>
                          </ul>
                          <ul className="blog-meta">
                            <li><i className="icon-27"></i>{moment(testimonialDetailData.created_at).format('DD-MM-YYYY')}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-lg-12">
                        <div className='treatment-content' dangerouslySetInnerHTML={{ __html: testimonialDetailData.testimonial_description }}></div>
                      </div>
                    </div>
                    {/* <div className="col-lg-2">
                        <div className="hosbox-content-footer">
                          <a href="#" className="edu-btn01 btn-small btn-full"> Inquiry</a>
                           <a href="" className="edu-btn btn-small btn-full">View Profile <i class="icon-4"></i></a>
                        </div>
                        </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 siderbar-filter">
                <TestimonialsDetailsSideBar data={testimonialDetailData.testimonial_category_name} />
              </div>
            </div>
          </div>
        </section>
        {testimonialHospitalData.length > 0 ?
          <div className="edu-team-area team-area-1 edu-section-gap bg-lighten01" style={{ marginBottom: "5px" }} >
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Hospitals</span>
                <h2 className="title">Related Hospitals</h2>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 5,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5,
                  },
                }}
                className="hospitalslider">
                {/* {loading == true ?<>
    <SwiperSlide>
    <Skeleton varient="rectangular"  />
    <Skeleton varient="text" className="skeleton-bottom"  />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular"  />
    <Skeleton varient="text" className="skeleton-bottom"  />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular" />
    <Skeleton varient="text" className="skeleton-bottom"  />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular" />
    <Skeleton varient="text" className="skeleton-bottom" />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular" />
    <Skeleton varient="text" className="skeleton-bottom" />
    </SwiperSlide>
    </> */}
                {testimonialHospitalData.map((value) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                      <div className="inner">
                        <div className="thumbnail-wrap">
                          <div className="thumbnail">
                            <a href={`/hospital/${value.hospital_slug}`}>
                              <img src={value.hospital_image != null ? testimonialHospitalImageUrl + value.hospital_image : defaultImage} alt="team images"></img>
                            </a>
                          </div>
                        </div>
                        <div className="content">
                          <h6 className="title"><a href={`/hospital/${value.hospital_slug}`}>{value.hospital_name}</a></h6>
                          {value.hospital_city_name != "" && value.hospital_country_name != "" ?
                            <p>{value.hospital_city_name} , {value.hospital_country_name}</p> : ""}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

              </Swiper>
            </div>
          </div> : ""}
        {testimonialDoctorData.length > 0 ?
          <div className="edu-team-area team-area-1 section-gap-equal" >
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Doctors</span>
                <h2 className="title">Related Doctors</h2>
                <span className="shape-line"><i className="icon-19"></i></span>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 5,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5,
                  },
                }}
                className="doctorslider">
                {testimonialDoctorData.map((value) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                      <div className="inner">
                        <div className="thumbnail-wrap">
                          <div className="thumbnail">
                            <a href={`/doctor/${value.doctor_slug}`}>
                              <img src={value.doctor_image != null ? testimonialDoctorImageUrl + value.doctor_image : defaultImage} ></img>
                            </a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title-second"><a href={`/doctor/${value.doctor_slug}`}>{value.doctor_name} </a></h5>
                          <span className="designation">{value.doctor_designation}</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div> : ""}
        {videoData.length > 0 ?
          <div className="edu-team-area team-area-1 section-gap-equal bg-lighten01" style={{ marginBottom: "5px" }}>
            <div className="container">
              <div className="section-title section-center">
                <span className="pre-title">Videos</span>
                <h2 className="title">Top Videos</h2>
                <span className="shape-line"><i className="icon-19"></i></span>
              </div>
              <Swiper
                spaceBetween={15}
                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 5,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5,
                  },
                }}
                className="doctorslider">
                {videoData.map((value) => (
                  <SwiperSlide>
                    <div className="edu-team-grid team-style-1">
                      <div className="inner">
                        <div className="">
                          <div className="">
                            <iframe height="300" width="100%" src={value.video_link != null ? "https://www.youtube.com/embed/" + value.video_link + "?autoplay=1" : ""}></iframe>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title-second">{value.video_name}</h5>
                          <span className="designation">{value.doctor_designation}</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div> : ""}
        <Footer />
      </>
        : <div className="loder">
          <img src="/assets/images/loading-gif.webp"></img>
        </div>}
    </>
  )
}
export default TestimonialsDetails