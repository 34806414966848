import React, { useEffect, useState, useRef } from 'react';
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { ApiService } from "../../Components/Services/apiservices";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
let apiServices = new ApiService()
function TreatmentHospital(props) {
    const didMountRef = useRef(true)
    const { slug } = useParams()
    const [treatmentHospitalData, setTreatmentHospitalData] = useState([])
    const [treatmentImageUrl, setTreatmentImageUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    useEffect(() => {
        if (didMountRef.current) {
           const dataString = {
                "treatment_slug": slug
            }
            apiServices.treatmenthospitalsPostRequest(dataString).then(res => {
                if (res.data.status == "success") {
                    setTreatmentHospitalData(res.data.hospitalData)
                    setTreatmentImageUrl(res.data.hospital_url)
                    setDefaultImage(res.data.default_image_baseurl)
                }
            })
        }
        didMountRef.current = false;
    }, [])
    return (
        <>
            {treatmentHospitalData.length > 0 ?
                <div className="edu-team-area team-area-1 edu-section-gap bg-lighten01" style={{ marginBottom: "5px" }} >
                    <div className="container">
                        <div className="section-title section-center">
                            <span className="pre-title">Hospitals</span>
                            <h2 className="title">{props.data} Hospitals in India</h2>
                        </div>
                        <Swiper
                            spaceBetween={15}
                            // modules={[Navigation, Pagination, Scrollbar, A11y]}
                            // navigation
                            // pagination={{ clickable: true }}
                            //   scrollbar={{ draggable: true }}
                            breakpoints={{

                                // when window width is >= 640px
                                340: {
                                    width: 340,
                                    slidesPerView: 2,
                                },
                                640: {
                                    width: 640,
                                    slidesPerView: 2,
                                },
                                // when window width is >= 768px
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                                1000: {
                                    width: 1000,
                                    slidesPerView: 5,
                                },
                                1200: {
                                    width: 1200,
                                    slidesPerView: 5,
                                },
                            }}
                            className="hospitalslider">
                            {/* {loading == true ?<>
    <SwiperSlide>
    <Skeleton varient="rectangular"  />
    <Skeleton varient="text" className="skeleton-bottom"  />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular"  />
    <Skeleton varient="text" className="skeleton-bottom"  />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular" />
    <Skeleton varient="text" className="skeleton-bottom"  />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular" />
    <Skeleton varient="text" className="skeleton-bottom" />
    </SwiperSlide>
    <SwiperSlide>
    <Skeleton varient="rectangular" />
    <Skeleton varient="text" className="skeleton-bottom" />
    </SwiperSlide>
    </> */}
                            {treatmentHospitalData.map((value, index) => (
                                <SwiperSlide>
                                    <div className="edu-team-grid team-style-1" key={index} >
                                        <div className="inner">
                                            <div className="thumbnail-wrap">
                                                <div className="thumbnail">
                                                    <a href={`/hospital/${value.hospital_slug}`}>
                                                        <img src={value.hospital_image != null ? treatmentImageUrl + value.hospital_image : defaultImage} alt="team images"></img>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h6 className="title"><a href={`/hospital/${value.hospital_slug}`}>{value.hospital_name}</a></h6>
                                                {value.hospital_city_name != "" && value.hospital_country_name != "" ?
                                                    <p>{value.hospital_city_name} , {value.hospital_country_name}</p> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}

                        </Swiper>
                    </div>
                </div> : ""}
        </>)
}
export default TreatmentHospital