import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams } from 'react-router-dom';
import { ApiService } from '../../Components/Services/apiservices';
const apiServices = new ApiService()
function TreatmentDoctor() {
    const { slug } = useParams()
    const didMountRef = useRef(true)
    const [treatmentHospitalData, setTreatmentHospitalData] = useState([])
    const [treatmentImageUrl, setTreatmentImageUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    useEffect(() => {
        if (didMountRef.current) {
            const dataString = {
                "treatment_slug": slug
            }
            apiServices.treatmentdoctorsPostRequest(dataString).then(res => {
                if (res.data.status == "success") {
                    setTreatmentHospitalData(res.data.hospitalData)
                    setDefaultImage(res.data.default_image_baseurl)
                    setTreatmentImageUrl(res.data.doctor_url)
                }
            })
        }
        didMountRef.current = false
    }, [])
    return (
        <>
            {treatmentHospitalData.length > 0 ?
                <div className="edu-team-area team-area-1 section-gap-equal">
                    <div className="container">
                        <div className="section-title section-center">
                            <span className="pre-title">Doctors</span>
                            <h2 className="title">Popular Doctors</h2>
                            <span className="shape-line"><i className="icon-19"></i></span>
                        </div>
                        <Swiper
                            spaceBetween={15}
                            breakpoints={{
                                // when window width is >= 640px
                                340: {
                                    width: 340,
                                    slidesPerView: 2,
                                },
                                640: {
                                    width: 640,
                                    slidesPerView: 2,
                                },
                                // when window width is >= 768px
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                                1000: {
                                    width: 1000,
                                    slidesPerView: 5,
                                },
                                1200: {
                                    width: 1200,
                                    slidesPerView: 5,
                                },
                            }}
                            className="doctorslider">
                            {treatmentHospitalData.map((value) => (
                                <SwiperSlide>
                                    <div className="edu-team-grid team-style-1">
                                        <div className="inner">
                                            <div className="thumbnail-wrap">
                                                <div className="thumbnail">
                                                    <a href={`/doctor/${value.doctor_slug}`}>
                                                        <img src={value.doctor_image != null ? treatmentImageUrl + value.doctor_image : defaultImage} ></img>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="title-second"><a href={`/doctor/${value.doctor_slug}`}>{value.doctor_name} </a></h5>
                                                <span className="designation">{value.doctor_designation}</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div> : ""}
        </>)
}
export default TreatmentDoctor