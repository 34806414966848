import React, { useEffect, useState, useRef } from 'react';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Home_Packages from "../Home_Packages"
import Doctors from "../Home_Doctors"
import Home_Articles from "../Home_Articles";
import Home_Category from "../Home_Category"
// import Partners from '../Partners';
import Home_Testimonials from '../Home_Testimonials'
import Home_Hospitals from '../Home_Hospitals';
import { ApiService } from "../../Components/Services/apiservices";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import HomeVideo from '../Home_video';
let apiServices = new ApiService()
function Home() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [pageData, setPageData] = useState({})
  const [searchData, setSearchData] = useState([])
  const didMountRef = useRef(true)
  const navigate = useNavigate()
  useEffect(() => {
    if (didMountRef.current) {
      pagesData()
     
    }
    didMountRef.current = false;
  }, [])

  const pagesData = () => {
    const dataString = {
      "page_url": "home"
    }
    apiServices.PagesDataPostRequest(dataString).then(res => {
      if (res.data.status == "success") {
        setPageData(res.data.pagesData)
      }
    })
  }
  function dashboardSearch(name) {
 if(name !== ""){
    const dataString = {
      search: name
    }
    apiServices.dashboardsearchPostRequest(dataString).then(res => {
      if (res.data.status == "success") {
        setSearchData(res.data.data)

      }
    })
  }
  else{
    setSearchData("")

  }
  }


  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />
        {pageData.page_meta_keyword != null ? (
          <meta
            name="keywords"
            content={pageData.page_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}

        <meta
          property="og:title"
          content={pageData.page_meta_title}
        />

        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={pageData.page_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "JDMax Healthcare"
          }
        />

      </Helmet>
      <Header />
      <div id="main-wrapper" className="main-wrapper">
        <div className="section-search">
          <div className="container">
            <div className="search-wrapper text-center">
              <h1 className="title" >Find Best <span className="color-secondary">Healthcare</span> in INDIA</h1>
              {/* <p >Discover the best doctors, clinic & hospital the city nearest to you.</p> */}
              <p>Most Trusted HealthCare Service Platform Search Top Hospitals, Best Doctors & Procedure details </p>
              <div className="section-search-box">
                <input placeholder="Search for a procedure, hospital, and doctor.." id="search_input" name="search_input" onChange={ (e)=>{dashboardSearch(e.target.value)}}/>
                {searchData && searchData.length >0?<>
  <div className="header-center">
<div className='header-search'>
    <div className='header-search-list'  id="search_input">
    <ul className='' style={{listStyle:"none"}}>
        {searchData?.map((items,index)=>{return(<>
          {items?.doctor_name && (
            <li onClick={()=>{navigate(`/doctor/${items.doctor_slug}`);}}  key={index}>{items?.doctor_name}</li>
          )}
            {items?.hospital_name && (
            <li onClick={()=>{navigate(`/hospital/${items.hospital_slug}`);}} key={index}>{items?.hospital_name}</li>
            )}
        
        </>)})}
    
        
    </ul>
</div>
</div>
</div>


</>:""}
              </div>
              <div>
                {/* {searchData.length > 0 ?
<div>
  <ul style={{listStyleType: "none"}}>
  {searchData.map((value) => (
    <>
    <li >{value.doctor_name}</li>
    <li>{value.hospital_name}</li>
    </>
  ))}
  </ul>
</div>:""} */}


             
              

              </div>
            </div>
          </div>
        </div>
        <div className="features-area-2">
          <div className="container">
            <div className="features-grid-wrap">
              <div className="features-box features-style-2 edublink-svg-animate">
                <div className="icon">
                  <img className="svgInject" src="/assets/images/animated-svg-icons/online-class.svg" alt="animated icon" />
                </div>
                <div className="content">
                  <h5 className="title"><span>Verified</span> Hospitals</h5>
                </div>
              </div>
              <div className="features-box features-style-2 edublink-svg-animate">
                <div className="icon">
                  <img className="svgInject" src="/assets/images/animated-svg-icons/instructor.svg" alt="animated icon" />
                </div>
                <div className="content">
                  <h5 className="title"><span>Free Expert</span>  Medical Opinions</h5>
                </div>
              </div>
              <div className="features-box features-style-2 edublink-svg-animate">
                <div className="icon certificate">
                  <img className="svgInject" src="/assets/images/animated-svg-icons/certificate.svg" alt="animated icon" />
                </div>
                <div className="content">
                  <h5 className="title"><span>24/7 Patient</span> Assistance</h5>
                </div>
              </div>
              <div className="features-box features-style-2 edublink-svg-animate">
                <div className="icon">
                  <img className="svgInject" src="/assets/images/animated-svg-icons/user.svg" alt="animated icon" />
                </div>
                <div className="content">
                  <h5 className="title"><span>Compare Cost,</span> Get Exclusive Packages</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Home_Category />
        <HomeVideo/>
        <Home_Packages />
        <Home_Hospitals />
        <Home_Testimonials />
        <Doctors />
        {/* <Partners/> */}
        <Home_Articles />
        <Footer />
      </div>
    </>
  )
}
export default Home;
