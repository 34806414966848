import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Containers/Home';
import Packages from './Containers/Packages';
import Hospitals from './Containers/Hospitals';
import Doctors from './Containers/Doctors';
import Contact from './Containers/Contact';
import Aboutus from './Containers/Aboutus';
import Pages from './Containers/Pages';
import LoginPage from './Containers/LoginPage';
import RegisterPage from './Containers/RegisterPage';
import FreeQuotePage from './Containers/FreeQuotePage';
import Testimonials from './Containers/Testimonials';
import Blogs from './Containers/Blogs';
import Treatment from './Containers/Treatment';
import DoctorsDetails from './Containers/DoctorsDetails';
import HospitalsDetails from './Containers/HospitalsDetails';
import CategoryDetails from './Containers/CategoryDetails';
import BlogsDetails from './Containers/BlogsDetails';
import PackagesDetails from './Containers/PackagesDetails';
import TestimonialsDetails from './Containers/TestimonialsDetails';
import TreatmentDetails from './Containers/TreatmentDetails';
import TreatmentListSideBar from './Containers/TreatmentListSideBar';
import Knowledge from './Containers/Knowledge';
import FreeConsultationPage from './Containers/FreeConsultationPage';
import HospitalFilter from './Containers/Hospitals/hospitalfilter';
import MyAccount from './Containers/MyAccount';
import DeleteAccount from './Containers/DeleteAccount';



function App() {
  return (
    <div className="App">
  <Router>
  <Routes>
  <Route exact path='/' activeClassName="active" element={ <Home /> }/>
  <Route exact path='/packages' activeClassName="active" element={ <Packages /> }/>
  <Route exact path='/hospitals' activeClassName="active" element={ <Hospitals /> }/>
  <Route exact path='/doctors' activeClassName="active" element={ <Doctors /> }/>
  <Route exact path='/contact' activeClassName="active" element={ <Contact /> }/>
  <Route exact path='/aboutus' activeClassName="active" element={ <Aboutus /> }/>
  <Route exact path='/login' activeClassName="active" element={ <LoginPage /> }/>
  <Route exact path='/register' activeClassName="active" element={ <RegisterPage /> }/>
  <Route exact path='/get-a-quote' activeClassName="active" element={ <FreeQuotePage /> }/>
  <Route exact path='/patient-testimonials' activeClassName="active" element={ <Testimonials /> }/>
  <Route exact path='/blogs' activeClassName="active" element={ <Blogs /> }/>
  <Route exact path='/blogs/:type/:slug' activeClassName="active" element={ <Blogs /> }/>
  <Route exact path='/treatment/:slug' activeClassName="active" element={ <Treatment /> }/>
  <Route exact path='/doctors/:slug1' activeClassName="active" element={ <Doctors /> }/>
  <Route exact path='/doctors/:slug1/:slug2' activeClassName="active" element={ <Doctors /> }/>
  <Route exact path='/doctor/:slug' activeClassName="active" element={ <DoctorsDetails /> }/>

  {/* <Route exact path='/hospitals+in' activeClassName="active" element={ <HospitalFilter /> }/>
  <Route exact path='/category+hospitals+in' activeClassName="active" element={ <HospitalFilter /> }/>
  <Route exact path='/hospitals-in+/:country' activeClassName="active" element={ <HospitalFilter /> }/> */}
  <Route exact path='/hospitals/:slug1' activeClassName="active" element={ <Hospitals /> }/>
  <Route exact path='/hospitals/:slug1/:slug2' activeClassName="active" element={ <Hospitals /> }/>
  <Route exact path='/hospital/:slug' activeClassName="active" element={ <HospitalsDetails /> }/>


  <Route exact path='/categories/:slug' activeClassName="active" element={ <CategoryDetails/> }/>
  <Route exact path='/blogs/:slug' activeClassName="active" element={ <BlogsDetails/> }/>
  <Route exact path='/packages/:slug' activeClassName="active" element={ <PackagesDetails /> }/>
  <Route exact path='/patient-testimonials/:slug' activeClassName="active" element={ <TestimonialsDetails /> }/>
  <Route exact path='/patient-testimonials/:type/:slug' activeClassName="active" element={ <Testimonials /> }/>
  <Route exact path='/treatmentlist/:slug' activeClassName="active" element={ <TreatmentListSideBar /> }/>
  <Route exact path='/treatment/:category/:slug' activeClassName="active" element={ <TreatmentDetails /> }/>
  <Route exact path='/video' activeClassName="active" element={ <Knowledge /> }/>
  <Route exact path='/free-consultation' activeClassName="active" element={ <FreeConsultationPage /> }/>
  <Route exact path='/free-consultation/:type/:id' activeClassName="active" element={ <FreeConsultationPage /> }/>
  <Route exact path='/myaccount' activeClassName="active" element={ <MyAccount/> }/>
  <Route exact path='/deleteaccount' activeClassName="active" element={ <DeleteAccount/> }/>

  <Route exact path='/:slug' activeClassName="active" element={<Pages/>} />
 </Routes>
 </Router>
    </div>
  );
}

export default App;
