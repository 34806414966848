import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
import { ApiService } from "../../Components/Services/apiservices";
import { Helmet } from "react-helmet";
let apiServices = new ApiService();
function BlogsDetails() {
    const didMountRef = useRef(true)
    const { slug } = useParams()
    const [treatmentHospitalData, setTreatmentHospitalData] = useState([])
    const [treatmentImageUrl, setTreatmentImageUrl] = useState('')
    const [treatmentDoctorData, setTreatmentDoctorData] = useState([])
    const [doctorImageUrl, setDoctorImageUrl] = useState('')
    const [loading, setloading] = useState(true)
    const [blogData, setBlogData] = useState([])
    const [categoryData, setCategoryData] = useState('')
    const [blogImageUrl, setBlogImageUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    const [latestBlogData, setLatestBlogData] = useState([])
    const [latestCategoryData, setLatestCategoryData] = useState([])
    const [latestBlogImageUrl, setLatestBlogImageUrl] = useState('')
    const [blogListDefaultImage, setBlogListDefaultImage] = useState('')
    const [blogName, setBlogName] = useState('')
    const [blogDesc, setBlogDesc] = useState('')
    const [bannerUrl, setBannerUrl] = useState('')
    const [bannerImage, setBannerImage] = useState('')
    const [blogDate, setBlogDate] = useState('')
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [blogDetails , setBlogDetails] = useState({})
    const [videoData, setVideoData] = useState([])
    useEffect(() => {
        if (didMountRef.current) {
            setSpinnerLoading(true)
            apiServices.bloglistsidebardataGetRequest().then(res => {
                if (res.data.status === "success") {
                    setLatestCategoryData(res.data.blogCategories)
                    setLatestBlogData(res.data.latestblogdata)
                    setLatestBlogImageUrl(res.data.blog_url)
                    setBlogListDefaultImage(res.data.default_image_baseurl)
                }
            })
            const dataString = {
                "blog_slug": slug
            }
            apiServices.blogdetailsPostRequest(dataString).then(res => {
                if (res.data.status === "success") {
                    setBlogName(res.data.blogDetails.blog_name)
                    setBlogDetails(res.data.blogDetails)
                    setBlogDesc(res.data.blogDetails.blog_desc)
                    setBannerUrl(res.data.blog_url)
                    setBannerImage(res.data.blogDetails.blog_image)
                    setBlogDate(res.data.blogDetails.created_at)
                    setloading(false)
                    setSpinnerLoading(false)
                }
                else {
                    setloading(false)
                    setSpinnerLoading(false)
                }
            })
            apiServices.bloghospitalsPostRequest(dataString).then(res => {
                if (res.data.status === "success") {
                    setTreatmentHospitalData(res.data.hospitalData)
                    setTreatmentImageUrl(res.data.hospital_url)
                    setDefaultImage(res.data.default_image_baseurl)
                }
            })
            apiServices.blogdoctorsPostRequest(dataString).then(res => {
                if (res.data.status === "success") {
                    setTreatmentDoctorData(res.data.hospitalData)
                    setDefaultImage(res.data.default_image_baseurl)
                    setDoctorImageUrl(res.data.doctor_url)
                }
            })
            apiServices.relatedblogvideosPostRequest(dataString).then(res => {
                if (res.data.status === "success") {
                    setVideoData(res.data.videoData)
                }
            })
        }
        didMountRef.current = false;
    }, [])
    const categorywisebloglist = (category_slug) => {
        const dataString = {
            "category_slug": category_slug
        }
        apiServices.categorywisebloglistPostRequest(dataString).then(res => {
            if (res.data.status === "success") {
                setBlogData(res.data.blogsData)
                setCategoryData(res.data.categoryData.category_name)
                setBlogImageUrl(res.data.blog_url)
                setDefaultImage(res.data.default_image_baseurl)
            }
        })
    }
    return (
        <>
         <Helmet>
        <title>{blogDetails.blog_meta_title}</title>

        <meta
          name="description"
          itemprop="description"
          content={
            blogDetails.blog_meta_desc != null
              ? blogDetails.blog_meta_desc
              : "JDMax Healthcare"
          }
        />
        {blogDetails.blog_meta_keyword != null ? (
          <meta
            name="keywords"
            content={blogDetails.blog_meta_keyword}
          />
        ) : (
          "JDMax Healthcare"
        )}
       
        <meta
          property="og:title"
          content={blogDetails.blog_meta_title}
        />
      
      <meta name="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />
<link rel="canonical" href={window.location.href} />
        <meta
          property="og:description"
          content={
            blogDetails.blog_meta_desc != null
              ? blogDetails.blog_meta_desc
              : "JDMax Healthcare"
          }
        />

        <meta
          name="twitter:title"
          content={blogDetails.blog_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            blogDetails.blog_meta_desc != null
              ? blogDetails.blog_meta_desc
              : "JDMax Healthcare"
          }
        />
       
      </Helmet>
            <Header />
            {spinnerLoading === false ?
                <>
                    <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
                        <div className="container">
                            <div className="row">
                                <div className='col-lg-12'>
                                    <h1>News & Blogs</h1>
                                    <div className="breadcrumb-inner">
                                        <ul className="edu-breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="separator"><i className="icon-angle-right"></i></li>
                                            <li className="breadcrumb-item"><a href="/blogs">News & Blogs</a></li>
                                            <li className="separator"><i className="icon-angle-right"></i></li>
                                            <li className="breadcrumb-item active" aria-current="page">{blogName}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="blog-details-area section-gap-equal">
                        <div className="container">
                            <div className="row row--30">
                                <div class="col-lg-8">
                                    <div className="blog-details-content">
                                        <div className="entry-content">
                                            {bannerImage != null ?
                                                <img src={bannerUrl + bannerImage} className="img-fluid mb-5" /> : ""}
                                            <h3 className="title">{blogName}</h3>
                                            <ul className="blog-meta">
                                                <li><i className="icon-27"></i>{moment(blogDate).format('DD-MM-YYYY')}</li>
                                            </ul>
                                        </div>
                                        {blogDesc != null ?
                                            <div className='treatment-content' dangerouslySetInnerHTML={{ __html: blogDesc }}>
                                            </div> : ""}
                                    </div>
                                </div>
                                <div className="col-lg-4 siderbar-filter">
                                    <div className='filterbox mb-4'>
                                        <div className="filterbox-title">Latest Post</div>
                                        <div className='filterbox-body'>
                                            {
                                                latestBlogData.map((value) => (
                                                    <div className="latest-post">
                                                        <div className="thumbnail">
                                                            <a href={`/blogs/${value.blog_slug}`}>
                                                                <img src={value.blog_image != null ? latestBlogImageUrl + value.blog_image : blogListDefaultImage} alt="Blog Images"></img>
                                                            </a>
                                                        </div>
                                                        <div className="post-content">
                                                            <h6 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h6>
                                                            <ul className="blog-meta"><li><i className="icon-27"></i>{moment(value.created_at).format('DD-MM-YYYY')}</li></ul>
                                                        </div>
                                                    </div>))}
                                        </div>
                                    </div>
                                    <div className='filterbox mb-4'>
                                        <div className="filterbox-title">Categories</div>
                                        <div className='filterbox-body'>
                                            <div className="content">
                                                <ul className="category-list">
                                                    {
                                                        latestCategoryData.map((value) => (
                                                            <li onClick={(e) => categorywisebloglist(value.category_slug)}><a href={`/blogs/category/` + value.category_slug}>{value.category_name} {value.blog_count != 0 ? <span>({value.blog_count})</span> : ""}</a></li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {treatmentHospitalData.length > 0 ?
                        <div className="edu-team-area team-area-1 edu-section-gap bg-lighten01" style={{ marginBottom: "5px" }} >
                            <div className="container">
                                <div className="section-title section-center">
                                    <span className="pre-title">Hospitals</span>
                                    <h2 className="title">Recommended Hospitals</h2>
                                </div>
                                <Swiper
                                    spaceBetween={15}
                                    breakpoints={{
                                        // when window width is >= 640px
                                        340: {
                                            width: 340,
                                            slidesPerView: 2,
                                        },
                                        640: {
                                            width: 640,
                                            slidesPerView: 2,
                                        },
                                        // when window width is >= 768px
                                        768: {
                                            width: 768,
                                            slidesPerView: 2,
                                        },
                                        1000: {
                                            width: 1000,
                                            slidesPerView: 5,
                                        },
                                        1200: {
                                            width: 1200,
                                            slidesPerView: 5,
                                        },
                                    }}
                                    className="hospitalslider">
                                    {treatmentHospitalData.map((value) => (
                                        <SwiperSlide>
                                            <div className="edu-team-grid team-style-1"  >
                                                <div className="inner">
                                                    <div className="thumbnail-wrap">
                                                        <div className="thumbnail">
                                                            <a href={`/hospital/${value.hospital_slug}`}>
                                                                <img src={value.hospital_image != null ? treatmentImageUrl + value.hospital_image : defaultImage} alt="team images"></img>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="title"><a href={`/hospital/${value.hospital_slug}`}>{value.hospital_name}</a></h6>
                                                        {value.hospital_city_name != "" && value.hospital_country_name != "" ?
                                                            <p>{value.hospital_city_name} , {value.hospital_country_name}</p> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}

                                </Swiper>
                            </div>
                        </div> : ""}
                    {treatmentDoctorData.length > 0 ?
                        <div className="edu-team-area team-area-1 section-gap-equal" >
                            <div className="container">
                                <div className="section-title section-center">
                                    <span className="pre-title">Doctors</span>
                                    <h2 className="title">Popular Doctors</h2>
                                    <span className="shape-line"><i className="icon-19"></i></span>
                                </div>
                                <Swiper
                                    spaceBetween={15}
                                    breakpoints={{
                                        // when window width is >= 640px
                                        340: {
                                            width: 340,
                                            slidesPerView: 2,
                                        },
                                        640: {
                                            width: 640,
                                            slidesPerView: 2,
                                        },
                                        // when window width is >= 768px
                                        768: {
                                            width: 768,
                                            slidesPerView: 2,
                                        },
                                        1000: {
                                            width: 1000,
                                            slidesPerView: 5,
                                        },
                                        1200: {
                                            width: 1200,
                                            slidesPerView: 5,
                                        },
                                    }}
                                    className="doctorslider">
                                    {treatmentDoctorData.map((value) => (
                                        <SwiperSlide>
                                            <div className="edu-team-grid team-style-1">
                                                <div className="inner">
                                                    <div className="thumbnail-wrap">
                                                        <div className="thumbnail">
                                                            <a href={`/doctor/${value.doctor_slug}`}>
                                                                <img src={value.doctor_image != null ? doctorImageUrl + value.doctor_image : ""} ></img>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title-second"><a href={`/doctor/${value.doctor_slug}`}>{value.doctor_name} </a></h5>
                                                        <span className="designation">{value.doctor_designation}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div> : ""}
                    {videoData.length > 0 ?
                        <div className="edu-team-area team-area-1 section-gap-equal bg-lighten01" style={{ marginBottom: "5px" }}>
                            <div className="container">
                                <div className="section-title section-center">
                                    <span className="pre-title">Videos</span>
                                    <h2 className="title">Top Videos</h2>
                                    <span className="shape-line"><i className="icon-19"></i></span>
                                </div>
                                <Swiper
                                    spaceBetween={15}
                                    breakpoints={{
                                        // when window width is >= 640px
                                        340: {
                                            width: 340,
                                            slidesPerView: 2,
                                        },
                                        640: {
                                            width: 640,
                                            slidesPerView: 2,
                                        },
                                        // when window width is >= 768px
                                        768: {
                                            width: 768,
                                            slidesPerView: 2,
                                        },
                                        1000: {
                                            width: 1000,
                                            slidesPerView: 5,
                                        },
                                        1200: {
                                            width: 1200,
                                            slidesPerView: 5,
                                        },
                                    }}
                                    className="doctorslider">
                                    {videoData.map((value) => (
                                        <SwiperSlide>
                                            <div className="edu-team-grid team-style-1">
                                                <div className="inner">
                                                    <div className="">
                                                        <div className="">
                                                            <iframe height="300" width="100%" src={value.video_link != null ? "https://www.youtube.com/embed/" + value.video_link + "?autoplay=1" : ""}></iframe>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title-second">{value.video_name}</h5>
                                                        <span className="designation">{value.doctor_designation}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div> : ""}
                    <Footer />
                </>
                : <div className="loder">
                    <img src="/assets/images/loading-gif.webp"></img>
                </div>}
        </>)
}
export default BlogsDetails