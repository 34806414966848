
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function TreatmentShortcodeForm() {
  const [featuredCountries, setFeaturedCountries] = useState([])
  const [nationalityData, setNationalityData] = useState([])
  const [countriesData, setCountriesData] = useState([])
  const [image, setImage] = useState({ preview: "", raw: "" })
  const [allData, setAllData] = useState({ patientname: "", age: "", gender: "", nationality: "", email: "", countrycode: "", phone: "", medicalconcerns: "" })
  const [successMessage, setSuccessMessage] = useState("")
  const imageMimeType = /(jpg|jpeg|doc|docx|pdf|png)/i;
  const didMountRef = useRef(true)
  const location = useLocation()
  useEffect(() => {
    if (didMountRef.current) {
      console.log(location.pathname.replace('/', ''))
      apiServices.countrieslistGetRequest().then(res => {
        console.log(res)
        if (res.data.countries.length > 0) {
          setCountriesData(res.data.countries)
          setFeaturedCountries(res.data.featuredcountries)
        }
      })
      apiServices.nationalitylistGetRequest().then(res => {
        console.log(res)
        if (res.data.nationality.length > 0) {
          setNationalityData(res.data.nationality)
        }
      })
    }
    didMountRef.current = false;
  }, [])
  const handlechangedata = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setAllData({ ...allData, [key]: value })
  }
  const handleImage = e => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Invalid Format, supported formats are | jpeg | jpg | doc | docx | pdf | png.");
      return;
    }
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };
  const getfreequotation = () => {
    if (allData.patientname === "") {
      document.getElementById("patientname").style.border = "1px solid red"
      return false
    }
    else if (allData.age === "") {
      document.getElementById("age").style.border = "1px solid red"
      return false
    }
    else if (allData.gender === "" || allData.gender == undefined) {
      document.getElementById("gender").style.border = "1px solid red"
      return false
    }
    else if (allData.nationality === "") {
      document.getElementById("nationality").style.border = "1px solid red"
      return false
    }
    else if (allData.email === "") {
      document.getElementById("email").style.border = "1px solid red"
      return false
    }
    else if (allData.countrycode === "") {
      document.getElementById("countrycode").style.border = "1px solid red"
      return false
    }
    else if (allData.phone === "") {
      document.getElementById("phone").style.border = "1px solid red"
      return false
    }
    else if (allData.medicalconcerns === "") {
      document.getElementById("medicalconcerns").style.border = "1px solid red"
      return false
    }
    const formdata = new FormData()
    formdata.append('page_type', location.pathname.replace('/', ''))
    formdata.append('patient_name', allData.patientname)
    formdata.append('age', allData.age)
    formdata.append('gender', allData.gender)
    formdata.append('nationality', allData.nationality)
    formdata.append('email', allData.email)
    formdata.append('country_code', allData.countrycode)
    formdata.append('phone', allData.phone)
    formdata.append('medical_concerns', allData.medicalconcerns)
    formdata.append('medical_record_image', image.raw)
    apiServices.enquiryprocessPostRequest(formdata).then(res => {
      if (res.data.status == "success") {
        setSuccessMessage(res.data.message)
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      }
    })
  }
  return (
    <>
      <section className="section-gap-equal-small parallex-form">
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center mb-5' >
                <h4 className="title mb-2" style={{ color: "white" }}>Get a Free Personalized quote for Bariatric Surgery in India</h4>
                <p style={{ color: "white" }}>Trusted by Patients from 50+ countries</p>
              </div>
            </div>
          </div>
          <div className='row g-5'>
            {successMessage != "" ?
              <div class="alert alert-info">
                <strong>{successMessage}</strong>
              </div> : ""}
            <div className='col-lg-7'>
              <div className='quotform'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='quotform-group mb-4'>
                      <label>Patient Full Name <span >*</span></label>
                      <input type="text" name="patientname" placeholder="Patient" id='patientname' onChange={handlechangedata} />
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='quotform-group mb-4'>
                      <label>Age <span >*</span></label>
                      <input type="number" name="age" placeholder="Age" id='age' onChange={handlechangedata} />
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='quotform-group mb-4'>
                      <label>Gender <span >*</span></label>
                      <select name="gender" id='gender' onChange={handlechangedata}>
                        <option value="">Select</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='quotform-group mb-4'>
                      <label>Nationality (Passport) <span >*</span></label>
                      <select name="nationality" id='nationality' onChange={handlechangedata}>
                        <option >Select</option>
                        {nationalityData.length > 0 && nationalityData.map((value, index) => (
                          <option key={index} value={value.nationality_id}>{value.nationality_name}</option>))}
                      </select>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='quotform-group mb-4'>
                      <label>Email <span >*</span></label>
                      <input type="email" name="email" placeholder="Email" id='email' onChange={handlechangedata} />
                    </div>
                  </div>
                  <div className='col-lg-3'>
                    <div className='quotform-group mb-4'>
                      <label>Country Code <span >*</span></label>
                      <select name="countrycode" id='countrycode' onChange={handlechangedata}>
                        <option >Select</option>
                        <option disabled style={{ fontWeight: "bold" }}>Popular Countries</option>
                        {featuredCountries.length > 0 && featuredCountries.map((value, index) => (
                          <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                        ))}
                        <option disabled style={{ fontWeight: "bold" }}>All Countries</option>
                        {countriesData.length > 0 && countriesData.map((value, index) => (
                          <option key={index} value={value.country_id}>{value.country_name}(+{value.country_phonecode})</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='col-lg-5'>
                    <div className='quotform-group mb-4'>
                      <label>Phone <span >*</span></label>
                      <input type="number" name="phone" placeholder="Phone Number" id='phone' onChange={handlechangedata} />
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='quotform-group mb-4'>
                      <label>Medical Concerns/Questions <span >*</span></label>
                      <textarea type="text" name="medicalconcerns" placeholder="Medical Concerns/Questions" id='medicalconcerns' onChange={handlechangedata} />
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='quotform-group mb-4'>
                      <label>Upload Medical Records (jpeg, jpg, doc, docx, pdf, png)</label>
                      <div className="custom-file">
                        <input type="file" className="custom-file-input" id="customFile" onChange={handleImage}></input>
                        <label className="custom-file-label" for="customFile">{image.raw.name != "" ? image.raw.name : "Choose file"}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-12'>
                <button type="submit" className='edu-btn btn-large-second mt-3' onClick={getfreequotation}>Get Free Quotation</button>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='quoteformlits'>
                <ul>
                  <li>
                    <h6>Connect with 1000+ Specialists</h6>
                    <p>Use the built in communication tools to ask as many questions as needed and get a prompt response. Patient Coordinators will assist you from start to finish.</p>
                  </li>
                  <li>
                    <h6>Connect with Hospitals around the globe</h6>
                    <p>We hand pick each hospital & only internationally accredited hospitals are included to ensure you will receive high quality care.</p>
                  </li>
                  <li>
                    <h6>Unbeatable Quotes - Guaranteed</h6>
                    <p>If you receive a lower quote for the same treatment, hospital and doctor, we guarantee we will beat that price.</p>
                  </li>
                  <li>
                    <h6>Free Medical Advice from Experts</h6>
                    <p>Speak directly to doctors to have all your questions and concerns addressed before finalizing where to get your treatment.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default TreatmentShortcodeForm



