import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import Skeleton from "react-loading-skeleton";
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function Hospitals() {
    const [hostpitals, setHospitals] = useState([])
    const [hospitalImageUrl, setHospitalsImageUrl] = useState('')
    const [citiesData, setCitiesData] = useState([])
    const [defaultImage, setDefaultImage] = useState('')
    const [loading, setloading] = useState(true)
    const [hospitalCity, setHospitalCity] = useState('Jaipur')
    const didMountRef = useRef(true)
    useEffect(() => {
        if (hostpitals.length === 0) {
            apiServices.featuredhospitalsGetRequest().then(res => {
                if (res.data.status === "success") {
                    setHospitals(res.data.hospitalData)
                    setHospitalsImageUrl(res.data.hospital_url)
                    setDefaultImage(res.data.default_image_baseurl)
                    setloading(false)
                }
                else {
                    setloading(false)
                }
            })
            apiServices.hospitalscitiesGetRequest().then(res => {
                if (res.data.status === "success") {
                    setCitiesData(res.data.cities)
                }
            })
        }
    }, [])
    const citydata = (cityid) => {
        var arr = cityid.split(',');
        setHospitalCity(arr[1])

        const dataString = {
            "city_id": arr[0]
        }
        apiServices.cityfeaturedhospitalsPostRequest(dataString).then(res => {
            if (res.data.status === "success") {
                setHospitals(res.data.hospitalData)
                setHospitalsImageUrl(res.data.hospital_url)
                setDefaultImage(res.data.default_image_baseurl)
            }
        })
    }
    return (
        <>
            <div className="edu-team-area team-area-1 edu-section-gap">
                <div className="container">
                    <div className="section-title section-center">
                        <span className="pre-title">Hospitals</span>
                        <h2 className="title">Top Hospitals in {hospitalCity}</h2>
                        <div className="homeselect">
                            <select onChange={(e) => citydata(e.target.value)}>
                                {citiesData.map((value, index) => (
                                    <option selected={value.location_name === "Jaipur" ? true : ""} value={value.location_id + ',' + value.location_name} key={index}>{value.location_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <Swiper
                        spaceBetween={15}
                        breakpoints={{
                            // when window width is >= 640px
                            340: {
                                width: 340,
                                slidesPerView: 2,
                            },
                            640: {
                                width: 640,
                                slidesPerView: 2,
                            },
                            // when window width is >= 768px
                            768: {
                                width: 768,
                                slidesPerView: 2,
                            },
                            1000: {
                                width: 1000,
                                slidesPerView: 5,
                            },
                            1200: {
                                width: 1200,
                                slidesPerView: 5,
                            },
                        }}
                        className="hospitalslider">
                        {loading === true ? <>
                            <SwiperSlide>
                                <Skeleton varient="rectangular" />
                                <Skeleton varient="text" className="skeleton-bottom" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Skeleton varient="rectangular" />
                                <Skeleton varient="text" className="skeleton-bottom" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Skeleton varient="rectangular" />
                                <Skeleton varient="text" className="skeleton-bottom" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Skeleton varient="rectangular" />
                                <Skeleton varient="text" className="skeleton-bottom" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Skeleton varient="rectangular" />
                                <Skeleton varient="text" className="skeleton-bottom" />
                            </SwiperSlide>
                        </>
                            : <>
                                {
                                    hostpitals.map((value) => (
                                        <SwiperSlide>
                                            <div className="edu-team-grid team-style-1">
                                                <div className="inner">
                                                    <div className="thumbnail-wrap">
                                                        <div className="thumbnail">
                                                            <a href={`/hospital/${value.hospital_slug}`}>
                                                                <img src={value.hospital_image != null ? hospitalImageUrl + value.hospital_image : defaultImage}
                                                                    alt="team images"></img>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="title"><a href={`/hospital/${value.hospital_slug}`}>{value.hospital_name}</a></h6>
                                                        {value.hospital_city_name !== "" && value.hospital_country_name !== "" ?
                                                            <p>{value.hospital_city_name} , {value.hospital_country_name}</p> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}</>
                        }
                    </Swiper>
                    <div className="course-view-all">
                        <a href="/hospitals" className="edu-btn">More Hospitals <i className="icon-4"></i></a>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Hospitals;